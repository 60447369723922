/*
Purpose: list of Entrolment with use datatable
Developer: BY R.SD
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { useLocation, NavLink, useNavigate } from 'react-router-dom';

import { Row, Col, Tab, Nav, Card, Form, Modal, Button } from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import { Button as Buttonmui } from '@mui/material';

import Moment from 'moment';
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";


import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, options_dig_type
  , BASE_URL
} from "../global";



const SessionList = () => {

  const options_payment_type = [
    { value: '', label: '---Select---' },
    { value: 'Online', label: 'Online' },
    { value: 'Offline', label: 'Offline' },

  ];


  const handleClick_student_view = () => {
    // You can use the 'id' value here for further processing
    navigate(`/view-student`, { state: { pk_student_key: `${Id}` } });

  };



  const [showForfeit, setForfeitShow] = useState(false);

  const [records, setRecords] = useState([]);

  const [records_c, setRecords_c] = useState([]);


  const [data, setItems] = useState([]);


  const [data_c, setItems_c] = useState([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [isActive, setIsActive] = useState(true);

  const handleClick = event => {
    setIsActive(isActive => !isActive);
  };


  const [Id, setId] = useState(null);
  
  //Get key value start
  const location = useLocation();
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    //const extractedId = urlParams.get("id");
    const { fk_stu_key, fk_sest_key, pk_enrol_key } = location.state || {};
    const extractedId = fk_stu_key;
    const extractedSestKey = fk_sest_key;
    const extractedEnrolKey = pk_enrol_key;
    if (extractedId !== '' && extractedId !== undefined && extractedSestKey !== undefined && extractedEnrolKey !== undefined) {
      setId(extractedId);
      
      //fetchData(extractedId);
      fetchData(extractedId, extractedSestKey, extractedEnrolKey);
      fetchMasterData(extractedId);
    }else{
      navigate("/student-entrolments");
    }
  }, [location]);
  //Get key value end


  //Api call start


  const [data_forfeit, setForfeitLabelData] = useState([]);


  const [options_subject, setOptions] = useState([]);
  const [options_pricing, setFeesOptions] = useState([]);
  const [options_session, setSessionOptions] = useState([]);
  const [options_unenrol_session, SetUnenrolSupOptions] = useState([]);


  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingUnenrol, setIsSubmittingUnenrol] = useState(false);

  const [isSubmittingForfeit, setIsSubmittingForfeit] = useState(false);

  const [StudentData, setStudentData] = useState({});


  const formRef = useRef(null);


  const fetchMasterData = (Id, clsid = '') => {


    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      pk_student_key: Id,
      mode: "getSingleStudent"

    })
      .then(response_stu => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        if (response_stu.data.success === true) {
          if (response_stu.data.data.length > 0) {
            setStudentData(response_stu.data.data[0]);
          }
          setLoading(false);

        }
        else {
          setLoading(false);

        }

      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });


    // Make an API request to fetch the options
    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      fk_student_key: Id,
      mode: "getAllSubjectby_center", //"getAllSubjectByStudent" 
      fk_cent_key: sessionStorage.getItem('key')

    })
      .then(response => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedOptions = response.data.data.map(option => ({
          value: option.subject_id,
          label: option.subject_name
        }));
        formattedOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setOptions(formattedOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });


    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      mode: "getAllFee",
      fk_cent_key: sessionStorage.getItem('key')
    })
      .then(response_fees => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const feesOptions = response_fees.data.data.map(option => ({
          value: option.fee_cost,
          label: option.fee_title + ' (' + option.fee_cost + ')'
        }));
        feesOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setFeesOptions(feesOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });

    axios.post(BASE_URL + 'API/api_index.php', {
      security_key: security_key,
      class_id: clsid,
      mode: "getAllSessionTimeByStudent",
      sest_type:'',
      fk_student_key: Id,
      fk_cent_key: sessionStorage.getItem('key')
    })
      .then(response_session => {

        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const SessionOptions = response_session.data.data.map(option => ({
          value: option.pk_sest_key,
          label: option.sest_name
        }));
        SessionOptions.unshift({
          value: '',
          label: '--Select--'
        });
        setSessionOptions(SessionOptions);
      })
      .catch(error => {
        console.error('Error fetching options from API: ', error);
      });



  };



  const fetchData = async (Id, session_key, enroll_key) => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "readStudentEnrolmentSession",
        fk_student_key: Id,
        fk_sest_key: session_key,
        pk_enrol_key: enroll_key,
        fk_cent_key: sessionStorage.getItem('key')
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


        // const UnenrolSupOptions = response.data.data.map(option => ({
        //   value: option.pk_enrol_key,
        //   label: option.sest_name
        // }));
        // UnenrolSupOptions.unshift({
        //     value: '',
        //     label: '--Select--'
        //   });
        //   SetUnenrolSupOptions(UnenrolSupOptions);

      } else {
        setLoading(false);

        //alert message provide
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // try {
    //   const response = await axios.post(BASE_URL+'API/api_index.php', {
    //     security_key: security_key,
    //     mode: "readStudentEnrolmentPayment",
    //     fk_student_key: Id,
    //     fk_cent_key: sessionStorage.getItem('key')
    //   });
    //  
    //   if (response.data.success === true) {
    //     setRecords_p(response.data.data);
    //     //setItems(response.data.data);
    //     setLoading(false);


    //     // const UnenrolSupOptions = response.data.data.map(option => ({
    //     //   value: option.pk_enrol_key,
    //     //   label: option.sest_name
    //     // }));
    //     // UnenrolSupOptions.unshift({
    //     //     value: '',
    //     //     label: '--Select--'
    //     //   });
    //     //   SetUnenrolSupOptions(UnenrolSupOptions);

    //   } else {
    //     setLoading(false);

    //     //alert message provide
    //   }

    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }

    // try {
    //   const response = await axios.post(BASE_URL+'API/api_index.php', {
    //     security_key: security_key,
    //     mode: "readStudentEnrolmentPayment",
    //     fk_student_key: Id,
    //     fk_cent_key: sessionStorage.getItem('key')
    //   });
    //   if (response.data.success === true) {
    //     setRecords_c(response.data.data);
    //     setItems_c(response.data.data);
    //     setLoading(false);


    //   } else {
    //     setLoading(false);
    //   }

    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }

    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "readStudentEnrolmentCompletedSession",
        fk_student_key: Id,
        fk_sest_key: session_key,
        pk_enrol_key: enroll_key,
        fk_cent_key: sessionStorage.getItem('key')
      });
      
      if (response.data.success === true) {
        setRecords_c(response.data.data);
        setItems_c(response.data.data);
        setLoading(false);

      } else {
        //alert message provide
        setLoading(false);

      }


    } catch (error) {
      console.error('Error fetching data:', error);
    }

    try {
      const response_section = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "readStudentEnrolmentSectionOnly",
        fk_student_key: Id,
        fk_cent_key: sessionStorage.getItem('key')
      });
      
      if (response_section.data.success === true) {


        const UnenrolSupOptions = response_section.data.data.map(option => ({
          value: option.pk_enrol_key,
          label: option.sest_name
        }));
        UnenrolSupOptions.unshift({
          value: '',
          label: '--Select--'
        });
        SetUnenrolSupOptions(UnenrolSupOptions);

      } else {
        //alert message provide
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  // Sample columns configuration
  const columns = [

    {

      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {

      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },

    },
    {

      headerName: 'Class',
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {
      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },

    },

    {
      headerName: 'Session Start Time',
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_time == null || params.row.ses_start_time == '' ? '-' : Moment(params.row.ses_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname + ' ' + params.row.tut_surname}`;
      },


    },

    {

      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },

    },
    {
      headerName: "Fees ($)",
      field: "Fees ($)",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrollPrice == null || params.row.enrollPrice == '' ? '-' : params.row.enrollPrice}`;
      },



    },

    {

      field: "forfeitwriteoff",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Forfeit &  Write off",
      headerClassName: "super-app-theme--header",
      width: 250,

      headerAlign:"left",
      renderCell: (params) => {
        const row = params.row;

        if (row.fcount == 1) { // Replace this condition with your actual condition
          return (
            <div className='d-flex  pad-12'>
              <Buttonmui variant="outlined-red" className="primary-danger btnmr-2 my-2" ><del>Forfeit</del> </Buttonmui>

            </div>
          );
        }
        else if (row.wcount == 2) {
          return (
            <div className='d-flex  pad-12'>

              <Buttonmui variant="outlined-red" className="primary-danger my-2" ><del>Writeoff </del> </Buttonmui>

            </div>
          );
        }
        else {
          return (
            <div className='d-flex  pad-12'>
              <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 1)} className="primary-blue  btnmr-2 my-2" >Forfeit </Buttonmui>
              <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 2)} className="primary-blue  my-2" >Writeoff </Buttonmui>
            </div>
          );
        }
      }
    }




  ];


  // Sample columns configuration
  const columns_c = [

    {
      headerName: 'Date',
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_date == null || params.row.ses_date == '' ? '-' : Moment(params.row.ses_date, "YYYY-MM-DD").format('DD-MM-YYYY')}`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === '-' || valueB === '-') {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
        }
  
        const dateA = Moment(valueA, 'DD-MM-YYYY');
        const dateB = Moment(valueB, 'DD-MM-YYYY');
  
        return direction === 'asc' ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: 'Session Name',
      field: "Session Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_name == null || params.row.sest_name == '' ? '-' : params.row.sest_name}`;
      },


    },
    {

      headerName: 'Class',
      field: "Class",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${(params.row.sest_is_holidays == 1) ? 'Regular' : (params.row.sest_is_holidays == 2) ? 'One Off Session' : (params.row.sest_is_holidays == 3) ? 'Make up Session' : 'N/A'}`;
      },

    },
    {
      headerName: 'Day',
      field: "Day",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.sest_day == null || params.row.sest_day == '' ? '-' : params.row.sest_day}`;
      },


    },

    {

      headerName: 'Session Start Time',
      field: "Session Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ses_start_time == null || params.row.ses_start_time == '' ? '-' : Moment(params.row.ses_start_time, "HH:mm:ss").format('HH:mm')}`;
      },

    },
    {
      headerName: 'Tutor',
      field: "Tutor",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname}`;
      },


    },

    {
      headerName: 'Subject',
      field: "Subject",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.subject_name == null || params.row.subject_name == '' ? '-' : params.row.subject_name}`;
      },



    },
    {
      headerName: "Fees ($)",
      field: "enrol_price",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.enrol_price == null || params.row.enrol_price == '' ? '-' : params.row.enrol_price}`;
      },

    },

    {

      field: "Forfeit &  Write off",
      type: "actions",

      //field: "#",
      //  type: "actions",
      headerName: "Forfeit &  Write off",
      headerClassName: "super-app-theme--header",
      width: 250,
      headerAlign:"left",

      // selector: (row) => row.payslip,
      // sortable: true,
      //id: "id",

      //cell: (row) => (<><Button variant="outlined-red" onClick={() => handleForfeitClick(row)} className="primary-outlined" >Forfeit </Button><Button variant="outlined-red" onClick={() => handleWriteoffClick(row)} className="primary-outlined" >Write off </Button></>),
      renderCell: (params) => {
        const row = params.row;
        if (row.fcount == 1 && row.wcount != 2) { // Replace this condition with your actual condition
          return (
            <div className='d-flex  pad-12'>
              <Buttonmui variant="outlined-red" className="primary-danger btnmr-2 my-2" ><del>Forfeit</del> </Buttonmui>
              {/* <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 2)} className="primary-outlined my-2" >Writeoff </Buttonmui> */}
            </div>
          );
        }
        else if (row.fcount != 1 && row.wcount == 2) {
          return (
            <div className='d-flex  pad-12'>

              {/* <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 1)} className="primary-outlined btnmr-2 my-2" >Forfeit </Buttonmui> */}
              <Buttonmui variant="outlined-red" className="primary-danger my-2" ><del>Writeoff </del> </Buttonmui>

            </div>
          );
        }
        else if (row.fcount == 1 && row.wcount == 2) {
          return (
            <div className='d-flex  pad-12'>
              <Buttonmui variant="outlined-red" className="primary-danger btnmr-2 my-2" ><del>Forfeit </del></Buttonmui>
              <Buttonmui variant="outlined-red" className="primary-danger my-2" ><del>Writeoff </del> </Buttonmui>
            </div>
          );
        }
        else {
          return (
            <div className='d-flex  pad-12'> -
              {/* <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 1)} className="primary-outlined btnmr-2 my-2" >Forfeit </Buttonmui>
                <Buttonmui variant="outlined-red" onClick={() => handleForfeitClick(row, 2)} className="primary-outlined my-2" >Writeoff </Buttonmui> */}
            </div>
          );
        }
      }
    }
  ];


  function handleClassChange(event) {
    //setSelectedClassValue(event.value);
    fetchMasterData(Id, values.sest_class);

  };
  function handleForfeitClick(event, type) {

    

    const newKey = 'type'; // Replace with your key
    const newValue = type; // Replace with your value
    const updatedData = { ...event, [newKey]: newValue };
    setForfeitLabelData(updatedData);

    setForfeitShow(true);



  }

  



  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowNotify, setModalShowNotyfy] = useState(false);


  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);






  //id={selectedId} otherValue={otherValue} onClose={closeModal}

  const basicSchema = yup.object().shape({

    enrol_start_date: yup.date().required("Date is required."),
    fk_sub_id: yup.mixed().required("Subject is required."),
    fk_sest_key: yup.mixed().required("Session is required."),
    enrol_category: yup.mixed().required("Category is required."),
    sest_class: yup.bool(), // Assuming sest_class is a boolean checkbox


    enrol_price: yup.mixed().when('sest_class', {
      is: false,
      then: () => yup.mixed().required("Fees is required."),
    }),
    enrol_reg_fees: yup.mixed().when('sest_class', {
      is: false,
      then: () => yup.mixed().required("Fees is required."),
    }),
    enrol_deposit: yup.mixed().when('sest_class', {
      is: false,
      then: () => yup.mixed().required("Deposit is required."),
    }),



    // sest_class: yup.mixed(),
    // enrol_price: yup.mixed().when('sest_class', {
    //   is: true,
    //   then: yup.mixed().notRequired() // Skip validation when isChecked is true (checked)
    // }),
    // enrol_price:  yup.string().required('Fees is required.'),


    //  enrol_price: yup.mixed().required('Fees is required.'),

    //   enrol_reg_fees: yup.mixed().required('Fees is required.'),

    //   enrol_deposit:yup.mixed().required('Deposit is required.'),

    //enrol_price_type: yup.mixed().required("Type is required."),
  });

  const initialValues = {
    /*tbl_student_enrol*/

    fk_sub_id: "",
    fk_sest_key: "",
    sest_class: false,
    enrol_category: "In Centre",
    enrol_price: "",
    enrol_start_date: "",
    enrol_reg_fees: "",
    enrol_price_type: "",
    enrol_deposit: "",

    fk_stu_key: Id,
    fk_cent_key: sessionStorage.getItem('key'),
    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "createStudentEnrolment",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData(Id);
            fetchMasterData(Id);
            // setShow(false);
            setModalShowAdd(false);
            //setModalShowNotyfy(true);

            resetForm();
            // After 3 seconds, redirect to another page
            // setTimeout(function () {
            //handleClose();
            // }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Oops! Something went wrong.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;


  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
   
  };




  const validationSchema1 = yup.object().shape({
    pk_enrol_key_e: yup.string().required('Session is required.'),
    enrol_disc_date: yup.string().required('Date is required.'),
    enrol_disc_reason: yup.string().required('Reason is required.'),
  });


  const initialValues1 = {
    pk_enrol_key_e: '',
    enrol_disc_date: '',
    enrol_disc_reason: '',
    updated_by: sessionStorage.getItem('key'),
  };


  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,

    onSubmit: async (values) => {
      setIsSubmittingUnenrol(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "StudentUnenrolment",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // setShow(false);
            fetchData(Id);
            fetchMasterData(Id);
            resetForm();
            toggleModal1();



            setIsSubmittingUnenrol(false); // Re-enable the submit button
          } else {
            toast.error("Oops! Something went wrong.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingUnenrol(false); // Re-enable the submit button
          }

        } else {

          toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingUnenrol(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Oops! Something went wrong. Please Contact TCMS Team", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingUnenrol(false); // Re-enable the submit button

      } finally {
        setIsSubmittingUnenrol(false); // Re-enable the submit button

      }

    },
  });




  function onSubmitForfeit(type) {
    setIsSubmittingForfeit(true);

    const responseforfeit = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),

      mode: "changeStudentEnrolmentForfeitWriteoff",
      pk_enrol_key: data_forfeit.pk_enrol_key,
      fw_session_date: data_forfeit.ses_date,
      fw_session_time: data_forfeit.ses_start_time,
      fw_sest_key: data_forfeit.fk_sest_key,
      fk_ses_key: data_forfeit.pk_ses_key,
      stuky: Id,
      fw_type: type,
    }).then((responseforfeit) => {

      if (responseforfeit.data.data == 1) {

        // Handle the successful response here
        toast.success("Success! Session has been "+(type == 1 ? 'forfeit ' : type == 2 ? 'writeoff' : '')+" successfully.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setTimeout(function () {
          setForfeitShow(false)
          window.location.reload(false);

        }, 3000);
      } else {
        toast.error("Failure! Unable to forfeit or writeoff. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setForfeitShow(false)
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to forfeit or writeoff. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
        setForfeitShow(false)
      });






  }

  

  return (
    <>
      <ToastContainer position="top-center" />

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Enrolment </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Subject</h6>
                  <Select menuPlacement="auto" options={options_subject}
                    name="fk_sub_id"
                    value={values.fk_sub_id['value']}
                    id="fk_sub_id"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_sub_id",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_sub_id: true });
                    }}
                    className={errors.fk_sub_id && touched.fk_sub_id ? "input-error" : ""} />
                  {errors.fk_sub_id && touched.fk_sub_id && <p className="error">{errors.fk_sub_id}</p>}
                </Col>



                {/* --------------Category--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Enrolment Category</h6>
                  <Select menuPlacement="auto" options={options_dig_type}
                    name="enrol_category"
                    value={options_dig_type.find(option => option.value && values.enrol_category && option.value.toString() === values.enrol_category.toString())}
                    id="enrol_category"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "enrol_category",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, enrol_category: true });
                    }}
                    className={errors.enrol_category && touched.enrol_category ? "input-error" : ""} />
                  {errors.enrol_category && touched.enrol_category && <p className="error">{errors.enrol_category}</p>}
                </Col>

                {/* --------------Class Session--------------- */}




                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable ">Class</h6>
                  <Form.Check
                    type="checkbox"
                    label="Makeup Session"
                    name="sest_class"
                    id="sest_class"
                    checked={values.sest_class}
                    onClick={handleClassChange}
                    onChange={handleChange}
                    className='mt-2'
                  />
                  {/*  <Select menuPlacement="auto"  options={options_isholiday}
                    name="sest_is_holidays"
                   value={values.sest_is_holidays['value']}
                    id="sest_is_holidays"
                    onChange={(event) => {
                      handleClassChange(event);                     
                       setTouched({ ...touched, sest_is_holidays: true });


                    }}
                    
                    // onChange={(events) => {
                    //   const event = {
                    //     target: {
                    //       name: "sest_is_holidays",
                    //       value: events["value"],
                    //     },
                    //   };
                    //  // handleChange(event);
                    //   handleClassChange(event);                     

                    // }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, sest_is_holidays: true });
                    }}
                    className={errors.sest_is_holidays && touched.sest_is_holidays ? "input-error" : ""} />
                    {errors.sest_is_holidays && touched.sest_is_holidays && <p className="error">{errors.sest_is_holidays}</p>} */}

                </Col>
                {/* --------------Session--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session</h6>

                  <Select menuPlacement="auto" options={options_session}
                    name="fk_sest_key"
                    value={values.fk_sest_key['value']}
                    id="fk_sest_key"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "fk_sest_key",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, fk_sest_key: true });
                    }}
                    className={errors.fk_sest_key && touched.fk_sest_key ? "input-error" : ""} />
                  {errors.fk_sest_key && touched.fk_sest_key && <p className="error">{errors.fk_sest_key}</p>}


                </Col>


                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Enrolment Date</h6>
                  <Form.Control
                    type="date"

                    value={values.enrol_start_date}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="enrol_start_date"
                    className={errors.enrol_start_date && touched.enrol_start_date ? "input-error" : ""}
                  />
                  {errors.enrol_start_date && touched.enrol_start_date && <p className="error">{errors.enrol_start_date}</p>}
                </Col>

                {/* --------------Fees--------------- */}
                {values.sest_class === false && (
                  <>
                    <Col lg="6" md="6" sm="6" className='position-relative'>
                      <h6 className="form-lable required-field">Fees</h6>
                      <Select menuPlacement="auto" options={options_pricing}
                        name="enrol_price"
                        value={values.enrol_price['value']}
                        id="enrol_price"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_price",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_price: true });
                        }}
                        className={errors.enrol_price && touched.enrol_price ? "input-error" : ""} />
                      {errors.enrol_price && touched.enrol_price && <p className="error">{errors.enrol_price}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative'>
                      <h6 className="form-lable required-field">Registration fees ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                      <Form.Control
                        type="number"

                        value={values.enrol_reg_fees}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="enrol_reg_fees"
                        className={errors.enrol_reg_fees && touched.enrol_reg_fees ? "input-error" : ""}
                      />
                      {errors.enrol_reg_fees && touched.enrol_reg_fees && <p className="error">{errors.enrol_reg_fees}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative'>
                      <h6 className="form-lable required-field">Enrolment Deposit ({sessionStorage.getItem('currency_code') != null && sessionStorage.getItem('currency_code') != '' ? sessionStorage.getItem('currency_code') : 'SGD'})</h6>
                      <Form.Control
                        type="number"

                        value={values.enrol_deposit}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="enrol_deposit"
                        className={errors.enrol_deposit && touched.enrol_deposit ? "input-error" : ""}
                      />
                      {errors.enrol_deposit && touched.enrol_deposit && <p className="error">{errors.enrol_deposit}</p>}
                    </Col>

                    <Col lg="6" md="6" sm="6" className='position-relative' style={{ display: 'none' }}>
                      <h6 className="form-lable required-field">Fees Type</h6>
                      <Select menuPlacement="auto" options={options_payment_type}
                        name="enrol_price_type"
                        value={values.enrol_price_type['value']}
                        id="enrol_price_type"
                        onChange={(selectedOption) => {
                          const event = {
                            target: {
                              name: "enrol_price_type",
                              value: selectedOption["value"],
                            },
                          };
                          handleChange(event);
                        }}
                        onBlur={(event) => {
                          handleBlur(event);
                          setTouched({ ...touched, enrol_price_type: true });
                        }}
                        className={errors.enrol_price_type && touched.enrol_price_type ? "input-error" : ""} />
                      {errors.enrol_price_type && touched.enrol_price_type && <p className="error">{errors.enrol_price_type}</p>}
                    </Col>
                  </>
                )}
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>

        </Modal>

        <Modal
          show={modalShowNotify} onHide={() => setModalShowNotyfy(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Enrolment </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                <div className="ten columns col-12">
                  <ul className="">
                    <li> Mon 04-sep-23 &rArr;	 Lesson Added For Student</li>
                    <li> Mon 11-sep-23 &rArr;	 Lesson Added For Student </li>
                    <li> Mon 18-sep-23 &rArr;	 Lesson Added For Student</li>
                    <li> Mon 25-sep-23 &rArr;	 Lesson Added For Student</li>
                  </ul>


                </div>
              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowNotyfy(false)}>Cancel</Button>
                <Button variant="contained" className="primary-filled " onClick={handleClick_student_view}>View Student</Button>
              </div>

            </Modal.Footer>
          </Form>
        </Modal>


        <Modal show={showModal1} onHide={toggleModal1} size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
            <Modal.Title>Unenrolment</Modal.Title>
          </Modal.Header>
          <Form onSubmit={formik1.handleSubmit}>
            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>


                {/* --------------Subject--------------- */}

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Session</h6>
                  <Select menuPlacement="auto" options={options_unenrol_session}
                    name="pk_enrol_key_e"
                    value={formik1.values.pk_enrol_key_e['value']}
                    id="pk_enrol_key_e"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "pk_enrol_key_e",
                          value: selectedOption["value"],
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({ ...formik1.touched, pk_enrol_key_e: true });
                    }}
                    className={formik1.errors.pk_enrol_key_e && formik1.touched.pk_enrol_key_e ? "input-error" : ""} />
                  {formik1.errors.pk_enrol_key_e && formik1.touched.pk_enrol_key_e && <p className="error">{formik1.errors.pk_enrol_key_e}</p>}
                </Col>

                <Col lg="6" md="6" sm="6" className='position-relative'>
                  <h6 className="form-lable required-field">Unenrolment Date</h6>
                  <Form.Control
                    type="date"

                    value={formik1.values.enrol_disc_date}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    id="enrol_disc_date"
                    className={formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date ? "input-error" : ""}
                  />
                  {formik1.errors.enrol_disc_date && formik1.touched.enrol_disc_date && <p className="error">{formik1.errors.enrol_disc_date}</p>}
                </Col>

                <Col lg="12" md="12" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Reason</h6>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    id="enrol_disc_reason"

                    value={formik1.values.enrol_disc_reason}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    className={formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason ? "input-error" : ""}
                  />
                  {formik1.errors.enrol_disc_reason && formik1.touched.enrol_disc_reason && <p className="error">{formik1.errors.enrol_disc_reason}</p>}
                </Col>
              </Row>


            </Modal.Body>
            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={toggleModal1}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmittingUnenrol}>Submit</Button>

              </div>

            </Modal.Footer>
          </Form>
        </Modal>






        <Modal show={showForfeit} variant="danger">

          <Modal.Header>{(data_forfeit.type == 1 ? 'Forfeit ' : data_forfeit.type == 2 ? 'Writeoff' : '')}</Modal.Header>

          <Modal.Body>
            <p>Are you sure? You want to {(data_forfeit.type == 1 ? 'forfeit ' : data_forfeit.type == 2 ? 'writeoff' : '')} this session? </p>

            <Card className='rounded-1 mt-2 card-bdr' >
              <Card.Body>

                <div>
                  <div className="align-self-center">
                    <div className="me-5">
                      <div className="">

                        <p className="">{data_forfeit.sest_name}<span className=''> ({data_forfeit.tut_fname + ' ' + data_forfeit.tut_surname})</span></p>
                        <div className="d-flex ">
                          <h6>Session Date:</h6>
                          <p className="ms-2">
                            <i class="fa-solid fa-calendar me-2"></i>
                            {Moment(data_forfeit.ses_date).format('DD-MM-YYYY')} ({data_forfeit.sest_day})
                          </p>
                        </div>
                        <div className="d-flex ">
                          <h6>Session Time:</h6>
                          <p className="ms-2">
                            <i class="fa-solid fa-clock me-2"></i>

                            {data_forfeit.ses_start_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>

                  </span>
                </div>

              </Card.Body>
            </Card>
            <hr />
          </Modal.Body>
          <Modal.Footer>

            <div className="mx-auto d-flex gap-2" >

              <Button variant="contained" className="primary-gray" onClick={() => setForfeitShow(false)}>Cancel</Button>
              <Button variant="contained" type='submit' className="primary-filled " onClick={() => onSubmitForfeit(data_forfeit.type)} disabled={isSubmittingForfeit} >Yes</Button>
            </div>

          </Modal.Footer>

        </Modal>



        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'> Enrolment Session </h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                <Typography color="text.primary">Enrolment Session</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


            <div className='d-flex justify-content-end gap-2'>
              <div >
                {/* <DropdownButton as={ButtonGroup} title="Action" id="dropdown-menu-align-responsive-1" className="ps-0 rounded-10 primary-filled btn-action text-light">
                  <Dropdown.Item eventKey="1" onClick={handleClick_student_view}>Student Particulars</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={handleClick_student_edit}>Student Profile</Dropdown.Item>
                  <Dropdown.Item eventKey="3"><NavLink to='/list-students' >Student list</NavLink></Dropdown.Item>

                </DropdownButton> */}
              </div>
            </div>
          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}

            <Tab.Container defaultActiveKey="first">
              <Row>
                <Col sm={12} className='border-bottom mb-2'>

                 
                  <Nav variant="pills" className="mb-3 text-dark ">
                    <div className='d-flex justify-content-between'>
                      <Nav.Item>
                        <Nav.Link eventKey="first" className="px-4 py-2 text-dark me-2" onClick={handleClick}>Current</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second" className="px-4 py-2 text-dark" onClick={handleClick}>Completed</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                        <Nav.Link eventKey="third" className="px-4 py-2 text-dark" onClick={handleClick}>Lapsed</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth" className="px-4 py-2 text-dark" onClick={handleClick}>Payment</Nav.Link>
                      </Nav.Item> */}
                    </div>
                  </Nav>
                  <span className='me-2 mt-2'>{StudentData ? StudentData.st_first_name != null && StudentData.st_first_name != '' ? StudentData.st_first_name + ' ' + StudentData.st_surname + ' - ' : '' : ''} <b>{StudentData ? StudentData.pk_student_id != null && StudentData.pk_student_id != '' ? '#' + StudentData.pk_student_id : '' : ''}</b></span>
                </Col>

                <Col sm={12}>

                  <Tab.Content>
                    <Tab.Pane eventKey="first">


                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "CurrentSessionList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CurrentSessionList" },
                              records,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns}
                          rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}


                        />


                      </Box>
                    
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">

                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: '100%', width: "100%" }}
                      >
                       <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "CompletedSessionList" },
                              printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CompletedSessionList" },
                              records_c,
                              showQuickFilter: true
                            },


                          }}
                          initialState={{
                            ...records_c.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}

                          pageSizeOptions={[10, 25, 50, 100]}

                          loading={loading}
                          rows={records_c.map((row, index) => ({ ...row, id: `row-${index}` }))}
                          columns={columns_c}
                          rowHeight={60} density="compact"
disableRowSelectionOnClick
showCellVerticalBorder= {true}
showColumnVerticalBorder= {true}


                        />


                      </Box>
                    
                    </Tab.Pane>


                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>

          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default SessionList;
