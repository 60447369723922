/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Select from 'react-select';
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import axios from "axios";
import {
    security_key, options_time_zone,
    options_countries, options_gender, options_preferedmethodofcontact,
    options_refferalsource, options_campaign, options_status, BASE_URL
} from "../global";
import PhoneInput from 'react-phone-input-2';

import './add-student.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

const phoneNumberRules = {
    'SG': {
      min: 8,
      max: 8,
      message: "Phone number must be exactly 8 digits.",
    }, // Singapore
  
    'AU': {
      min: 9,
      max: 10,
      message: "Phone number must be between 9 and 10 digits.",
    }, // Australia
  
    'GB': {
      min: 10,
      max: 10,
      message: "Phone number must be exactly 10 digits.",
    }, // United Kingdom

    'NZ': {
      min: 8,
      max: 10,
      message: "Phone number must be between 8 and 10 digits.",
    }, // New Zealand
  
    
    'General': {
    min: 5,
    max: 15,
    message: "Phone number must be between 5 to 15 digits.",
  } // general number lenght
  };

function AddLead() {
    const [options_parent, setOptions_par] = useState([]);
    const [options_yearlevel, setYearLevelOptions] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showOtherField, setShowOtherField] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();



    const onGroupContainerClick = useCallback(() => {
        navigate("/list-lead");
    }, [navigate]);







    const basicSchema = yup.object().shape({

        st_first_name: yup.string().notRequired().min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        st_surname: yup.string().notRequired().min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        st_dob: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
       
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),


        /* st_first_name: yup.string().required("First name is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
            st_surname: yup.string().required("Last Name is required."),
            st_gender: yup.mixed().required("Gender is required."),
            st_dob: yup.date().required("Date of birth is required."),
            st_year_level: yup.mixed().required("Year level is required."),
            fk_sc_id: yup.mixed().required("School is required."), */

        fk_sc_id: yup.string().min(2, "Minimum 2 characters required").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        st_email: yup.string().email("Please enter a valid email address").notRequired().matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
            return !value || value && value.includes('.');
          }),
        st_countryCode: yup.string().notRequired() ,
        st_phone: yup
          .string()
          .test(
            'valid-phone-number',
            function (value) {
              const { st_countryCode } = this.parent;
              const countryCodeMap = {
                '44': 'GB',   // UK
                '61': 'AU',   // Australia
                '64': 'NZ',   // New Zealand
                '65': 'SG',   // Singapore
                
              };
        
             
              const countryKey = countryCodeMap[st_countryCode];
        
              
              const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
        
            
              const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${st_countryCode}\\s?`, 'g'), '');
              if (!cleanPhoneNumber ) {
                return true; 
              }
              if (countrySpecificRule) {
                const { min, max, message } = countrySpecificRule;
                const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
                // Check if the phone number length is within the valid range for the country
                if (phoneLength < min || phoneLength > max) {
                  return this.createError({ message: message });
                }
              }
        
              return true;  // Validation passes if no errors
            }
          )
          .notRequired(),

        /*  st_email: yup.string().email("Please enter a valid email").required("Email is required."),
         st_phone: yup.string().min(7, "Minimum 5 digit required").required("Phone is required."), */
        //st_timezone: yup.mixed().required("Timezone is required."),


        st_is_adultlearner: yup.bool(),
        //st_is_adultlearner: yup.boolean(),
        /*tbl_parent*/
        par_firstname: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        }),
        par_surname: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().required("Lastname is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
        }),



        par_emailid: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().email("Please enter a valid email address").notRequired().matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
                return !value || value && value.includes('.');
              }),
        }),
        par_countryCode: yup.string().notRequired(),
        par_phone: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().test('email-or-phone', 'valid-phone-number', function (value) {
                const par_emailid = this.parent.par_emailid;
                
                if (!value && (!par_emailid || !yup.string().email().isValidSync(par_emailid))) {
                  return this.createError({
                    path: 'par_phone',
                    message: 'Email or phone is required.',
                  });
                }
                
                const { par_countryCode } = this.parent;
                const countryCodeMap = {
                    '44': 'GB',   // UK
                    '61': 'AU',   // Australia
                    '64': 'NZ',   // New Zealand
                    '65': 'SG',   // Singapore
                    
                  };
            
                 
                  const countryKey = countryCodeMap[par_countryCode];
            
                  
                  const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
            
                
                  const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${par_countryCode}\\s?`, 'g'), '');
                  if (!cleanPhoneNumber ) {
                    return true; 
                  }
                  if (countrySpecificRule) {
                    const { min, max, message } = countrySpecificRule;
                    const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
                    // Check if the phone number length is within the valid range for the country
                    if (phoneLength < min || phoneLength > max) {
                      return this.createError({ message: message });
                    }
                  }
            
                 
                return true;
              }),
          }),

        par_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
        par_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        par_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        //par_country: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        par_zip: yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").notRequired().matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
        /*par_address: yup.mixed().required("Address is required."),
        par_city: yup.mixed().required(" City/Town is  required."),
        par_state: yup.mixed().required("State is required."),
        par_country: yup.mixed().required("Country is required."),
        par_zip: yup.mixed().required("Postcode is required."),*/


        lead_pref_tim_contact: yup
            .string()
            .nullable(true)
            .test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is null or a valid time
            return value === undefined || value === null || value === '' || dayjs(value, 'HH:mm:ss').isValid();
            }),
        ls_date: yup
        .string()
        .nullable(true)
        .test('is-valid-date', 'Please provide a valid date.', function (value) {
          // Check if the value is null or a valid date 
          
          return value === undefined || value === null || value === '' || dayjs(value, 'YYYY-MM-DD').isValid();
        }),

        /*tbl_lead*/
        /*lead_pref_meth_contact: yup.mixed().required("Method of contact is required."), //My point reset date-10
        lead_pref_tim_contact: yup.mixed().required("Time to contact is required."),
        lead_ref_source: yup.mixed().required("Referal source is required."),
        lead_ref_campaign: yup.mixed().required("Referal campaign is required."),
        ls_date: yup.date().required("Date is required."),
        ls_status: yup.mixed().required("Status is required."),
        ls_description: yup.string().required("description is required."),
       

        ps_relationship: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.mixed().required("Relationship is required."),
          }),*/
          ls_description: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
          ps_relationship: yup.string().when('st_is_adultlearner', {
            is: false,
            then: () => yup.string().notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
        }),
    });


    useEffect(() => {
        // Make an API request to fetch the options
        

        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            fk_cent_key: sessionStorage.getItem('key'),
            mode: "getAllParentbyCenter"
        })
            .then(response_par => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedOptions_par = response_par.data.data.map(option => ({
                    value: option.pk_par_key,
                    label: option.par_firstname + ' ' + option.par_surname
                }));
                formattedOptions_par.unshift({
                    value: '0',
                    label: 'Select Existing Parent'
                });
                setOptions_par(formattedOptions_par);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });

        axios.post(BASE_URL + 'API/api_index.php', {
            security_key: security_key,
            country_id: sessionStorage.getItem('country_code'),
            mode: "getCountryYearLevel"
        })
            .then(response_year => {
                // Assuming the API response is an array of objects with 'id' and 'value' properties
                const formattedyearOptions = response_year.data.data.map(option => ({
                    value: option.activity_level_id,
                    label: option.level_name
                }));
                setYearLevelOptions(formattedyearOptions);
            })
            .catch(error => {
                console.error('Error fetching options from API: ', error);
            });
    }, []);



    const initialValues = {
        /*tbl_student*/
        st_first_name: "",
        st_surname: "",
        st_gender: "",
        st_dob: "",
        st_year_level: "",
        fk_sc_id: "",
        st_email: "",
        st_address: "",
        st_city: "",
        st_state: "",
        st_country: sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG',
        st_zip: "",
        st_phone: "",
        st_timezone: sessionStorage.getItem('time_zone'),
        st_is_adultlearner: false,
        fk_cent_key: "",
        st_avatar: "",
        st_countryCode:'',
        par_countryCode:'',
        ex_par_key: '0',

        /*tbl_parent*/
        par_firstname: "",
        par_surname: "",
        par_emailid: "",
        par_phone: "",
        par_address: "",
        par_city: "",
        par_state: "",
        par_country: sessionStorage.getItem('country_code') != null && sessionStorage.getItem('country_code') != '' ? sessionStorage.getItem('country_code') : 'SG',
        par_zip: "",

        /*tbl_lead*/
        lead_pref_meth_contact: "", //My point reset date-10
        lead_pref_tim_contact: "",
        lead_ref_source: "",
        lead_ref_campaign: "",
        lead_ref_campaign_others: "",
        ls_date: "",
        ls_status: "",
        ls_description: "",


        ps_relationship: "",
        fk_cent_key: sessionStorage.getItem('key'),
        created_by: sessionStorage.getItem('key'),

    };

    // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);


            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    security_key: security_key,
                    mode: "createLead_direct",
                    ...formValues,
                });

                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/list-lead");
                            setIsSubmitting(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds


                    }else if (response.data.data == 4) {
                        toast.error("Year level is not available, Try another option", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    } else {
                        toast.error("Failure! Unable to create lead. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setIsSubmitting(false); // Re-enable the submit button
                    }

                } else {

                    toast.error("Failure! Unable to create lead. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button

                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Failure! Unable to create lead. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            } finally {
                setIsSubmitting(false); // Re-enable the submit button

            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;
    const fileInputRef = useRef(null);
   

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields

    };

    const handleUsernameBlur = useCallback(async () => {
        const username = formik.values.sa_login_id;
        if (username) {
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    userID: username,
                    security_key: security_key,
                    mode: "checkStudentUserId",
                });

                if (response.data.success === true) {
                    formik.setFieldTouched("sa_login_id", true, false);
                    formik.setFieldError("sa_login_id", "Username is not available");
                } else {
                    formik.setFieldTouched("sa_login_id", true, false);
                    formik.setFieldError("sa_login_id", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik.setFieldError("sa_login_id", null);
            }
        }
    }, [formik.values.sa_login_id]);


    const handleUsernameBlur1 = useCallback(async () => {
        const username1 = formik.values.pa_login_id;
        if (username1) {
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    userID: username1,
                    security_key: security_key,
                    mode: "checkParentUserId",
                });

                if (response.data.success === true) {
                    formik.setFieldTouched("pa_login_id", true, false);
                    formik.setFieldError("pa_login_id", "Username is not available");
                } else {
                    formik.setFieldTouched("pa_login_id", true, false);
                    formik.setFieldError("pa_login_id", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik.setFieldError("pa_login_id", null);
            }
        }
    }, [formik.values.pa_login_id]);


    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) { const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;
          
            const errorFieldElement = document.getElementById(firstErrorField);
          
            if (errorFieldElement) {
               errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
             
            }
         //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
      }, [formik]);

    return (

        <>
            {/* React alert */}
             <ToastContainer position="top-center" />
            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">

                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'> Add New Lead </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                                    <Typography color="text.primary">Lead</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}
                    </div>

                    {/* --------------Student Particulars--------------- */}

                    <Stack>
                        <div className="my-card p-3 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Student Particulars </h6>
                            </div>
                            <Row>

                                {/* --------------Student First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">First Name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_first_name"
                                        className={errors.st_first_name && touched.st_first_name ? "input-error" : ""}
                                    />
                                    {errors.st_first_name && touched.st_first_name && <p className="error">{errors.st_first_name}</p>}
                                </Col>

                                {/* --------------Student SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Last name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_surname"
                                        className={errors.st_surname && touched.st_surname ? "input-error" : ""}
                                    />
                                    {errors.st_surname && touched.st_surname && <p className="error">{errors.st_surname}</p>}
                                </Col>


                                {/* --------------Student Gender--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Gender</h6>
                                     <Select menuPlacement="auto"  options={options_gender}
                                        name="st_gender"
                                        value={values.st_gender["value"]}
                                        id="st_gender"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_gender",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_gender: true });
                                        }}
                                        className={errors.st_gender && touched.st_gender ? "input-error" : ""}
                                    />
                                    {errors.st_gender && touched.st_gender && <p className="error">{errors.st_gender}</p>}
                                </Col>

                                {/* --------------Student Date of Birth--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Date of Birth</h6>
                                    {/* <Form.Control
                                        type="date"
                                        max={new Date().toISOString().split("T")[0]}

                                        value={values.st_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_dob"
                                        className={errors.st_dob && touched.st_dob ? "input-error" : ""}
                                    />
                                    {errors.st_dob && touched.st_dob && <p className="error">{errors.st_dob}</p>} */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.st_dob, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "st_dob",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, st_dob: true });
                                            }}
                                            name="st_dob"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'st_dob',
                                                },
                                                }}
                                            className={errors.st_dob && touched.st_dob ? "input-error w-100" : "w-100"}
                                            maxDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.st_dob && touched.st_dob && <span className="error">{errors.st_dob}</span>}
                                </Col>


                                {/* --------------Student Year Level--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Year Level</h6>
                                     <Select menuPlacement="auto"  options={options_yearlevel}
                                        name="st_year_level"
                                        value={values.st_year_level["value"]}
                                        id="st_year_level"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_year_level",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_year_level: true });
                                        }}
                                        className={errors.st_year_level && touched.st_year_level ? "input-error" : ""}
                                    />
                                    {errors.st_year_level && touched.st_year_level && <p className="error">{errors.st_year_level}</p>}
                                </Col>

                                {/* --------------Student School--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">School</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.fk_sc_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="fk_sc_id"
                                        className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""}
                                    />
                                    {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col>

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">School</h6>
                                     <Select menuPlacement="auto"  options={options_school}
                                     name="fk_sc_id"
                                     value={values.fk_sc_id["value"]}
                                     id="fk_sc_id"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "fk_sc_id",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, fk_sc_id: true });
                                     }}
                                     className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""} />
                                      {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col> */}

                                {/* --------------Student Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Email</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.st_email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_email"
                                        className={errors.st_email && touched.st_email ? "input-error" : ""}
                                    />
                                    {errors.st_email && touched.st_email && <p className="error">{errors.st_email}</p>}
                                </Col>



                                {/* --------------Student city--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">City/Town</h6>
                                    <Form.Control type="text" 
                                    value={values.st_city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_city"
                                    className={errors.st_city && touched.st_city ? "input-error" : ""}
                                     />
                                    {errors.st_city && touched.st_city && <p className="error">{errors.st_city}</p>}
                                </Col> */}

                                {/* --------------Student state--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">County/Region/State</h6>
                                    <Form.Control type="text" 
                                     value={values.st_state}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_state"
                                     className={errors.st_state && touched.st_state ? "input-error" : ""} />
                                      {errors.st_state && touched.st_state && <p className="error">{errors.st_state}</p>}
                                </Col> */}

                                {/* --------------Student Country--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Country</h6>
                                     <Select menuPlacement="auto"  options={options_countries}
                                    name="st_country"
                                    value={values.st_country["value"]}
                                    id="st_country"
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "st_country",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, st_country: true });
                                    }}
                                    className={errors.st_country && touched.st_country ? "input-error" : ""}
                                     />
                                     {errors.st_country && touched.st_country && <p className="error">{errors.st_country}</p>}
                                </Col> */}


                                {/* --------------Student Postal Code--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Postal Code</h6>
                                    <Form.Control type="text" 
                                     value={values.st_zip}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_zip"
                                     className={errors.st_zip && touched.st_zip ? "input-error" : ""}
                                    />
                                    {errors.st_zip && touched.st_zip && <p className="error">{errors.st_zip}</p>}
                                </Col> */}

                                {/* --------------Student Timezone--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Timezone</h6>
                                     <Select menuPlacement="auto"  options={options_time_zone}
                                     name="st_timezone"
                                     value={values.st_timezone["value"]}
                                     id="st_timezone"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "st_timezone",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, st_timezone: true });
                                     }}
                                     className={errors.st_timezone && touched.st_timezone ? "input-error" : ""}
                                      />
                                       {errors.st_timezone && touched.st_timezone && <p className="error">{errors.st_timezone}</p>}
                                </Col> */}

                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Contact Number</h6>
                                    {/* <Form.Control type="number" 
                                     value={values.st_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_phone"
                                     className={errors.st_phone && touched.st_phone ? "input-error" : ""}
                                      />
                                       {errors.st_phone && touched.st_phone && <p className="error">{errors.st_phone}</p>} */}
                                    <PhoneInput
                                        name="st_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.st_phone}
                                        onChange={(phone, country) => {
                                            const event = {
                                                target: {
                                                    name: "st_phone",
                                                    value: phone,
                                                },
                                            };
                                            const event1 = {
                                                target: {
                                                    name: "st_countryCode",
                                                    value: country.dialCode,
                                                },
                                                };
                                            handleChange(event1);
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_phone: true });
                                        }}
                                        inputProps={{id: 'st_phone'}}
                                        id="st_phone"
                                        inputClass={errors.st_phone && touched.st_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.st_phone && touched.st_phone && <span className="error">{errors.st_phone}</span>}
                                </Col>


                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Photo</h6>
                                    <Form.Control type="file"
                                        name="st_avatar"
                                        id="st_avatar"
                                        accept=".png, .jpeg, .jpg, .jfif"
                                        onChange={(event) => {
                                            setTouched({ ...touched, st_avatar: true });
                                            if (event.target.files.length > 0) {
                                                const fileName = event.target.files[0];
                                                async function handleFileUpload(fileName) {
                                                    try {
                                                        // Create a FormData object to send the form data, including the image file
                                                        const formData = new FormData();
                                                        formData.append("mode", "file_s3_upload_image");
                                                        formData.append("user", "student");
                                                        formData.append("image", fileName); // Append the image file

                                                        // Make an API request here with the FormData object
                                                        const response_file = await axios.post(BASE_URL + 'API/upload/index.php', formData);

                                                        // Handle the API response here
                                                        if (response_file.data.success === true) {
                                                            handleChange({ target: { name: "st_avatar", value: response_file.data.data } });
                                                        } else {
                                                            fileInputRef.current.value = null;
                                                            handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                        }

                                                        // You can also perform further actions based on the API response
                                                        // For example, show a success message or redirect to another page.
                                                    } catch (error) {
                                                       
                                                        fileInputRef.current.value = null;
                                                        handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                                    }
                                                }

                                                // Call the async function when needed, passing the fileName as an argument
                                                handleFileUpload(fileName);


                                            } else {
                                                // handleChange(event);
                                              
                                               fileInputRef.current.value = null;
                                                handleChange({ target: { name: "st_avatar", value: null } }); // Clear the input value
                                            }
                                        }}
                                        ref={fileInputRef}
                                        className={errors.st_avatar && touched.st_avatar ? "input-error" : ""}
                                    />
                                    {errors.st_avatar && touched.st_avatar && <p className="error">{errors.st_avatar}</p>}
                                </Col>

                                {/* --------------Contact Checkbox--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>

                                    <Form.Check
                                        type="checkbox"
                                        label="Adult Learner"
                                        id="st_is_adultlearner"
                                        name="st_is_adultlearner"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        checked={formik.values.st_is_adultlearner}
                                        className='mt-0 mb-0'

                                    />

                                    {errors.st_is_adultlearner && touched.st_is_adultlearner && <p className="error">{errors.st_is_adultlearner}</p>}
                                </Col>

                                {/* --------------Contact Address1--------------- */}
                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Address</h6>
                                    <Form.Control as="textarea" 
                                    rows={3} 
                                    value={values.st_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_address"
                                    className={errors.st_address && touched.st_address ? "input-error control_textarea" : "control_textarea"}
                                     />
                                      {errors.st_address && touched.st_address && <p className="error">{errors.st_address}</p>}
                                </Col> */}

                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Student Particulars End--------------- */}



                    {/* --------------Contact Details--------------- */}

                    <Stack className='mt-3' style={{ display: formik.values.st_is_adultlearner != null && formik.values.st_is_adultlearner === true ? 'none' : 'block' }}>
                        <div className="my-card p-3 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3 row'>
                                <Col lg="8" md="8" sm="12" className='position-relative'>
                                    <h6 className='mb-0'>Parents / Guardians Details </h6></Col>

                                <Col lg="4" md="4" sm="12" className='position-relative'>
                                    {/* <h6 className="form-lable ">Parent Name</h6> */}
                                     <Select menuPlacement="auto"  options={options_parent}
                                        name="ex_par_key"
                                        value={options_parent.find(option => option.value && values.ex_par_key && option.value.toString() === values.ex_par_key.toString())}
                                        id="ex_par_key"
                                        //  onChange={(selectedOption) => {
                                        //      const event = {
                                        //          target: {
                                        //              name: "ex_par_key",
                                        //              value: selectedOption["value"],
                                        //          },
                                        //      };
                                        //      handleChange(event);
                                        //  }}
                                        onChange={async (selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ex_par_key",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);

                                            // Fetch data based on the selected country
                                            try {

                                                const response = await axios.post(BASE_URL + 'API/api_index.php', {
                                                    security_key: security_key,
                                                    mode: "getSingleParentbyCenter",
                                                    pk_par_key: selectedOption["value"]
                                                });
                                                
                                                if (response.data.success === true) {

                                                    if (response.data.data.length > 0) {
                                                       
                                                        handleChange({ target: { name: 'ps_relationship', value: response.data.data[0].ps_relationship } });
                                                        handleChange({ target: { name: 'par_firstname', value: response.data.data[0].par_firstname } });
                                                        handleChange({ target: { name: 'par_surname', value: response.data.data[0].par_surname } });
                                                        handleChange({ target: { name: 'par_emailid', value: response.data.data[0].par_emailid } });
                                                        handleChange({ target: { name: 'par_phone', value: response.data.data[0].par_phone } });
                                                        handleChange({ target: { name: 'par_address', value: response.data.data[0].par_address } });
                                                        handleChange({ target: { name: 'par_city', value: response.data.data[0].par_city } });
                                                        handleChange({ target: { name: 'par_country', value: response.data.data[0].par_country } });
                                                        handleChange({ target: { name: 'par_state', value: response.data.data[0].par_state } });
                                                        handleChange({ target: { name: 'par_zip', value: response.data.data[0].par_zip } });

                                                    } else {
                                                        
                                                        handleChange({ target: { name: 'ps_relationship', value: '' } });
                                                        handleChange({ target: { name: 'par_firstname', value: '' } });
                                                        handleChange({ target: { name: 'par_surname', value: '' } });
                                                        handleChange({ target: { name: 'par_emailid', value: '' } });
                                                        handleChange({ target: { name: 'par_phone', value: '' } });
                                                        handleChange({ target: { name: 'par_address', value: '' } });
                                                        handleChange({ target: { name: 'par_city', value: '' } });
                                                        handleChange({ target: { name: 'par_country', value: '' } });
                                                        handleChange({ target: { name: 'par_state', value: '' } });
                                                        handleChange({ target: { name: 'par_zip', value: '' } });
                                                    }



                                                } else {
                                                    // console.log("Error fetching abbreviation data");
                                                }

                                                // Update the "yl_abbreviation" field in the Formik values

                                            } catch (error) {
                                                // Handle any errors that occur during the API call
                                                console.error("Error fetching abbreviation data:", error);
                                            }
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ex_par_key: true });
                                        }}
                                        className={errors.ex_par_key && touched.ex_par_key ? "input-error" : "mb-0"}
                                    />
                                    {errors.ex_par_key && touched.ex_par_key && <p className="error">{errors.ex_par_key}</p>}
                                </Col>

                            </div>

                            <Row>



                                {/* --------------Student Parents / Guardians --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Relationship</h6>
                                    <Form.Control type="text"
                                        value={values.ps_relationship}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ps_relationship"
                                        className={errors.ps_relationship && touched.ps_relationship ? "input-error" : ""}
                                    />
                                    {errors.ps_relationship && touched.ps_relationship && <p className="error">{errors.ps_relationship}</p>}
                                </Col>


                                {/* --------------Contact First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control type="text"
                                        value={values.par_firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_firstname"
                                        className={errors.par_firstname && touched.par_firstname ? "input-error" : ""} />
                                    {errors.par_firstname && touched.par_firstname && <p className="error">{errors.par_firstname}</p>}
                                </Col>

                                {/* --------------Contact SurName--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last Name</h6>
                                    <Form.Control type="text"
                                        value={values.par_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_surname"
                                        className={errors.par_surname && touched.par_surname ? "input-error" : ""} />
                                    {errors.par_surname && touched.par_surname && <p className="error">{errors.par_surname}</p>}
                                </Col>

                                {/* --------------Contact Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Email</h6>
                                    <Form.Control type="email"
                                        value={values.par_emailid}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_emailid"
                                        className={errors.par_emailid && touched.par_emailid ? "input-error" : "mb-0"}
                                    />
                                    {errors.par_emailid && touched.par_emailid && <p className="error">{errors.par_emailid}</p>}
                                </Col>

                                {/* --------------Student phone--------------- */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Contact Number</h6>
                                    {/* <Form.Control type="number" 
                                     value={values.par_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="par_phone"
                                     className={errors.par_phone && touched.par_phone ? "input-error" : ""} 
                                      />
                                    {errors.par_phone && touched.par_phone && <p className="error">{errors.par_phone}</p>} */}
                                    <PhoneInput
                                        name="par_phone"
                                         countryCodeEditable={false}
                                        onlyCountries={['sg','nz','gb','au']}
                                        country={sessionStorage.getItem('country_alph_code') != null && sessionStorage.getItem('country_alph_code') != '' ? sessionStorage.getItem('country_alph_code').toLowerCase() : 'sg'} // Set the default country (e.g., singapore)
                                        value={values.par_phone}
                                        onChange={(phone, country) => {
                                            const event = {
                                                target: {
                                                    name: "par_phone",
                                                    value: phone,
                                                },
                                            };
                                            const event1 = {
                                                target: {
                                                    name: "par_countryCode",
                                                    value: country.dialCode,
                                                },
                                                };
                                            handleChange(event1);
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, par_phone: true });
                                        }}
                                        inputProps={{id: 'par_phone'}}
                                        id="par_phone"
                                        inputClass={errors.par_phone && touched.par_phone ? 'input-error form-control' : 'form-control'}
                                    />
                                    {errors.par_phone && touched.par_phone && <span className="error">{errors.par_phone}</span>}
                                </Col>




                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Contact Details End--------------- */}
                    <Stack className='mt-3'>
                        <div className="my-card p-3 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details</h6>
                            </div>

                            <Row>
                                {/* --------------Contact Address1--------------- */}
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Address</h6>
                                    <Form.Control as="textarea" rows={3}
                                        value={values.par_address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_address"
                                        className={errors.par_address && touched.par_address ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.par_address && touched.par_address && <p className="error">{errors.par_address}</p>}
                                </Col>
                                {/* --------------Student city--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">City/Town</h6>
                                    <Form.Control type="text"
                                        value={values.par_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_city"
                                        className={errors.par_city && touched.par_city ? "input-error" : ""}
                                    />
                                    {errors.par_city && touched.par_city && <p className="error">{errors.par_city}</p>}
                                </Col>

                                {/* --------------Student state--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">County/Region/State</h6>
                                    <Form.Control type="text"
                                        value={values.par_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_state"
                                        className={errors.par_state && touched.par_state ? "input-error" : ""}
                                    />
                                    {errors.par_state && touched.par_state && <p className="error">{errors.par_state}</p>}
                                </Col>

                                {/* --------------Student Country--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mt-3'>
                                    <h6 className="form-lable ">Country</h6>
                                     <Select menuPlacement="auto"  options={options_countries}
                                        name="par_country"
                                        value={options_countries.find(option => option.value && values.par_country && option.value.toString() === values.par_country.toString())}
                                        id="par_country"
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "par_country",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, par_country: true });
                                        }}
                                        className={errors.par_country && touched.par_country ? "input-error" : ""}
                                    />
                                    {errors.par_country && touched.par_country && <p className="error">{errors.par_country}</p>}
                                </Col>


                                {/* --------------Student Postal Code--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Postal Code</h6>
                                    <Form.Control type="text"
                                        value={values.par_zip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="par_zip"
                                        className={errors.par_zip && touched.par_zip ? "input-error" : "mb-0"}
                                    />
                                    {errors.par_zip && touched.par_zip && <p className="error">{errors.par_zip}</p>}
                                </Col>
                                {/* -------------- How did you hear about us? --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">How did you hear about us?</h6>
                                     <Select menuPlacement="auto"  options={options_campaign}
                                        name="lead_ref_campaign"
                                        value={values.lead_ref_campaign["value"]}
                                        id="lead_ref_campaign"
                                        onChange={(selectedOption) => {
                                            const value = selectedOption.value;
                                            console.log(value);
                                            if (value === "Others") {
                                              setShowOtherField(true);
                                             
                                              const event = {
                                                  target: {
                                                    name: "lead_ref_campaign",
                                                    value: selectedOption["value"],
                                                  },
                                                };
                        
                                                handleChange(event);
                                          } else {
                                              setShowOtherField(false);
                                              const event = {
                                                target: {
                                                  name: "lead_ref_campaign",
                                                  value: selectedOption["value"],
                                                },
                                              };
                      
                                              handleChange(event);
                                              const resetEvent = {
                                                target: {
                                                  name: "lead_ref_campaign_others",
                                                  value: null,
                                                },
                                              };
                                      
                                              handleChange(resetEvent);
                                            }
                                          }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_ref_campaign: true });
                                        }}
                                        className={errors.lead_ref_campaign && touched.lead_ref_campaign ? "input-error" : ""}
                                    />
                                    {errors.lead_ref_campaign && touched.lead_ref_campaign && <p className="error">{errors.lead_ref_campaign}</p>}
                                </Col>
                                {showOtherField && (
                                    <Col lg="4" md="6" sm="12" className="position-relative">
                                        <h6 className="form-lable">Please specify</h6>
                                        <Form.Control
                                        type="text"
                                        id="lead_ref_campaign_others"
                                        name="lead_ref_campaign_others"
                                        value={values.lead_ref_campaign_others }
                                        onChange={handleChange}
                                        onBlur={() =>
                                            setTouched({ ...touched, lead_ref_campaign_others: true })
                                        }
                                        className={
                                            errors.lead_ref_campaign_others && touched.lead_ref_campaign_others
                                            ? "input-error"
                                            : ""
                                        }
                                        />
                                        {errors.lead_ref_campaign_others && touched.lead_ref_campaign_others && (
                                        <p className="error">{errors.lead_ref_campaign_others}</p>
                                        )}
                                    </Col>
                                    )}
                                {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative' style={{ display: 'none' }}>
                                    <h6 className="form-lable ">Timezone</h6>
                                     <Select menuPlacement="auto"  options={options_time_zone}
                                        name="st_timezone"
                                        value={options_time_zone.find(option => option.value && values.st_timezone && option.value.toString() === values.st_timezone.toString())}
                                        id="st_timezone"
                                        isDisabled={true}
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "st_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, st_timezone: true });
                                        }}
                                        className={errors.st_timezone && touched.st_timezone ? "input-error" : ""}
                                    />
                                    {errors.st_timezone && touched.st_timezone && <p className="error">{errors.st_timezone}</p>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>




                    {/* --------------Lead Details--------------- */}

                    <Stack className='mt-3'>
                        <div className="my-card p-3 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Lead Details </h6>
                            </div>

                            <Row>

                                {/* --------------Details Preferred  Method Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable"> Preferred Method Of Contact</h6>
                                     <Select menuPlacement="auto"  options={options_preferedmethodofcontact}
                                        name="lead_pref_meth_contact"
                                        value={values.lead_pref_meth_contact["value"]}
                                        id="lead_pref_meth_contact"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_pref_meth_contact",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_pref_meth_contact: true });
                                        }}
                                        className={errors.lead_pref_meth_contact && touched.lead_pref_meth_contact ? "input-error" : ""}
                                    />
                                    {errors.lead_pref_meth_contact && touched.lead_pref_meth_contact && <p className="error">{errors.lead_pref_meth_contact}</p>}

                                </Col>

                                {/* --------------Details Preferred  Time Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable"> Preferred Time To Contact</h6>
                                    {/* <Form.Control type="time"

                                        value={values.lead_pref_tim_contact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="lead_pref_tim_contact"
                                        className={errors.lead_pref_tim_contact && touched.lead_pref_tim_contact ? "input-error" : ""}
                                    />
                                    {errors.lead_pref_tim_contact && touched.lead_pref_tim_contact && <p className="error">{errors.lead_pref_tim_contact}</p>} */}

                                        <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                        <TimePicker 
                                        value={dayjs(values.lead_pref_tim_contact, 'HH:mm:ss')}
                                        onChange={(time) =>  {
                                        const event = {
                                        target: {
                                            name: "lead_pref_tim_contact",
                                            value: time!= null ? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                        },
                                        };
                                        
                                        handleChange(event);
                                        
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_pref_tim_contact: true });
                                        }}
                                        slotProps={{
                                            textField: { readOnly: true, size: 'small',
                                            id: 'lead_pref_tim_contact',
                                            },
                                        }}
                                        className={errors.lead_pref_tim_contact && touched.lead_pref_tim_contact ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                        />
                                    
                                    </LocalizationProvider>
                                
                                    {errors.lead_pref_tim_contact && touched.lead_pref_tim_contact && <span className="error">{errors.lead_pref_tim_contact}</span>}
                                </Col>



                                {/* --------------Details Referral Source--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative mb-3'>
                                    <h6 className="form-lable">Referral Source</h6>
                                     <Select menuPlacement="auto"  options={options_refferalsource}
                                        name="lead_ref_source"
                                        value={values.lead_ref_source["value"]}
                                        id="lead_ref_source"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "lead_ref_source",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, lead_ref_source: true });
                                        }}
                                        className={errors.lead_ref_source && touched.lead_ref_source ? "input-error" : ""}
                                    />
                                    {errors.lead_ref_source && touched.lead_ref_source && <p className="error">{errors.lead_ref_source}</p>}
                                </Col>


                                {/* -------------- Details Status --------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Status Date</h6>
                                    {/* <Form.Control
                                        type="date"
                                        max={new Date().toISOString().split("T")[0]}
                                        value={values.ls_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ls_date"
                                        className={errors.ls_date && touched.ls_date ? "input-error" : ""}
                                    />
                                    {errors.ls_date && touched.ls_date && <p className="error">{errors.ls_date}</p>} */}
                                    <LocalizationProvider className={'form-control'} dateAdapter={AdapterDayjs}>
                                        <DatePicker  views={['year', 'month', 'day']}
                                            value={dayjs(values.ls_date, 'YYYY-MM-DD')}
                                            onChange={(date) =>  {
                                                const event = {
                                                target: {
                                                    name: "ls_date",
                                                    value: date != null ? dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : date,
                                                },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, ls_date: true });
                                            }}
                                            name="ls_date"
                                            slotProps={{
                                                textField: { readOnly: true, size: 'small',
                                                    id: 'ls_date',
                                                },
                                                }}
                                            className={errors.ls_date && touched.ls_date ? "input-error w-100" : "w-100"}
                                            minDate={dayjs()}
                                            format="DD-MM-YYYY"
 />
                                    </LocalizationProvider>
                                    {errors.ls_date && touched.ls_date && <span className="error">{errors.ls_date}</span>}
                                </Col>

                                {/* --------------Details Preferred  Method Of Contact--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Status</h6>
                                     <Select menuPlacement="auto"  options={options_status}
                                        name="ls_status"
                                        value={values.ls_status["value"]}
                                        id="ls_status"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "ls_status",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, ls_status: true });
                                        }}
                                        className={errors.ls_status && touched.ls_status ? "input-error" : ""}

                                    />
                                    {errors.ls_status && touched.ls_status && <p className="error">{errors.ls_status}</p>}

                                </Col>
                                {/* --------------description--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable">Status Description</h6>
                                    <Form.Control
                                        as="textarea" rows={3}

                                        value={values.ls_description}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ls_description"
                                        className={errors.ls_description && touched.ls_description ? "input-error control_textarea" : "control_textarea mb-0"}
                                    />
                                    {errors.ls_description && touched.ls_description && <p className="error">{errors.ls_description}</p>}
                                </Col>
                            </Row>
                        </div>
                    </Stack>




                    <Stack gap={2} className="mt-3">
                        <div className="text-end">

                            <Button variant="contained" className="primary-gray me-2" onClick={onGroupContainerClick}>Cancel</Button>
                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Save</Button>
                        </div>
                    </Stack>

                </div>

            </Form>
        </>
    );
}

export default AddLead;