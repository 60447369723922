/*
Purpose: add student details
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
Updated on : 23/08/2023
updated by : KTH
*/



import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { security_key, options_time_zone, options_countries, options_time_zone_identifier, BASE_URL } from "../global";

/*Form validation*/

import { useFormik } from "formik";
import * as yup from "yup";

import "./add-student.css";

import axios from "axios";

const options_week_day = [
    { value: "", label: "--Select--", isDisabled: true },
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thusday", label: "Thusday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
];

const options_attendance = [
    { value: "", label: "--Select--", isDisabled: true },
    { value: "Pending", label: "Pending" },
    { value: "Completed", label: "Completed" },
];

/*Form validation*/

//const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const basicSchema = yup.object().shape({
    cent_name: yup.string().required("Centre name is required.").min(2, "Minimum 2 characters is required.").max(100, "Maximum 100 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    ci_first_name: yup.string().required("First name is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
    ci_surname: yup.string().required("Last Name is required.").min(1, "Minimum 1 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Letters and spaces only at between."),
    ca_login_id: yup.string().required("Username is required.").min(2, "Minimum 2 characters is required.").max(30, "Maximum 30 characters is required.").matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    cent_emailid: yup.string().email("Please enter a valid email address").required("Email is required.").matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
        return value && value.includes('.');
      }),
    ca_password: yup.string().min(5, "Minimum 5 characters is required.").required("Password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    ca_cpassword: yup
        .string()
        .oneOf([yup.ref("ca_password"), null], "Password is not matching")
        .required("Confirm password is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
   // cent_location: yup.string().required("Location is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    // cent_weekday: yup.mixed().required("Week Day is required."),
    // cent_resetdate: yup.mixed().required("My point reset date is required."),
   
    cent_Process_attendence: yup.mixed().required("Process Attendence - Default pending to is required."),
    cent_timezone: yup.mixed().required("Timezone is required."),
    cent_timezone_identifier: yup.mixed().required("Timezone identifier is required."),
    cent_address: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("Address is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma"),
    cent_country: yup.mixed().required("Country is required."),
    cent_city: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required(" City/Town is  required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_state: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").required("State is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    cent_zip:  yup.string().min(4, "Minimum 4 characters is required.").max(8, "Maximum 8 characters is required.").required("Postcode is required.").matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),
    cent_comis_amt: yup
        .number()
        .required("Percentage is required.")
        .min(0, "Percentage must be greater than or equal to 0.")
        .max(100, "Percentage must be less than or equal to 100")
});

const initialValues = {
    /*tbl_center_master*/
    cent_name: "", //Centre name-1
    cent_address: "",
    cent_city: "",
    cent_state: "", //State-11
    cent_country: "",
    cent_zip: "",
    cent_location: "", //Location-8
    cent_emailid: "", //Email-5
    cent_website: "",
    cent_weekday: "",
    cent_timezone: '',
    cent_timezone_identifier: '',
    // cent_weekday: { value: "", label: "--Select--", isDisabled: true },
    // cent_weekday: {
    //     value: null,
    // }, //Week Day-9
    cent_resetdate: "", //My point reset date-10
    cent_VAT: "",
    cent_email_sig: "",
    cent_elec_sig: "",
    cent_Process_attendence: {
        value: null,
        options_attendance,
    }, //Process Attendence - Default pending to-12   //Not in db

    /*tbl_center_auth*/
    ca_login_id: "", //Login user name-4
    ca_password: "", //Password-6
    ca_cpassword: "", //Confirm password-7   //Not in db

    /*tbl_center_incharge*/
    ci_first_name: "", //First name-2
    ci_surname: "", //Surname-3
    ci_details: "",

    cent_comis_amt: '',

    created_by: sessionStorage.getItem('key'),
    updated_by: sessionStorage.getItem('key'),
    visibility: "1",
};

const AddAdmin = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    const navigate = useNavigate();

   



    // const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched, resetForm, setValues } = useFormik({
    const formik = useFormik({
        initialValues,
        validationSchema: basicSchema,
        // onSubmit,
        onSubmit: async (formValues) => {
            setIsSubmitting(true);


            try {
                const response = await axios.post(BASE_URL + 'API/api_index.php', {
                    security_key: security_key,
                    mode: "addCenterModuleData_new",
                    ...formValues,
                });

                if (response.data.success === true) {
                    // Reset the form
                    //formRef.current.reset();
                    if (response.data.data === 1) {
                        toast.success("Success! Your form was submitted", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        // After 3 seconds, redirect to another page
                        setTimeout(function () {
                            navigate("/admin/admin-list");
                            setIsSubmitting(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                    } else {
                        toast.error("Failure! Unable to create centre. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });

                        setTimeout(function () {
                            setIsSubmitting(false); // Re-enable the submit button
                        }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
                    }

                } else {

                    if (response.data.data === 3) {
                        toast.error("Centre name is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                    } else if (response.data.data === 2) {
                        toast.error("Username is not available", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                    }else if (response.data.data === 4) {
                        toast.error("Email already exists", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                    } else {



                        toast.error("Failure! Unable to create centre. Try again later", {
                            autoClose: 3000, // 3 seconds in this example
                        });
                    }

                   // setTimeout(function () {
                        setIsSubmitting(false); // Re-enable the submit button
                    //}, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                }
            } catch (error) {
                toast.error("Failure! Unable to create centre. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setTimeout(function () {
                    setIsSubmitting(false); // Re-enable the submit button
                }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
            } finally {
                setTimeout(function () {
                    setIsSubmitting(false); // Re-enable the submit button
                }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
            }

        },
    });

    // Now, you can set variables as needed:
    const values = formik.values;
    const handleBlur = formik.handleBlur;
    const touched = formik.touched;
    const handleChange = formik.handleChange;
    const errors = formik.errors;
    const handleSubmit = formik.handleSubmit;
    const setTouched = formik.setTouched;
    const resetForm = formik.resetForm;
    const setValues = formik.setValues;
    const handleReset = formik.handleReset;

    // console.log(formik);
    // console.log(errors.ca_login_id);
   // console.log("weekday:" + values.cent_weekday);

    const resetFormFields = (resetForm, setValues, initialValues) => {
        resetForm(); // Reset Formik-controlled fields
        // setValues({
        //     ...initialValues, // Reset select fields
        // });
        values.cent_weekday.defaultValue = options_week_day[0];
        // defaultValue={options_week_day[0]}
    };

    const handleUsernameBlur = useCallback(async () => {
        const username = formik.values.ca_login_id;
        if (username) {
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    userID: username,
                    security_key: security_key,
                    mode: "checkCenterUserId",
                });

                if (response.data.success === true) {
                    formik.setFieldTouched("ca_login_id", true, false);
                    formik.setFieldError("ca_login_id", "Username is not available");
                } else {
                    formik.setFieldTouched("ca_login_id", true, false);
                    formik.setFieldError("ca_login_id", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik.setFieldError("ca_login_id", null);
            }
        }
    }, [formik.values.ca_login_id]);


    const handleUsernameBlur1 = useCallback(async () => {
        const cent_name = formik.values.cent_name;
        if (cent_name) {
            try {
                const response = await axios.post(BASE_URL + "API/api_index.php", {
                    cent_name: cent_name,
                    security_key: security_key,
                    mode: "checkCenterName",
                });

                if (response.data.success === true) {
                    formik.setFieldTouched("cent_name", true, false);
                    formik.setFieldError("cent_name", "Centre Name is not available");
                } else {
                    formik.setFieldTouched("cent_name", true, false);
                    formik.setFieldError("cent_name", null);
                }
            } catch (error) {
                console.error("Error checking username:", error);
                formik.setFieldError("cent_name", null);
            }
        }
    }, [formik.values.cent_name]);

   
    const handleSave = () => {
        navigate("/admin/admin-list");
    };

    useEffect(() => {
        if (!formik.isSubmitting) return;
        if (Object.keys(formik.errors).length > 0) {
            const firstErrorField = Object.keys(formik.errors)[0];
            //const firstErrorFieldId = `${firstErrorField}Id`;

            const errorFieldElement = document.getElementById(firstErrorField);

            if (errorFieldElement) {
                 errorFieldElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
            } else {
                // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
            }
            //document.getElementById(Object.keys(formik.errors)[0]).focus();
        }
    }, [formik]);

    return (
        <>
            {/* React alert */}
             <ToastContainer position="top-center" />

            {/* center form */}
            <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                <div className="">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <div>
                            <h5 className='page-title'>  Centre Management </h5>
                            <div role="presentation" >
                                <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                                    <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>


                                    <Typography color="text.primary">Centre Management</Typography>
                                </Breadcrumbs>
                            </div>
                        </div>
                        {/* <div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                            </Button>
                        </div> */}
                    </div>


                    {/* --------------Student Particulars--------------- */}
                    <Stack>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Add Owner/Centre </h6>
                            </div>

                            <Row>

                                {/* --------------Centre Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Centre Name</h6>
                                    <Form.Control
                                        type="text"
                                        name="cent_name"

                                        value={values.cent_name}
                                        onChange={handleChange}
                                        onBlur={handleUsernameBlur1}
                                        id="cent_name"
                                        className={errors.cent_name && touched.cent_name ? "input-error" : ""}

                                    />
                                    {errors.cent_name && touched.cent_name && <p className="error">{errors.cent_name}</p>}
                                </Col>


                                {/* -------------- First Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">First Name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.ci_first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ci_first_name"
                                        className={errors.ci_first_name && touched.ci_first_name ? "input-error" : ""}
                                    />
                                    {errors.ci_first_name && touched.ci_first_name && <p className="error">{errors.ci_first_name}</p>}
                                </Col>


                                {/* --------------Last Name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Last Name</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.ci_surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ci_surname"
                                        className={errors.ci_surname && touched.ci_surname ? "input-error" : ""}
                                    />
                                    {errors.ci_surname && touched.ci_surname && <p className="error">{errors.ci_surname}</p>}
                                </Col>




                                {/* ------------ Email--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Email</h6>
                                    <Form.Control
                                        type="text"

                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_emailid"
                                        value={values.cent_emailid}
                                        className={errors.cent_emailid && touched.cent_emailid ? "input-error" : ""}
                                    />
                                    {errors.cent_emailid && touched.cent_emailid && <p className="error">{errors.cent_emailid}</p>}
                                </Col>

                                {/* --------------user name--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field"> Username</h6>
                                    <Form.Control
                                        type="text"
                                        name="ca_login_id"
                                        autoComplete="off"

                                        value={values.ca_login_id}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ca_login_id"
                                       // onBlur={handleUsernameBlur}
                                        className={errors.ca_login_id && touched.ca_login_id ? "input-error" : ""}
                                    />
                                    {errors.ca_login_id && touched.ca_login_id && <p className="error">{errors.ca_login_id}</p>}
                                    {/* {errors.ca_login_id && <p className="error">{errors.ca_login_id}</p>} */}
                                </Col>

                                {/* -------------- Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Password</h6>
                                    <Form.Control
                                        type="password"
                                        autoComplete="new-password"

                                        value={values.ca_password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ca_password"
                                        className={errors.ca_password && touched.ca_password ? "input-error" : ""}
                                    />
                                    {errors.ca_password && touched.ca_password && <p className="error">{errors.ca_password}</p>}
                                </Col>


                                {/* --------------Confirm Password--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Confirm Password</h6>
                                    <Form.Control
                                        type="password"

                                        value={values.ca_cpassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ca_cpassword"
                                        className={errors.ca_cpassword && touched.ca_cpassword ? "input-error" : ""}
                                    />
                                    {errors.ca_cpassword && touched.ca_cpassword && <p className="error">{errors.ca_cpassword}</p>}
                                </Col>

                                {/* --------------Student School--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Location</h6>
                                    <Form.Control
                                        type="text"

                                        value={values.cent_location}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_location"
                                        className={errors.cent_location && touched.cent_location ? "input-error" : ""}
                                    />
                                    {errors.cent_location && touched.cent_location && <p className="error">{errors.cent_location}</p>}
                                </Col> */}
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">License Fee(%)</h6>
                                    <Form.Control
                                        type="number"
                                        min={1}
                                        max={100}
                                        value={values.cent_comis_amt}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_comis_amt"
                                        className={errors.cent_comis_amt && touched.cent_comis_amt ? "input-error" : ""}
                                    />
                                    {errors.cent_comis_amt && touched.cent_comis_amt && <p className="error">{errors.cent_comis_amt}</p>}
                                </Col>

                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Student Particulars End--------------- */}
                    <Stack className='mt-4'>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Contact Details</h6>
                            </div>
                            <Row>

                                {/* --------------Contact Address1--------------- */}
                                <Col lg="12" md="12" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Address</h6>
                                    <Form.Control
                                        as="textarea" rows={3}

                                        value={values.cent_address}
                                        id="cent_address"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={errors.cent_address && touched.cent_address ? "input-error control_textarea" : "control_textarea"}
                                    />
                                    {errors.cent_address && touched.cent_address && <p className="error">{errors.cent_address}</p>}

                                </Col>
                                {/* --------------Student city--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">City/Town</h6>
                                    <Form.Control type="text"
                                        value={values.cent_city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_city"
                                        className={errors.cent_city && touched.cent_city ? "input-error" : ""}
                                    />
                                    {errors.cent_city && touched.cent_city && <p className="error">{errors.cent_city}</p>}
                                </Col>

                                {/* --------------Student state--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control type="text"
                                        value={values.cent_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_state"
                                        className={errors.cent_state && touched.cent_state ? "input-error" : ""}
                                    />
                                    {errors.cent_state && touched.cent_state && <p className="error">{errors.cent_state}</p>}
                                </Col>

                                {/* --------------Student Country--------------- */}


                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Country</h6>
                                    <Select menuPlacement="auto" options={options_countries}
                                        name="cent_country"
                                        value={options_countries.find(option => option.value && values.cent_country && option.value.toString() === values.cent_country.toString())}
                                        id="cent_country"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_country",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_country: true });
                                        }}
                                        className={errors.cent_country && touched.cent_country ? "input-error" : ""}
                                    />
                                    {errors.cent_country && touched.cent_country && <span className="error">{errors.cent_country}</span>}
                                </Col>

                                {/* --------------Student Postal Code--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Postal Code</h6>
                                    <Form.Control type="text"
                                        value={values.cent_zip}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_zip"
                                        className={errors.cent_zip && touched.cent_zip ? "input-error" : ""}
                                    />
                                    {errors.cent_zip && touched.cent_zip && <p className="error">{errors.cent_zip}</p>}
                                </Col>

                                {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Timezone</h6>
                                    <Select menuPlacement="auto" options={options_time_zone}
                                        name="cent_timezone"
                                        value={options_time_zone.find(option => option.value && values.cent_timezone && option.value.toString() === values.cent_timezone.toString())}
                                        id="cent_timezone"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_timezone",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_timezone: true });
                                        }}
                                        className={errors.cent_timezone && touched.cent_timezone ? "input-error" : ""}
                                    />
                                    {errors.cent_timezone && touched.cent_timezone && <span className="error">{errors.cent_timezone}</span>}
                                </Col>
                                {/* --------------Student Timezone--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Timezone Identifier</h6>
                                    <Select menuPlacement="auto" options={options_time_zone_identifier}
                                        name="cent_timezone_identifier"
                                        value={options_time_zone_identifier.find(option => option.value && values.cent_timezone_identifier && option.value.toString() === values.cent_timezone_identifier.toString())}
                                        id="cent_timezone_identifier"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_timezone_identifier",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_timezone_identifier: true });
                                        }}
                                        className={errors.cent_timezone_identifier && touched.cent_timezone_identifier ? "input-error" : ""}
                                    />
                                    {errors.cent_timezone_identifier && touched.cent_timezone_identifier && <span className="error">{errors.cent_timezone_identifier}</span>}
                                </Col>


                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Payment Details--------------- */}
                    <Stack className='mt-4' style={{ display: 'none' }}>
                        <div className="my-card p-4 bg-white">
                            <div className='section-heading border-bottom pb-3 mb-3'>
                                <h6 className='mb-0'>Defaults </h6>
                            </div>

                            <Row>

                                {/* ------------ Week day--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">Week Day</h6>
                                    {/* <Form.Control type="date"  /> */}
                                    <Select menuPlacement="auto"
                                        options={options_week_day}
                                        name="cent_weekday"
                                        value={values.cent_weekday["value"]}
                                        id="cent_weekday"
                                        onChange={(selectedOption) => {
                                            const event = {
                                                target: {
                                                    name: "cent_weekday",
                                                    value: selectedOption["value"],
                                                },
                                            };
                                            handleChange(event);
                                        }}
                                        onBlur={(event) => {
                                            handleBlur(event);
                                            setTouched({ ...touched, cent_weekday: true });
                                        }}
                                        className={errors.cent_weekday && touched.cent_weekday ? "input-error" : ""}
                                    />
                                    {errors.cent_weekday && touched.cent_weekday && <p className="error">{errors.cent_weekday}</p>}
                                </Col>

                                {/* ------------ My point reset date day--------------- */}

                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">My point reset date</h6>
                                    <Form.Control
                                        type="date"

                                        value={values.cent_resetdate}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_resetdate"
                                        className={errors.cent_resetdate && touched.cent_resetdate ? "input-error" : ""}
                                    />
                                    {errors.cent_resetdate && touched.cent_resetdate && (
                                        <p className="error">{errors.cent_resetdate}</p>
                                    )}
                                </Col>


                                {/* ------------ My point reset date day--------------- */}

                                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable required-field">County/Region/State</h6>
                                    <Form.Control
                                        type="text"
                                        
                                        value={values.cent_state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="cent_state"
                                        className={errors.cent_state && touched.cent_state ? "input-error" : ""}
                                    />
                                    {errors.cent_state && touched.cent_state && <p className="error">{errors.cent_state}</p>}
                                </Col> */}

                                {/* ------------ Process Attendence- default pending to--------------- */}
                                {/* <Form.Group as={Row} className="mb-3" controlId="cent_Process_attendence">
                                    <Form.Label column sm="3">
                                        <h6>Process Attendence - Default pending to</h6>
                                    </Form.Label>
                                    <Col sm="9">
                                        
                                         <Select menuPlacement="auto" 
                                            options={options_attendance}
                                            
                                            name="cent_Process_attendence"
                                            value={values.cent_Process_attendence["value"]}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: "cent_Process_attendence",
                                                        value: selectedOption["value"],
                                                    },
                                                };
                                                handleChange(event);
                                            }}
                                            onBlur={(event) => {
                                                handleBlur(event);
                                                setTouched({ ...touched, cent_Process_attendence: true });
                                            }}
                                            className={
                                                errors.cent_Process_attendence && touched.cent_Process_attendence ? "input-error" : ""
                                            }
                                        />

                                        {errors.cent_Process_attendence && touched.cent_Process_attendence && (
                                            <p className="error">{errors.cent_Process_attendence}</p>
                                        )}
                                    </Col>
                                </Form.Group> */}

                                {/* --------------Select Day of Month--------------- */}

                                <Col sm="12">
                                    <Col xs={12} md={12} className="text-end">
                                        <Button variant="contained" className="primary-filled mt-3">
                                            Connect Strip Account
                                        </Button>
                                    </Col>
                                </Col>

                            </Row>
                        </div>
                    </Stack>

                    {/* --------------Payment Details End--------------- */}

                    <Stack gap={2} className="mt-4">
                        <div className="text-end">
                            <Button variant="contained" className="primary-gray text-white me-2" onClick={handleSave}>

                                Cancel
                            </Button>
                            <Button type="submit" variant="contained" className="primary-filled" disabled={isSubmitting}>
                                Save
                            </Button>
                        </div>
                    </Stack>

                </div>
            </Form>
        </>
    );
};

export default AddAdmin;
