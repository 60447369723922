
import axios from "axios";
import { useState, useCallback, useRef, useEffect } from "react";


const security_key = process.env.REACT_APP_SECURITY_KEY;
const themeDefault = 'dark'
const namesOfModes = ['dark', 'moonlight', 'eclipse', 'light']

const BASE_URL = process.env.REACT_APP_BASE_URL;

const options_salutation = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Dr', label: 'Dr' },
  ];
  

  const options_paymen_frequecy = [
    { value: '', label: '---Select---' },
    { value: 'Weekly', label: 'Weekly' },
    { value: 'Fortnightly', label: 'Fortnightly' },
    { value: 'Monthly', label: 'Monthly' },
  ];


  const options_countries = []; // Change from const to let
  
  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        mode: "getAllCountry"
      });
  
      if (response.data.success === true) {

        const formattedOptions = response.data.data.map(option => ({
          value: option.country_id,
          label: option.country_name,
        }));

        options_countries.push(...formattedOptions);
       
      } else {
        // Handle error or show an alert message
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  fetchData(); // Use an empty dependency array to run this effect once when the component mounts


  
  
  const options_countries1 = [
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Åland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua and Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AU', label: 'Australia' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
  { value: 'BA', label: 'Bosnia and Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cape Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo, the Democratic Republic of the' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: 'Côte d\'Ivoire' },
  { value: 'HR', label: 'Croatia' },
  { value: 'CU', label: 'Cuba' },
  { value: 'CW', label: 'Curaçao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island and McDonald Mcdonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IR', label: 'Iran, Islamic Republic of' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'KP', label: 'Korea, Democratic People\'s Republic of' },
  { value: 'KR', label: 'Korea, Republic of' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Lao People\'s Democratic Republic' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MK', label: 'Macedonia, the Former Yugoslav Republic of' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia, Federated States of' },
  { value: 'MD', label: 'Moldova, Republic of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Réunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russian Federation' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthélemy' },
  { value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha' },
  { value: 'KN', label: 'Saint Kitts and Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French part)' },
  { value: 'PM', label: 'Saint Pierre and Miquelon' },
  { value: 'VC', label: 'Saint Vincent and the Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome and Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SD', label: 'Sudan' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard and Jan Mayen' },
  { value: 'SZ', label: 'Swaziland' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'SY', label: 'Syrian Arab Republic' },
  { value: 'TW', label: 'Taiwan, Province of China' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania, United Republic of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad and Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks and Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'US', label: 'United States' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands, British' },
  { value: 'VI', label: 'Virgin Islands, U.S.' },
  { value: 'WF', label: 'Wallis and Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' }
  ]
  
  
  
  const options_time_zone = [
    { value: "-12:00", label: "(GMT -12:00) Eniwetok, Kwajalein"},
    { value: "-11:00", label: "(GMT -11:00) Midway Island, Samoa"},
    { value: "-10:00", label: "(GMT -10:00) Hawaii"},
    { value: "-09:50", label: "(GMT -9:30) Taiohae"},
    { value: "-09:00", label: "(GMT -9:00) Alaska"},
    { value: "-08:00", label: "(GMT -8:00) Pacific Time (US & Canada)"},
    { value: "-07:00", label: "(GMT -7:00) Mountain Time (US & Canada)"},
    { value: "-06:00", label: "(GMT -6:00) Central Time (US & Canada), Mexico City"},
    { value: "-05:00", label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima"},
    { value: "-04:50", label: "(GMT -4:30) Caracas"},
    { value: "-04:00", label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz"},
    { value: "-03:50", label: "(GMT -3:30) Newfoundland"},
    { value: "-03:00", label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown"},
    { value: "-02:00", label: "(GMT -2:00) Mid-Atlantic"},
    { label: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00" },
    { label: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00"},
    { label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00"},
    { label: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00"},
    { label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00"},
    { label: "(GMT +3:30) Tehran", value: "+03:50"},
    { label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00"},
    { label: "(GMT +4:30) Kabul", value: "+04:50"},
    { label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00"},
    { label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50"},
    { label: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75"},
    { label: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00"},
    { label: "(GMT +6:30) Yangon, Mandalay", value: "+06:50"},
    { label: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00"},
    { label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00"},
    { label: "(GMT +8:45) Eucla", value: "+08:75"},
    { label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00"},
    { label: "(GMT +9:30) Adelaide, Darwin", value: "+09:50"},
    { label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00"},
    { label: "(GMT +10:30) Lord Howe Island", value: "+10:50"},
    { label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00"},
    { label: "(GMT +11:30) Norfolk Island", value: "+11:50"},
    { label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00"},
    { label: "(GMT +12:45) Chatham Islands", value: "+12:75"},
    { label: "(GMT +13:00) Apia, Nukualofa", value: "+13:00"},
    { label: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00"}
  ]
//   const options_time_zone_identifier = [
//     { value: "Etc/GMT+12", label: "(GMT-12:00) International Date Line West" },
//     { value: "Pacific/Midway", label: "(GMT-11:00) Midway Island, Samoa" },
//     { value: "Pacific/Honolulu", label: "(GMT-10:00) Hawaii" },
//     { value: "US/Alaska", label: "(GMT-09:00) Alaska" },
//     { value: "America/Los_Angeles", label: "(GMT-08:00) Pacific Time (US & Canada)" },
//     { value: "America/Tijuana", label: "(GMT-08:00) Tijuana, Baja California" },
//     { value: "US/Arizona", label: "(GMT-07:00) Arizona" },
//     { value: "America/Chihuahua", label: "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
//     { value: "US/Mountain", label: "(GMT-07:00) Mountain Time (US & Canada)" },
//     { value: "America/Managua", label: "(GMT-06:00) Central America" },
//     { value: "US/Central", label: "(GMT-06:00) Central Time (US & Canada)" },
//     { value: "America/Mexico_City", label: "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
//     { value: "Canada/Saskatchewan", label: "(GMT-06:00) Saskatchewan" },
//     { value: "America/Bogota", label: "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
//     { value: "US/Eastern", label: "(GMT-05:00) Eastern Time (US & Canada)" },
//     { value: "US/East-Indiana", label: "(GMT-05:00) Indiana (East)" },
//     { value: "Canada/Atlantic", label: "(GMT-04:00) Atlantic Time (Canada)" },
//     { value: "America/Caracas", label: "(GMT-04:00) Caracas, La Paz" },
//     { value: "America/Manaus", label: "(GMT-04:00) Manaus" },
//     { value: "America/Santiago", label: "(GMT-04:00) Santiago" },
//     { value: "Canada/Newfoundland", label: "(GMT-03:30) Newfoundland" },
//     { value: "America/Sao_Paulo", label: "(GMT-03:00) Brasilia" },
//     { value: "America/Argentina/Buenos_Aires", label: "(GMT-03:00) Buenos Aires, Georgetown" },
//     { value: "America/Godthab", label: "(GMT-03:00) Greenland" },
//     { value: "America/Montevideo", label: "(GMT-03:00) Montevideo" },
//     { value: "America/Noronha", label: "(GMT-02:00) Mid-Atlantic" },
//     { value: "Atlantic/Cape_Verde", label: "(GMT-01:00) Cape Verde Is." },
//     { value: "Atlantic/Azores", label: "(GMT-01:00) Azores" },
//     { value: "Africa/Casablanca", label: "(GMT+00:00) Casablanca, Monrovia, Reykjavik" },
//     { value: "Etc/Greenwich", label: "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
//     { value: "Europe/Amsterdam", label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
//     { value: "Europe/Belgrade", label: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
//     { value: "Europe/Brussels", label: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
//     { value: "Europe/Sarajevo", label: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
//     { value: "Africa/Lagos", label: "(GMT+01:00) West Central Africa" },
//     { value: "Asia/Amman", label: "(GMT+02:00) Amman" },
//     { value: "Europe/Athens", label: "(GMT+02:00) Athens, Bucharest, Istanbul" },
//     { value: "Asia/Beirut", label: "(GMT+02:00) Beirut" },
//     { value: "Africa/Cairo", label: "(GMT+02:00) Cairo" },
//     { value: "Africa/Harare", label: "(GMT+02:00) Harare, Pretoria" },
//     { value: "Europe/Helsinki", label: "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
//     { value: "Asia/Jerusalem", label: "(GMT+02:00) Jerusalem" },
//     { value: "Europe/Minsk", label: "(GMT+02:00) Minsk" },
//     { value: "Africa/Windhoek", label: "(GMT+02:00) Windhoek" },
//     { value: "Asia/Kuwait", label: "(GMT+03:00) Kuwait, Riyadh, Baghdad" },
//     { value: "Europe/Moscow", label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
//     { value: "Africa/Nairobi", label: "(GMT+03:00) Nairobi" },
//     { value: "Asia/Tbilisi", label: "(GMT+03:00) Tbilisi" },
//     { value: "Asia/Tehran", label: "(GMT+03:30) Tehran" },
//     { value: "Asia/Muscat", label: "(GMT+04:00) Abu Dhabi, Muscat" },
//     { value: "Asia/Baku", label: "(GMT+04:00) Baku" },
//     { value: "Asia/Yerevan", label: "(GMT+04:00) Yerevan" },
//     { value: "Asia/Kabul", label: "(GMT+04:30) Kabul" },
//     { value: "Asia/Yekaterinburg", label: "(GMT+05:00) Yekaterinburg" },
//     { value: "Asia/Karachi", label: "(GMT+05:00) Islamabad, Karachi, Tashkent" },
//     { value: "Asia/Calcutta", label: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
//     { value: "Asia/Calcutta", label: "(GMT+05:30) Sri Jayawardenapura" },
//     { value: "Asia/Katmandu", label: "(GMT+05:45) Kathmandu" },
//     { value: "Asia/Almaty", label: "(GMT+06:00) Almaty, Novosibirsk" },
//     { value: "Asia/Dhaka", label: "(GMT+06:00) Astana, Dhaka" },
//     { value: "Asia/Rangoon", label: "(GMT+06:30) Yangon (Rangoon)" },
//     { value: "Asia/Bangkok", label: "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
//     { value: "Asia/Krasnoyarsk", label: "(GMT+07:00) Krasnoyarsk" },
//     { value: "Asia/Hong_Kong", label: "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
//     { value: "Asia/Kuala_Lumpur", label: "(GMT+08:00) Kuala Lumpur, Singapore" },
//     { value: "Asia/Irkutsk", label: "(GMT+08:00) Irkutsk, Ulaan Bataar" },
//     { value: "Australia/Perth", label: "(GMT+08:00) Perth" },
//     { value: "Asia/Taipei", label: "(GMT+08:00) Taipei" },
//     { value: "Asia/Tokyo", label: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
//     { value: "Asia/Seoul", label: "(GMT+09:00) Seoul" },
//     { value: "Asia/Yakutsk", label: "(GMT+09:00) Yakutsk" },
//     { value: "Australia/Adelaide", label: "(GMT+09:30) Adelaide" },
//     { value: "Australia/Darwin", label: "(GMT+09:30) Darwin" },
//     { value: "Australia/Brisbane", label: "(GMT+10:00) Brisbane" },
//     { value: "Australia/Canberra", label: "(GMT+10:00) Canberra, Melbourne, Sydney" },
//     { value: "Australia/Hobart", label: "(GMT+10:00) Hobart" },
//     { value: "Pacific/Guam", label: "(GMT+10:00) Guam, Port Moresby" },
//     { value: "Asia/Vladivostok", label: "(GMT+10:00) Vladivostok" },
//     { value: "Asia/Magadan", label: "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
//     { value: "Pacific/Auckland", label: "(GMT+12:00) Auckland, Wellington" },
//     { value: "Pacific/Fiji", label: "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
//     { value: "Pacific/Tongatapu", label: "(GMT+13:00) Nuku'alofa" }
// ];

const options_time_zone_identifier = [
  { value: "Etc/GMT+12", label: "(Etc/GMT+12) (GMT-12:00) International Date Line West" },
    { value: "Pacific/Midway", label: "(Pacific/Midway) (GMT-11:00) Midway Island, Samoa" },
    { value: "Pacific/Honolulu", label: "(Pacific/Honolulu) (GMT-10:00) Hawaii" },
    { value: "US/Alaska", label: "(US/Alaska) (GMT-09:00) Alaska" },
    { value: "America/Los_Angeles", label: "(America/Los_Angeles) (GMT-08:00) Pacific Time (US & Canada)" },
    { value: "America/Tijuana", label: "(America/Tijuana) (GMT-08:00) Tijuana, Baja California" },
    { value: "US/Arizona", label: "(US/Arizona) (GMT-07:00) Arizona" },
    { value: "America/Chihuahua", label: "(America/Chihuahua) (GMT-07:00) Chihuahua, La Paz, Mazatlan" },
    { value: "US/Mountain", label: "(US/Mountain) (GMT-07:00) Mountain Time (US & Canada)" },
    { value: "America/Managua", label: "(America/Managua) (GMT-06:00) Central America" },
    { value: "US/Central", label: "(US/Central) (GMT-06:00) Central Time (US & Canada)" },
    { value: "America/Mexico_City", label: "(America/Mexico_City) (GMT-06:00) Guadalajara, Mexico City, Monterrey" },
    { value: "Canada/Saskatchewan", label: "(Canada/Saskatchewan) (GMT-06:00) Saskatchewan" },
    { value: "America/Bogota", label: "(America/Bogota) (GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
    { value: "US/Eastern", label: "(US/Eastern) (GMT-05:00) Eastern Time (US & Canada)" },
    { value: "US/East-Indiana", label: "(US/East-Indiana) (GMT-05:00) Indiana (East)" },
    { value: "Canada/Atlantic", label: "(Canada/Atlantic) (GMT-04:00) Atlantic Time (Canada)" },
    { value: "America/Caracas", label: "(America/Caracas) (GMT-04:00) Caracas, La Paz" },
    { value: "America/Manaus", label: "(America/Manaus) (GMT-04:00) Manaus" },
    { value: "America/Santiago", label: "(America/Santiago) (GMT-04:00) Santiago" },
    { value: "Canada/Newfoundland", label: "(Canada/Newfoundland) (GMT-03:30) Newfoundland" },
    { value: "America/Sao_Paulo", label: "(America/Sao_Paulo) (GMT-03:00) Brasilia" },
    { value: "America/Argentina/Buenos_Aires", label: "(America/Argentina/Buenos_Aires) (GMT-03:00) Buenos Aires, Georgetown" },
    { value: "America/Godthab", label: "(America/Godthab) (GMT-03:00) Greenland" },
    { value: "America/Montevideo", label: "(America/Montevideo) (GMT-03:00) Montevideo" },
    { value: "America/Noronha", label: "(America/Noronha) (GMT-02:00) Mid-Atlantic" },
    { value: "Atlantic/Cape_Verde", label: "(Atlantic/Cape_Verde) (GMT-01:00) Cape Verde Is." },
    { value: "Atlantic/Azores", label: "(Atlantic/Azores) (GMT-01:00) Azores" },
    { value: "Africa/Casablanca", label: "(Africa/Casablanca) (GMT+00:00) Casablanca, Monrovia, Reykjavik" },
    { value: "Europe/London", label: "(Europe/London) (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
    { value: "Europe/Amsterdam", label: "(Europe/Amsterdam) (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
    { value: "Europe/Belgrade", label: "(Europe/Belgrade) (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
    { value: "Europe/Brussels", label: "(Europe/Brussels) (GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
    { value: "Europe/Sarajevo", label: "(Europe/Sarajevo) (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
    { value: "Africa/Lagos", label: "(Africa/Lagos) (GMT+01:00) West Central Africa" },
    { value: "Asia/Amman", label: "(Asia/Amman) (GMT+02:00) Amman" },
    { value: "Europe/Athens", label: "(Europe/Athens) (GMT+02:00) Athens, Bucharest, Istanbul" },
    { value: "Asia/Beirut", label: "(Asia/Beirut) (GMT+02:00) Beirut" },
    { value: "Africa/Cairo", label: "(Africa/Cairo) (GMT+02:00) Cairo" },
    { value: "Africa/Harare", label: "(Africa/Harare) (GMT+02:00) Harare, Pretoria" },
    { value: "Europe/Helsinki", label: "(Europe/Helsinki) (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
    { value: "Asia/Jerusalem", label: "(Asia/Jerusalem) (GMT+02:00) Jerusalem" },
    { value: "Europe/Minsk", label: "(Europe/Minsk) (GMT+02:00) Minsk" },
    { value: "Africa/Windhoek", label: "(Africa/Windhoek) (GMT+02:00) Windhoek" },
    { value: "Asia/Kuwait", label: "(Asia/Kuwait) (GMT+03:00) Kuwait, Riyadh, Baghdad" },
    { value: "Europe/Moscow", label: "(Europe/Moscow) (GMT+03:00) Moscow, St. Petersburg, Volgograd" },
    { value: "Africa/Nairobi", label: "(Africa/Nairobi) (GMT+03:00) Nairobi" },
    { value: "Asia/Tbilisi", label: "(Asia/Tbilisi) (GMT+03:00) Tbilisi" },
    { value: "Asia/Tehran", label: "(Asia/Tehran) (GMT+03:30) Tehran" },
    { value: "Asia/Muscat", label: "(Asia/Muscat) (GMT+04:00) Abu Dhabi, Muscat" },
    { value: "Asia/Baku", label: "(Asia/Baku) (GMT+04:00) Baku" },
    { value: "Asia/Yerevan", label: "(Asia/Yerevan) (GMT+04:00) Yerevan" },
    { value: "Asia/Kabul", label: "(Asia/Kabul) (GMT+04:30) Kabul" },
    { value: "Asia/Yekaterinburg", label: "(Asia/Yekaterinburg) (GMT+05:00) Yekaterinburg" },
    { value: "Asia/Karachi", label: "(Asia/Karachi) (GMT+05:00) Islamabad, Karachi, Tashkent" },
    { value: "Asia/Calcutta", label: "(Asia/Calcutta) (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
    { value: "Asia/Calcutta", label: "(Asia/Calcutta) (GMT+05:30) Sri Jayawardenapura" },
    { value: "Asia/Katmandu", label: "(Asia/Katmandu) (GMT+05:45) Kathmandu" },
    { value: "Asia/Almaty", label: "(Asia/Almaty) (GMT+06:00) Almaty, Novosibirsk" },
    { value: "Asia/Dhaka", label: "(Asia/Dhaka) (GMT+06:00) Astana, Dhaka" },
    { value: "Asia/Rangoon", label: "(Asia/Rangoon) (GMT+06:30) Yangon (Rangoon)" },
    { value: "Asia/Bangkok", label: "(Asia/Bangkok) (GMT+07:00) Bangkok, Hanoi, Jakarta" },
    { value: "Asia/Krasnoyarsk", label: "(Asia/Krasnoyarsk) (GMT+07:00) Krasnoyarsk" },
    { value: "Asia/Hong_Kong", label: "(Asia/Hong_Kong) (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
    { value: "Asia/Kuala_Lumpur", label: "(Asia/Kuala_Lumpur) (GMT+08:00) Kuala Lumpur, Singapore" },
    { value: "Asia/Irkutsk", label: "(Asia/Irkutsk) (GMT+08:00) Irkutsk, Ulaan Bataar" },
    { value: "Australia/Perth", label: "(Australia/Perth) (GMT+08:00) Perth" },
    { value: "Asia/Taipei", label: "(Asia/Taipei) (GMT+08:00) Taipei" },
    { value: "Asia/Tokyo", label: "(Asia/Tokyo) (GMT+09:00) Osaka, Sapporo, Tokyo" },
    { value: "Asia/Seoul", label: "(Asia/Seoul) (GMT+09:00) Seoul" },
    { value: "Asia/Yakutsk", label: "(Asia/Yakutsk) (GMT+09:00) Yakutsk" },
    { value: "Australia/Adelaide", label: "(Australia/Adelaide) (GMT+09:30) Adelaide" },
    { value: "Australia/Darwin", label: "(Australia/Darwin) (GMT+09:30) Darwin" },
    { value: "Australia/Brisbane", label: "(Australia/Brisbane) (GMT+10:00) Brisbane" },
    { value: "Australia/Canberra", label: "(Australia/Canberra) (GMT+10:00) Canberra, Melbourne, Sydney" },
    { value: "Australia/Hobart", label: "(Australia/Hobart) (GMT+10:00) Hobart" },
    { value: "Pacific/Guam", label: "(Pacific/Guam) (GMT+10:00) Guam, Port Moresby" },
    { value: "Asia/Vladivostok", label: "(Asia/Vladivostok) (GMT+10:00) Vladivostok" },
    { value: "Asia/Magadan", label: "(Asia/Magadan) (GMT+11:00) Magadan, Solomon Is., New Caledonia" },
    { value: "Pacific/Auckland", label: "(Pacific/Auckland) (GMT+12:00) Auckland, Wellington" },
    { value: "Pacific/Fiji", label: "(Pacific/Fiji) (GMT+12:00) Fiji, Kamchatka, Marshall Is." },
    { value: "Pacific/Tongatapu", label: "(Pacific/Tongatapu) (GMT+13:00) Nuku'alofa" }

];

  
  const options_emp_type = [
    { value: "Part Time", label: "Part Time"},
    { value: "Full Time", label: "Full Time"}
  ]
  
  
  const options_wage_type = [
    { value: "Flat Rate", label: "Flat Rate"},
    { value: "Per Student", label: "Per Student"},
  ]

  const options_gender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' },
]

const options_yearlevel = []; // Change from const to let
  
  const fetchDatayearlevel = async () => {
    try {
      const response_year = await axios.post(BASE_URL+'API/api_index.php', {
        security_key: security_key,
        country_id: sessionStorage.getItem('country_code'),
        mode: "getCountryYearLevel"
      });
  
      if (response_year.data.success === true) {

        const formattedyearOptions = response_year.data.data.map(option => ({
          value: option.activity_level_id,
          label: option.level_name,
          datacurrencycode: option.country_currency

        }));

        options_yearlevel.push(...formattedyearOptions);
       
      } else {
        // Handle error or show an alert message
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  fetchDatayearlevel();
  



const options_yearlevel1 = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' }
]



const options_parent = [
  { value: "Father", label: "Father" },
  { value: "Mother", label: "Mother" },
  { value: "Guardian", label: "Guardian" },

]
const options_days = [
  { value: "2", label: "Monday" },
  { value: "3", label: "Tuesday" },
  { value: "4", label: "Wednesday" },
  { value: "5", label: "Thursday" },
  { value: "6", label: "Friday" },
  { value: "7", label: "Saturday" },
  { value: "1", label: "Sunday" }
]

// const options_days = [
//   { value: "1", label: "Monday" },
//   { value: "2", label: "Tuesday" },
//   { value: "3", label: "Wednesday" },
//   { value: "4", label: "Thursday" },
//   { value: "5", label: "Friday" },
//   { value: "6", label: "Saturday" },
//   { value: "7", label: "Sunday" }
// ]
const options_days_sessTime = [
  { value: "Monday", label: "Monday" },
  { value: "Tuesday", label: "Tuesday" },
  { value: "Wednesday", label: "Wednesday" },
  { value: "Thursday", label: "Thursday" },
  { value: "Friday", label: "Friday" },
  { value: "Saturday", label: "Saturday" },
  { value: "Sunday", label: "Sunday" }
]

const options_preferedmethodofcontact = [
  { value: '', label: '---Select---' },
  { value: 'Email', label: 'Email' },
  { value: 'Phone', label: 'Phone' },
  { value: 'Mail', label: 'Mail' },
];


const options_refferalsource = [
  { value: '', label: '---Select---' },
  { value: 'Web', label: 'Web' },
  { value: 'Phone Inquiry', label: 'Phone Inquiry' },
];

const options_campaign = [
  { value: '', label: '---Select---' },
  { value: 'Word of mouth', label: 'Word of mouth' },
  { value: 'School/teacher', label: 'School/teacher' },
  { value: 'Sibling attends', label: 'Sibling attends' },
  { value: 'Internet', label: 'Internet' },
  { value: 'Others', label: 'Others' },

];

const options_status = [
  { value: '', label: '---Select---' },
  { value: 'New Enquiry', label: 'New Enquiry' },
  { value: 'Diagnostics Taken', label: 'Diagnostics Taken' },
  { value: 'No Reply', label: 'No Reply' },
  // { value: 'Declined', label: 'Declined' }, 
];

const options_status_diagnostics = [
  { value: '', label: '---Select---' },
  { value: 'New Enquiry', label: 'New Enquiry' },
  { value: 'Diagnostics Taken', label: 'Diagnostics Taken' },
  { value: 'No Reply', label: 'No Reply' },
  { value: 'Declined', label: 'Declined' }, 
];

const options_dig_type = [
  { value: '', label: '---Select---' },
  { value: 'Online', label: 'Online' },
  { value: 'In Centre', label: 'In Centre' },
 
];

const options_duration = [
  { value: '', label: '---Select---' },
  { value: '15', label: '15 minutes' },
  { value: '30', label: '30 minutes' },
  { value: '45', label: '45 minutes' },
  { value: '1', label: '1 hour' },
  // Add more duration options as needed
];

const options_weeklydays = [
  // { value: '', label: '---Select---' },
  { value: '7', label: '7 Day Week' },
  { value: '6', label: '6 Day Week' },
  

];

const options_isholiday = [
  { value: '', label: '---Select---' },
  { value: '1', label: 'Regular' },
  { value: '2', label: 'One Off Session' },
  { value: '3', label: 'Make Up Session' },
];
const options_isholiday_enrol = [
  { value: '1', label: 'Regular' },
  { value: '2', label: 'One Off Session' },
  { value: '3', label: 'Make Up Session' },
];


const options_holidays = [
  { value: '', label: 'None' },
  { value: '1', label: 'School Holidays' },
  { value: '2', label: 'Public Holidays' },
  { value: '3', label: 'All Holidays' },
];


const options_months = [
  { value: "1", label: "1st" },
  { value: "2", label: "2nd" },
  { value: "3", label: "3rd" },
  { value: "4", label: "4th" },
  { value: "5", label: "5th" },
  { value: "6", label: "6th" },
  { value: "7", label: "7th" },
  { value: "8", label: "8th" },
  { value: "9", label: "9th" },
  { value: "10", label: "10th" },
  { value: "11", label: "11th" },
  { value: "12", label: "12th" },
  { value: "13", label: "13th" },
  { value: "14", label: "14th" },
  { value: "15", label: "15th" },
  { value: "16", label: "16th" },
  { value: "17", label: "17th" },
  { value: "18", label: "18th" },
  { value: "19", label: "19th" },
  { value: "20", label: "20th" },
  { value: "21", label: "21st" },
  { value: "22", label: "22nd" },
  { value: "23", label: "23rd" },
  { value: "24", label: "24th" },
  { value: "25", label: "25th" },
  { value: "26", label: "26th" },
  { value: "27", label: "27th" },
  { value: "28", label: "28th" },
  { value: "29", label: "29th" },
  { value: "30", label: "30th" },
  { value: "31", label: "31st" }
];


const options_banner_type = [
  // { value: '', label: '---Select---' },
  { value: '1', label: 'All countries' },
  { value: '2', label: 'Countries' },
  { value: '3', label: 'Centres' },
];

const options_attendance_status = [
  { value: '', label: '---Select---' },
  { value: '0', label: 'Pending' },
  { value: '1', label: 'Ongoing' },
  { value: '2', label: 'Attended' },
  { value: '3', label: 'Missed' },
  { value: '4', label: 'Offline' },
  // { value: '5', label: 'defer requested' },
  // { value: '6', label: 'Cancelled' },
];

export { security_key, themeDefault, namesOfModes, options_wage_type, options_emp_type, options_time_zone,
   options_countries, options_salutation, options_gender, options_yearlevel, options_parent,
    options_days, options_preferedmethodofcontact, options_refferalsource, options_status_diagnostics,options_campaign,
     options_status, options_dig_type, options_duration, options_months,BASE_URL, options_weeklydays,
     options_isholiday,options_isholiday_enrol,options_paymen_frequecy,options_days_sessTime,options_holidays,options_banner_type, options_attendance_status,options_time_zone_identifier}