/*
Purpose: View details of student
Designed: BY R.Sanjai
Date:22/08/2023 
Project: TCMS
*/

import {
  Row,
  Col,
  Stack,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Button,
  ButtonGroup,
} from "react-bootstrap";
import { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

import axios from "axios";
import {
  security_key,
  options_time_zone,
  options_countries,
  options_gender,
  options_preferedmethodofcontact,
  options_refferalsource,
  options_campaign,
  options_status,
  BASE_URL,
} from "../global";
import "react-phone-input-2/lib/style.css"; // Import CSS styles for the phone input
import PhoneInput from "react-phone-input-2";

import "./add-student.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
const phoneNumberRules = {
  'SG': {
    min: 8,
    max: 8,
    message: "Phone number must be exactly 8 digits.",
  }, // Singapore

  'AU': {
    min: 9,
    max: 10,
    message: "Phone number must be between 9 and 10 digits.",
  }, // Australia

  'GB': {
    min: 10,
    max: 10,
    message: "Phone number must be exactly 10 digits.",
  }, // United Kingdom

  'NZ': {
    min: 8,
    max: 10,
    message: "Phone number must be between 8 and 10 digits.",
  }, // New Zealand

  
  'General': {
  min: 5,
  max: 15,
  message: "Phone number must be between 5 to 15 digits.",
} // general number lenght
};
function ViewLead() {
  const [options_yearlevel, setYearLevelOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingSlot, setIsSubmittingSlot] = useState(false);
  const formRef = useRef(null);
  const [showOtherField, setShowOtherField] = useState(false);
    
  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);

  const navigate = useNavigate();

  const onGroupContainerClick = useCallback(() => {
    navigate("/list-lead");
  }, [navigate]);

  const basicSchema = yup.object().shape({
    st_first_name: yup
      .string()
      .notRequired()
      .min(2, "Minimum 2 characters is required.")
      .max(30, "Maximum 30 characters is required.")
      .matches(
        /^[A-Za-z]+(?: [A-Za-z]+)*$/,
        "Letters and spaces only at between."
      ),
    st_surname: yup
      .string()
      .notRequired()
      .min(1, "Minimum 1 characters is required.")
      .max(30, "Maximum 30 characters is required.")
      .matches(
        /^[A-Za-z]+(?: [A-Za-z]+)*$/,
        "Letters and spaces only at between."
      ),
    st_dob: yup
      .string()
      .nullable(true)
      .test("is-valid-date", "Please provide a valid date.", function (value) {
        // Check if the value is null or a valid date
        //console.log(value);
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          dayjs(value, "YYYY-MM-DD").isValid()
        );
      }),

    /* st_first_name: yup.string().required("First name  is required.."),
        st_surname: yup.string().required("Last Name  is required.."),
        st_gender: yup.mixed().required("Gender  is required.."),
        st_dob: yup.date().required("Date of birth  is required.."),
        st_year_level: yup.mixed().required("Year level  is required.."),
        fk_sc_id: yup.mixed().required("School  is required.."),
        st_email: yup.string().email("Please enter a valid email address").required("Email  is required.."),
       
        st_phone: yup.string().min(7, "Minimum 5 digit required").required("Phone  is required.."),*/
    //st_timezone: yup.mixed().required("Timezone  is required.."),
    fk_sc_id: yup
      .string()
      .min(2, "Minimum 2 characters required")
      .max(250, "Maximum 250 characters is required.")
      .notRequired()
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    st_email: yup
      .string()
      .email("Please enter a valid email address")
      .notRequired()
      .matches(
        /^[^$&+,:;=?@#|'<>.^*()%!-]+/,
        "Input cannot start with a special character"
      )
      .test("no-dot", "Please enter a valid email address", function (value) {
        return !value || (value && value.includes("."));
      }),
    st_countryCode: yup.string().notRequired(),
    st_phone: yup
          .string()
          .test(
            'valid-phone-number',
            function (value) {
              const { st_countryCode } = this.parent;
              const countryCodeMap = {
                '44': 'GB',   // UK
                '61': 'AU',   // Australia
                '64': 'NZ',   // New Zealand
                '65': 'SG',   // Singapore
                
              };
        
             
              const countryKey = countryCodeMap[st_countryCode];
        
              
              const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
        
            
              const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${st_countryCode}\\s?`, 'g'), '');
              if (!cleanPhoneNumber ) {
                return true; 
              }
              if (countrySpecificRule) {
                const { min, max, message } = countrySpecificRule;
                const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
                // Check if the phone number length is within the valid range for the country
                if (phoneLength < min || phoneLength > max) {
                  return this.createError({ message: message });
                }
              }
        
              return true;  // Validation passes if no errors
            }
          )
          .notRequired(),

    st_is_adultlearner: yup.bool(),
    //st_is_adultlearner: yup.boolean(),
    /*tbl_parent*/
    par_firstname: yup.string().when("st_is_adultlearner", {
      is: false,
      then: () =>
        yup
          .string()
          .required("First name  is required.")
          .min(2, "Minimum 2 characters is required.")
          .max(30, "Maximum 30 characters is required.")
          .matches(
            /^[A-Za-z]+(?: [A-Za-z]+)*$/,
            "Letters and spaces only at between."
          ),
    }),
    par_surname: yup.string().when("st_is_adultlearner", {
      is: false,
      then: () =>
        yup
          .string()
          .required("Lastname  is required.")
          .min(1, "Minimum 1 characters is required.")
          .max(30, "Maximum 30 characters is required.")
          .matches(
            /^[A-Za-z]+(?: [A-Za-z]+)*$/,
            "Letters and spaces only at between."
          ),
    }),

    par_emailid: yup.string().when('st_is_adultlearner', {
      is: false,
      then: () => yup.string().email("Please enter a valid email address").notRequired().matches(/^[^$&+,:;=?@#|'<>.^*()%!-]+/, 'Input cannot start with a special character').test('no-dot', 'Please enter a valid email address', function (value) {
          return !value || value && value.includes('.');
        }),
  }),
    par_countryCode: yup.string().notRequired(),
    par_phone: yup.string().when('st_is_adultlearner', {
      is: false,
      then: () => yup.string().test('email-or-phone', 'valid-phone-number', function (value) {
          const par_emailid = this.parent.par_emailid;
          
          if (!value && (!par_emailid || !yup.string().email().isValidSync(par_emailid))) {
            return this.createError({
              path: 'par_phone',
              message: 'Email or phone is required.',
            });
          }
          
          const { par_countryCode } = this.parent;
          const countryCodeMap = {
              '44': 'GB',   // UK
              '61': 'AU',   // Australia
              '64': 'NZ',   // New Zealand
              '65': 'SG',   // Singapore
              
            };
      
           
            const countryKey = countryCodeMap[par_countryCode];
      
            
            const countrySpecificRule = phoneNumberRules[countryKey] || phoneNumberRules['General'];
      
          
            const cleanPhoneNumber = value && value.replace(new RegExp(`^\\+?${par_countryCode}\\s?`, 'g'), '');
            if (!cleanPhoneNumber ) {
              return true; 
            }
            if (countrySpecificRule) {
              const { min, max, message } = countrySpecificRule;
              const phoneLength = cleanPhoneNumber ? cleanPhoneNumber.length : 0;
              // Check if the phone number length is within the valid range for the country
              if (phoneLength < min || phoneLength > max) {
                return this.createError({ message: message });
              }
            }
      
           
          return true;
        }),
    }),
    /*par_address: yup.mixed().required("Address  is required.."),
        par_city: yup.mixed().required("City  is required.."),
        par_state: yup.mixed().required("State  is required.."),
        par_country: yup.mixed().required("Country  is required.."),
        par_zip: yup.mixed().required("Postcode  is required.."),*/

    par_address: yup
      .string()
      .min(2, "Minimum 2 characters is required.")
      .max(250, "Maximum 250 characters is required.")
      .notRequired()
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    par_city: yup
      .string()
      .min(2, "Minimum 2 characters is required.")
      .max(250, "Maximum 250 characters is required.")
      .notRequired()
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      )
      .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
    par_state: yup
      .string()
      .min(2, "Minimum 2 characters is required.")
      .max(250, "Maximum 250 characters is required.")
      .notRequired()
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      )
      .matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/, "Avoid apostrophe & quotes."),
    //par_country: yup.string().min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").notRequired().matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    par_zip: yup
      .string()
      .min(4, "Minimum 4 characters is required.")
      .max(8, "Maximum 8 characters is required.")
      .notRequired()
      .matches(/^[a-zA-Z0-9\/-]+$/, "Allow only letters, numbers, - and /."),

    lead_pref_tim_contact: yup
      .string()
      .nullable(true)
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is null or a valid time
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          dayjs(value, "HH:mm:ss").isValid()
        );
      }),
    ls_date: yup
      .string()
      .nullable(true)
      .test("is-valid-date", "Please provide a valid date.", function (value) {
        // Check if the value is null or a valid date
        //console.log(value);
        return (
          value === undefined ||
          value === null ||
          value === "" ||
          dayjs(value, "YYYY-MM-DD").isValid()
        );
      }),

    /*tbl_lead*/
    /* lead_pref_meth_contact: yup.mixed().required("Method of contact  is required.."), //My point reset date-10
         lead_pref_tim_contact: yup.mixed().required("Time to contact  is required.."),
         lead_ref_source: yup.mixed().required("Referal source  is required.."),
         lead_ref_campaign: yup.mixed().required("Referal campaign  is required.."),
         ls_date: yup.date().required("Date  is required.."),
         ls_status: yup.mixed().required("Status  is required.."),
         ls_description: yup.string().required("description  is required.."),
        
 
         ps_relationship: yup.string().when('st_is_adultlearner', {
             is: false,
             then: () => yup.mixed().required("Relationship  is required.."),
           }),*/

    ls_description: yup
      .string()
      .min(2, "Minimum 2 characters is required.")
      .max(250, "Maximum 250 characters is required.")
      .notRequired()
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    ps_relationship: yup.string().when("st_is_adultlearner", {
      is: false,
      then: () =>
        yup
          .string()
          .notRequired()
          .matches(
            /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
            "Avoid start & end with a space or comma"
          )
          .matches(
            /^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,
            "Avoid apostrophe & quotes."
          ),
    }),
  });

  useEffect(() => {
    // Make an API request to fetch the options

    axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        country_id: sessionStorage.getItem("country_code"),
        mode: "getCountryYearLevel",
      })
      .then((response_year) => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedyearOptions = response_year.data.data.map((option) => ({
          value: option.activity_level_id,
          label: option.level_name,
        }));
        setYearLevelOptions(formattedyearOptions);
      })
      .catch((error) => {
        console.error("Error fetching options from API: ", error);
      });
  }, []);

  const [Id, setId] = useState(null);
  //Get key value start
  const location = useLocation();
  useEffect(() => {
    const { pk_lead_key } = location.state || {};
    const extractedLeadId = pk_lead_key;

    if (extractedLeadId !== "" && extractedLeadId !== undefined) {
      setId(extractedLeadId);
      fetchData(extractedLeadId);
    } else {
      navigate("/list-lead");
    }
  }, [location]);
  //Get key value end

  const handleClick = async () => {
    const Id_new = Id;
    if (Id_new) {
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          fk_lead_key: Id_new,
          security_key: security_key,
          mode: "checkstudentMoved",
        });

        if (response.data.success === true) {
          navigate(`/add-student`, { state: { pk_student_key: `${Id_new}` } });
        } else {
          if (response.data.data == 2) {
            toast.error(
              "Sorry, Student initial diagnostics already scheduled",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );
          } else {
            toast.error("Sorry, Student already scheduled", {
              autoClose: 3000, // 3 seconds in this example
            });
          }
        }
      } catch (error) {
        console.error("Error checking username:", error);

        toast.error("Failure! Unable to process. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
      }
    }
  };

  const handleClick_dignostic = async () => {
    const dig_id = Id;
    if (dig_id) {
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          pk_lead_key: dig_id,
          security_key: security_key,
          mode: "checkAssessmentSlotMoved",
        });

        if (response.data.success === true) {
          toggleModal1();
        } else {
          toast.error("Sorry, Student initial diagnostics already scheduled", {
            autoClose: 3000, // 3 seconds in this example
          });
        }
      } catch (error) {
        console.error("Error checking username:", error);

        toast.error("Failure! Unable to process. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });
      }
    }
  };
  // const handleClick = () => {
  //     // You can use the 'id' value here for further processing
  //     console.log('ID Value:', Id);

  //     try {
  //         const response = await axios.post(BASE_URL+"API/api_index.php", {
  //             userID: username,
  //             security_key: security_key,
  //             mode: "checkStudentUserId",
  //         });

  //         if (response.data.success === true) {
  //             formik.setFieldTouched("sa_login_id", true, false);
  //             formik.setFieldError("sa_login_id", "Username is not available");
  //         } else {
  //             formik.setFieldTouched("sa_login_id", true, false);
  //             formik.setFieldError("sa_login_id", null);
  //         }
  //     } catch (error) {
  //         console.error("Error checking username:", error);
  //         formik.setFieldError("sa_login_id", null);
  //     }

  //    // navigate(`/add-student?id=${Id}`);
  //   };

  const [StudentData, setStudentData] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  //Api call start

  const fetchData = async (id) => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getSingleLead_new",
        pk_lead_id: id,
      });

      if (response.data.success === true) {
        if (response.data.data.length > 0) {
          setStudentData(response.data.data[0]);

          if (
            response.data.data[0]["st_avatar"] != null &&
            response.data.data[0]["st_avatar"] != ""
          ) {
            const fullImageUrl = `${response.data.data[0]["st_avatar"]}`;

            setImageUrl(fullImageUrl);
          }
        }
      } else {
        // Handle unsuccessful response, e.g., show an alert to the user.
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //Api call End

  const initialValues = {
    pk_student_key: StudentData ? StudentData.pk_student_key : "",
    pk_lead_key: StudentData ? StudentData.pk_lead_key : "",
    pk_par_key: StudentData ? StudentData.pk_par_key : "",

    /*tbl_student*/
    st_first_name: StudentData ? StudentData.st_first_name : "",
    st_surname: StudentData ? StudentData.st_surname : "",
    st_gender: StudentData ? StudentData.st_gender : "",
    st_dob: StudentData ? StudentData.st_dob : "",
    st_year_level: StudentData ? StudentData.st_year_level : "",
    fk_sc_id: StudentData ? StudentData.fk_sc_id : "",
    st_email: StudentData ? StudentData.st_email : "",
    st_address: StudentData ? StudentData.st_address : "",
    st_city: StudentData ? StudentData.st_city : "",
    st_state: StudentData ? StudentData.st_state : "",
    st_country: StudentData ? StudentData.st_country : "",
    st_zip: StudentData ? StudentData.st_zip : "",
    st_phone: StudentData ? StudentData.st_phone : "",
    st_timezone: StudentData
      ? StudentData.st_timezone != null && StudentData.st_timezone != ""
        ? StudentData.st_timezone
        : sessionStorage.getItem("time_zone")
      : sessionStorage.getItem("time_zone"),
    st_is_adultlearner: StudentData
      ? StudentData.st_is_adultlearner == 1
        ? true
        : false
      : false,
    fk_cent_key: StudentData ? StudentData.fk_cent_key : "",
    st_avatar: StudentData ? StudentData.st_avatar : "",

    st_countryCode: "",
    par_countryCode: "",
    /*tbl_parent*/
    par_firstname: StudentData ? StudentData.par_firstname : "",
    par_surname: StudentData ? StudentData.par_surname : "",
    par_emailid: StudentData ? StudentData.par_emailid : "",
    par_phone: StudentData ? StudentData.par_phone : "",
    par_address: StudentData ? StudentData.par_address : "",
    par_city: StudentData ? StudentData.par_city : "",
    par_state: StudentData ? StudentData.par_state : "",
    par_country: StudentData
      ? StudentData.par_country != null && StudentData.par_country != ""
        ? StudentData.par_country
        : sessionStorage.getItem("country_code") != null &&
          sessionStorage.getItem("country_code") != ""
        ? sessionStorage.getItem("country_code")
        : "SG"
      : sessionStorage.getItem("country_code") != null &&
        sessionStorage.getItem("country_code") != ""
      ? sessionStorage.getItem("country_code")
      : "SG",
    par_zip: StudentData ? StudentData.par_zip : "",

    /*tbl_lead*/
    lead_pref_meth_contact: StudentData
      ? StudentData.lead_pref_meth_contact
      : "", //My point reset date-10
    lead_pref_tim_contact: StudentData ? StudentData.lead_pref_tim_contact : "",
    lead_ref_source: StudentData ? StudentData.lead_ref_source : "",
    lead_ref_campaign: StudentData ? StudentData.lead_ref_campaign : "",
    lead_ref_campaign_others: StudentData ? StudentData.lead_ref_campaign_others : "",
    ls_date: StudentData ? StudentData.ls_date : "",
    ls_status: StudentData ? StudentData.ls_status : "",
    ls_description: StudentData ? StudentData.ls_description : "",

    ps_relationship: StudentData ? StudentData.ps_relationship : "",

    updated_by: sessionStorage.getItem("key"),
  };

  useEffect(() => {
   
    if (StudentData.lead_ref_campaign === "Others") {
      setShowOtherField(true)
    } else {
        setShowOtherField(false)
    }
}, [StudentData]); 

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: basicSchema,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "updateLead_new",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // After 3 seconds, redirect to another page
            setTimeout(function () {
              navigate("/list-lead");
              setIsSubmitting(false); // Re-enable the submit button
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds
          } else if (response.data.data == 4) {
            toast.error("Year level is not available, Try another option", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update lead. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to update lead. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update lead. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button
      } finally {
        setIsSubmitting(false); // Re-enable the submit button
      }
    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;
  const fileInputRef = useRef(null);

  function handelRemove(event) {
    event.preventDefault();
    setImageUrl("");
  }

  //  const passwordRules = /^(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const validationSchema1 = yup.object().shape({
    sa_login_id: yup
      .string()
      .required("Username is required.")
      .min(2, "Minimum 2 characters is required.")
      .max(30, "Maximum 30 characters is required.")
      .matches(/^[^'"`\s’]+$/, "Avoid spaces, apostrophe & quotes."),
    sa_password: yup
      .string()
      .min(5, "Minimum 5 characters is required.")
      .required("Password  is required.")
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    sa_conform_password: yup
      .string()
      .oneOf([yup.ref("sa_password"), null], "Password is not matching")
      .required("Confirm password  is required.")
      .matches(
        /^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/,
        "Avoid start & end with a space or comma"
      ),
    /*shs_question: yup.mixed().required("Question  is required.."),
        shs_answer: yup.mixed().required("Answer  is required.."),*/

    // la_maths_year_level: yup.mixed().required("Maths year level  is required.."),
    //la_english_year_level: yup.mixed().required("English year level  is required.."),

    la_maths_year_level: yup
      .mixed()
      .test("at-least-one", "Year level is required.", function (value) {
        const { la_english_year_level } = this.parent;
        if (!value && !la_english_year_level) {
          return this.createError({
            path: "la_maths_year_level",
            message: "Year level is required.",
          });
        }
        return true;
      }),
    la_english_year_level: yup
      .mixed()
      .test("at-least-one", "Year level is required.", function (value) {
        const { la_maths_year_level } = this.parent;
        if (!value && !la_maths_year_level) {
          return this.createError({
            path: "la_english_year_level",
            message: "Year level is required.",
          });
        }
        return true;
      }),
    as_date: yup
      .string()
      .test("is-valid-date", "Please provide a valid date.", function (value) {
        const parsedDate = dayjs(value, "YYYY-MM-DD");
        return parsedDate.isValid();
      })
      .required("Date  is required."),

    as_start_time: yup
      .string() // Input value should be a string
      .notRequired() // Time of contact is required, and an error message is provided if not present
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return !value || dayjs(value, "HH:mm:ss").isValid();
      }),
    as_end_time: yup
      .string()
      .notRequired()
      .test("is-after", "End time must be after start time", function (value) {
        const { as_start_time } = this.parent;
        if (!as_start_time || !value) return true; // Skip validation if one of the fields is empty

        const startTimeParts = as_start_time
          .split(":")
          .map((part) => parseInt(part, 10));
        const endTimeParts = value.split(":").map((part) => parseInt(part, 10));

        const startTimeMinutes = startTimeParts[0] * 60 + startTimeParts[1];
        const endTimeMinutes = endTimeParts[0] * 60 + endTimeParts[1];

        return endTimeMinutes > startTimeMinutes;
      })
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return !value || dayjs(value, "HH:mm:ss").isValid();
      }),
  });

  const initialValues1 = {
    pk_student_key: StudentData ? StudentData.pk_student_key : "",
    pk_lead_key: StudentData ? StudentData.pk_lead_key : "",
    fk_cent_key: StudentData ? StudentData.fk_cent_key : "",
    /*tbl_student_auth*/
    sa_login_id: "",
    sa_password: "",
    sa_conform_password: "",
    shs_question: "",
    shs_answer: "",
    as_date: "",
    as_start_time: "",
    as_end_time: "",
    la_maths_year_level: "",
    la_english_year_level: "",

    created_by: sessionStorage.getItem("key"),
  };

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingSlot(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "createAssessmentSchedule",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            // After 3 seconds, redirect to another page
            setTimeout(function () {
              navigate("/Diagnostics");
              setIsSubmittingSlot(false); // Re-enable the submit button
            }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

            // toggleModal1();
          } else if (response.data.data === 2) {
            toast.error("Student username is not available", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else if (response.data.data == 4) {
            toast.error(
              "Maths year level is not available, Try another option",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else if (response.data.data == 5) {
            toast.error(
              "English year level is not available, Try another option",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create schedule. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingSlot(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to create schedule. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingSlot(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create schedule. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingSlot(false); // Re-enable the submit button
      } finally {
        setIsSubmittingSlot(false); // Re-enable the submit button
      }
    },
  });

  const handleUsernameBlur = useCallback(async () => {
    const username = formik1.values.sa_login_id;
    if (username) {
      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          userID: username,
          security_key: security_key,
          mode: "checkStudentUserId",
        });

        if (response.data.success === true) {
          formik1.setFieldTouched("sa_login_id", true, false);
          formik1.setFieldError("sa_login_id", "Username is not available");
        } else {
          formik1.setFieldTouched("sa_login_id", true, false);
          formik1.setFieldError("sa_login_id", null);
        }
      } catch (error) {
        console.error("Error checking username:", error);
        formik1.setFieldError("sa_login_id", null);
      }
    }
  }, [formik1.values.sa_login_id]);

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      const firstErrorField = Object.keys(formik.errors)[0];
      //const firstErrorFieldId = `${firstErrorField}Id`;

      const errorFieldElement = document.getElementById(firstErrorField);

      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else {
        // console.error(`Element with ID ${firstErrorField} not found in the DOM.`);
      }
      //document.getElementById(Object.keys(formik.errors)[0]).focus();
    }
  }, [formik]);

  return (
    <>
      {/* React alert */}
      <ToastContainer position="top-center" />
      {/* center form */}

      <Modal
        show={showModal1}
        onHide={toggleModal1}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Schedule Initial Assessment</Modal.Title>
        </Modal.Header>
        <Form onSubmit={formik1.handleSubmit}>
          <Modal.Body>
            {/* --------------Date--------------- */}

            <div className="section-heading border-bottom pb-3 mb-3">
              <h6 className="mb-0">Student App Login</h6>
            </div>
            <Row>
              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field ">Username</h6>
                <Form.Control
                  type="text"
                  name="sa_login_id"
                  autoComplete="off"
                  value={formik1.values.sa_login_id}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="sa_login_id"
                  //onBlur={handleUsernameBlur}
                  className={
                    formik1.errors.sa_login_id && formik1.touched.sa_login_id
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.sa_login_id && formik1.touched.sa_login_id && (
                  <p className="error">{formik1.errors.sa_login_id}</p>
                )}
              </Col>

              {/* --------------Student Password--------------- */}

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Password</h6>
                <Form.Control
                  type="password"
                  value={formik1.values.sa_password}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="sa_password"
                  autoComplete="new-password"
                  className={
                    formik1.errors.sa_password && formik1.touched.sa_password
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.sa_password && formik1.touched.sa_password && (
                  <p className="error">{formik1.errors.sa_password}</p>
                )}
              </Col>

              {/* --------------Student Confirm Password--------------- */}

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Confirm Password</h6>
                <Form.Control
                  type="password"
                  value={formik1.values.sa_conform_password}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="sa_conform_password"
                  className={
                    formik1.errors.sa_conform_password &&
                    formik1.touched.sa_conform_password
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.sa_conform_password &&
                  formik1.touched.sa_conform_password && (
                    <p className="error">
                      {formik1.errors.sa_conform_password}
                    </p>
                  )}
              </Col>

              {/* --------------Student Secret Question--------------- */}

              <Col
                lg="4"
                md="6"
                sm="12"
                className="position-relative"
                style={{ display: "none" }}
              >
                <h6 className="form-lable">Secret Question</h6>
                <Form.Control
                  type="text"
                  value={formik1.values.shs_question}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="shs_question"
                  className={
                    formik1.errors.shs_question && formik1.touched.shs_question
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.shs_question &&
                  formik1.touched.shs_question && (
                    <p className="error">{formik1.errors.shs_question}</p>
                  )}
              </Col>

              {/* --------------Student Secrect Answer--------------- */}

              <Col
                lg="4"
                md="6"
                sm="12"
                className="position-relative"
                style={{ display: "none" }}
              >
                <h6 className="form-lable">Secret Answer</h6>
                <Form.Control
                  type="text"
                  value={formik1.values.shs_answer}
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  id="shs_answer"
                  className={
                    formik1.errors.shs_answer && formik1.touched.shs_answer
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.shs_answer && formik1.touched.shs_answer && (
                  <p className="error">{formik1.errors.shs_answer}</p>
                )}
              </Col>
            </Row>

            <div className="section-heading border-bottom pb-3 mt-3 mb-3">
              <h6 className="mb-0">Schedule Initial Assessment</h6>
            </div>
            <Row>
              <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                <h6 className="form-lable required-field">Date</h6>
                {/* <Form.Control
                                    type="date"
                                    min={new Date().toISOString().split("T")[0]}

                                    value={formik1.values.as_date}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    id="as_date"
                                    className={formik1.errors.as_date && formik1.touched.as_date ? "input-error" : ""}
                                /> */}
                <LocalizationProvider
                  className={"form-control"}
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    views={["year", "month", "day"]}
                    value={dayjs(formik1.values.as_date, "YYYY-MM-DD")}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "as_date",
                          value:
                            date != null
                              ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                              : date,
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({ ...formik1.touched, as_date: true });
                    }}
                    name="as_date"
                    slotProps={{
                      textField: {
                        readOnly: true,
                        size: "small",
                        id: "as_date",
                      },
                    }}
                    className={
                      formik1.errors.as_date && formik1.touched.as_date
                        ? "input-error w-100"
                        : "w-100"
                    }
                    minDate={dayjs()}
                    format="DD-MM-YYYY"
                  />
                </LocalizationProvider>
                {formik1.errors.as_date && formik1.touched.as_date && (
                  <span className="error">{formik1.errors.as_date}</span>
                )}
              </Col>
              <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                <h6 className="form-lable">Start Time</h6>
                {/* <Form.Control
                                    type="time"

                                    value={formik1.values.as_start_time}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    id="as_start_time"
                                    className={formik1.errors.as_start_time && formik1.touched.as_start_time ? "input-error" : ""}
                                /> */}
                <LocalizationProvider
                  className={"form-control"}
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    value={dayjs(formik1.values.as_start_time, "HH:mm:ss")}
                    onChange={(time) => {
                      const event = {
                        target: {
                          name: "as_start_time",
                          value:
                            time != null
                              ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                              : time,
                        },
                      };

                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        as_start_time: true,
                      });
                    }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        size: "small",
                        id: "as_start_time",
                      },
                    }}
                    className={
                      formik1.errors.as_start_time &&
                      formik1.touched.as_start_time
                        ? "input-error w-100 h-auto"
                        : "w-100 h-auto"
                    }
                  />
                </LocalizationProvider>
                {formik1.errors.as_start_time &&
                  formik1.touched.as_start_time && (
                    <span className="error">
                      {formik1.errors.as_start_time}
                    </span>
                  )}
              </Col>
              <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                <h6 className="form-lable">End Time</h6>
                {/* <Form.Control
                                    type="time"

                                    value={formik1.values.as_end_time}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                    id="as_end_time"
                                    className={formik1.errors.as_end_time && formik1.touched.as_end_time ? "input-error" : ""}
                                /> */}
                <LocalizationProvider
                  className={"form-control"}
                  dateAdapter={AdapterDayjs}
                >
                  <TimePicker
                    value={dayjs(formik1.values.as_end_time, "HH:mm:ss")}
                    onChange={(time) => {
                      const event = {
                        target: {
                          name: "as_end_time",
                          value:
                            time != null
                              ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                              : time,
                        },
                      };

                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        as_end_time: true,
                      });
                    }}
                    slotProps={{
                      textField: {
                        readOnly: true,
                        size: "small",
                        id: "as_end_time",
                      },
                    }}
                    className={
                      formik1.errors.as_end_time && formik1.touched.as_end_time
                        ? "input-error w-100 h-auto"
                        : "w-100 h-auto"
                    }
                  />
                </LocalizationProvider>
                {formik1.errors.as_end_time && formik1.touched.as_end_time && (
                  <span className="error">{formik1.errors.as_end_time}</span>
                )}
              </Col>

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">
                  English Year Level
                </h6>
                <Select
                  options={options_yearlevel}
                  name="la_english_year_level"
                  value={formik1.values.la_english_year_level["value"]}
                  id="la_english_year_level"
                  onChange={(selectedOption) => {
                    const event = {
                      target: {
                        name: "la_english_year_level",
                        value: selectedOption["value"],
                      },
                    };
                    formik1.handleChange(event);
                  }}
                  onBlur={(event) => {
                    formik1.handleBlur(event);
                    formik1.setTouched({
                      ...formik1.touched,
                      la_english_year_level: true,
                    });
                  }}
                  className={
                    formik1.errors.la_english_year_level &&
                    formik1.touched.la_english_year_level
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.la_english_year_level &&
                  formik1.touched.la_english_year_level && (
                    <span className="error">
                      {formik1.errors.la_english_year_level}
                    </span>
                  )}
              </Col>

              <Col lg="4" md="6" sm="12" className="position-relative">
                <h6 className="form-lable required-field">Maths Year Level</h6>
                <Select
                  options={options_yearlevel}
                  name="la_maths_year_level"
                  value={formik1.values.la_maths_year_level["value"]}
                  id="la_maths_year_level"
                  onChange={(selectedOption) => {
                    const event = {
                      target: {
                        name: "la_maths_year_level",
                        value: selectedOption["value"],
                      },
                    };
                    formik1.handleChange(event);
                  }}
                  onBlur={(event) => {
                    formik1.handleBlur(event);
                    formik1.setTouched({
                      ...formik1.touched,
                      la_maths_year_level: true,
                    });
                  }}
                  className={
                    formik1.errors.la_maths_year_level &&
                    formik1.touched.la_maths_year_level
                      ? "input-error"
                      : ""
                  }
                />
                {formik1.errors.la_maths_year_level &&
                  formik1.touched.la_maths_year_level && (
                    <span className="error">
                      {formik1.errors.la_maths_year_level}
                    </span>
                  )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="mx-auto d-flex gap-2">
              <Button
                variant="contained"
                className="primary-gray"
                onClick={toggleModal1}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                className="primary-filled "
                disabled={isSubmittingSlot}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
        <div className="">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
              <h5 className="page-title"> Edit Lead </h5>
              <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                  <NavLink underline="hover" color="inherit" to="/calendar">
                    {" "}
                    Home
                  </NavLink>

                  <Typography color="text.primary">Lead</Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="d-flex gap-2">
              <div></div>
              {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

              <div className="d-flex justify-content-end gap-2">
                <div>
                  <DropdownButton
                    as={ButtonGroup}
                    title="Action"
                    id="dropdown-menu-align-responsive-1"
                    className="ps-0 rounded-10 primary-filled btn-action text-light"
                  >
                    <Dropdown.Item eventKey="1" onClick={handleClick_dignostic}>
                      {" "}
                      Schedule Initial Assessment
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="2" onClick={handleClick}>
                      {" "}
                      Convert to Student Account
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </div>
          </div>

          {/* --------------Student Particulars--------------- */}

          <Stack>
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Student Particulars </h6>
              </div>
              <Row>
                {/* --------------Student First Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">First Name</h6>
                  <Form.Control
                    type="text"
                    value={values.st_first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="st_first_name"
                    className={
                      errors.st_first_name && touched.st_first_name
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.st_first_name && touched.st_first_name && (
                    <p className="error">{errors.st_first_name}</p>
                  )}
                </Col>

                {/* --------------Student SurName--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Last name</h6>
                  <Form.Control
                    type="text"
                    value={values.st_surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="st_surname"
                    className={
                      errors.st_surname && touched.st_surname
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.st_surname && touched.st_surname && (
                    <p className="error">{errors.st_surname}</p>
                  )}
                </Col>

                {/* --------------Student Gender--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Gender</h6>
                  <Select
                    options={options_gender}
                    name="st_gender"
                    value={options_gender.find(
                      (option) =>
                        option.value &&
                        values.st_gender &&
                        option.value.toString() === values.st_gender.toString()
                    )}
                    id="st_gender"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "st_gender",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, st_gender: true });
                    }}
                    className={
                      errors.st_gender && touched.st_gender ? "input-error" : ""
                    }
                  />
                  {errors.st_gender && touched.st_gender && (
                    <p className="error">{errors.st_gender}</p>
                  )}
                </Col>

                {/* --------------Student Date of Birth--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Date of Birth</h6>
                  {/* <Form.Control
                                        type="date"
                                        max={new Date().toISOString().split("T")[0]}

                                        value={values.st_dob}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="st_dob"
                                        className={errors.st_dob && touched.st_dob ? "input-error" : ""}
                                    />
                                    {errors.st_dob && touched.st_dob && <p className="error">{errors.st_dob}</p>} */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(values.st_dob, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "st_dob",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, st_dob: true });
                      }}
                      name="st_dob"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "st_dob",
                        },
                      }}
                      className={
                        errors.st_dob && touched.st_dob
                          ? "input-error w-100"
                          : "w-100"
                      }
                      maxDate={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {errors.st_dob && touched.st_dob && (
                    <span className="error">{errors.st_dob}</span>
                  )}
                </Col>

                {/* --------------Student Year Level--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Year Level</h6>
                  <Select
                    options={options_yearlevel}
                    name="st_year_level"
                    value={options_yearlevel.find(
                      (option) =>
                        option.value &&
                        values.st_year_level &&
                        option.value.toString() ===
                          values.st_year_level.toString()
                    )}
                    id="st_year_level"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "st_year_level",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, st_year_level: true });
                    }}
                    className={
                      errors.st_year_level && touched.st_year_level
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.st_year_level && touched.st_year_level && (
                    <p className="error">{errors.st_year_level}</p>
                  )}
                </Col>

                {/* --------------Student School--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">School</h6>
                  <Form.Control
                    type="text"
                    value={values.fk_sc_id}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="fk_sc_id"
                    className={
                      errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""
                    }
                  />
                  {errors.fk_sc_id && touched.fk_sc_id && (
                    <p className="error">{errors.fk_sc_id}</p>
                  )}
                </Col>

                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">School</h6>
                                    <Select options={options_school}
                                     name="fk_sc_id"
                                     value={options_school.find(option => option.value && values.fk_sc_id && option.value.toString() == values.fk_sc_id.toString())}
                                     
                                     id="fk_sc_id"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "fk_sc_id",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, fk_sc_id: true });
                                     }}
                                     className={errors.fk_sc_id && touched.fk_sc_id ? "input-error" : ""} />
                                      {errors.fk_sc_id && touched.fk_sc_id && <p className="error">{errors.fk_sc_id}</p>}
                                </Col> */}

                {/* --------------Student Email--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Email</h6>
                  <Form.Control
                    type="text"
                    value={values.st_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="st_email"
                    className={
                      errors.st_email && touched.st_email ? "input-error" : ""
                    }
                  />
                  {errors.st_email && touched.st_email && (
                    <p className="error">{errors.st_email}</p>
                  )}
                </Col>

                {/* --------------Student city--------------- */}

                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">City/Town</h6>
                                    <Form.Control type="text" 
                                    value={values.st_city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_city"
                                    className={errors.st_city && touched.st_city ? "input-error" : ""}
                                     />
                                    {errors.st_city && touched.st_city && <p className="error">{errors.st_city}</p>}
                                </Col> */}

                {/* --------------Student state--------------- */}
                {/*                             
                                <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">County/Region/State</h6>
                                    <Form.Control type="text" 
                                     value={values.st_state}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_state"
                                     className={errors.st_state && touched.st_state ? "input-error" : ""} />
                                      {errors.st_state && touched.st_state && <p className="error">{errors.st_state}</p>}
                                </Col>
                             */}
                {/* --------------Student Country--------------- */}

                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Country</h6>
                                    <Select options={options_countries}
                                    name="st_country"
                                    value={options_countries.find(option => option.value && values.st_country && option.value.toString() === values.st_country.toString())}
                                    
                                    id="st_country"
                                    onChange={(selectedOption) => {
                                        const event = {
                                            target: {
                                                name: "st_country",
                                                value: selectedOption["value"],
                                            },
                                        };
                                        handleChange(event);
                                    }}
                                    onBlur={(event) => {
                                        handleBlur(event);
                                        setTouched({ ...touched, st_country: true });
                                    }}
                                    className={errors.st_country && touched.st_country ? "input-error" : ""}
                                     />
                                     {errors.st_country && touched.st_country && <p className="error">{errors.st_country}</p>}
                                </Col> */}

                {/* --------------Student Postal Code--------------- */}

                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Postal Code</h6>
                                    <Form.Control type="text" 
                                     value={values.st_zip}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_zip"
                                     className={errors.st_zip && touched.st_zip ? "input-error" : ""}
                                    />
                                    {errors.st_zip && touched.st_zip && <p className="error">{errors.st_zip}</p>}
                                </Col> */}

                {/* --------------Student Timezone--------------- */}

                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Timezone</h6>
                                    <Select options={options_time_zone}
                                     name="st_timezone"
                                     value={options_time_zone.find(option => option.value && values.st_timezone && option.value.toString() === values.st_timezone.toString())}
                                     
                                     id="st_timezone"
                                     onChange={(selectedOption) => {
                                         const event = {
                                             target: {
                                                 name: "st_timezone",
                                                 value: selectedOption["value"],
                                             },
                                         };
                                         handleChange(event);
                                     }}
                                     onBlur={(event) => {
                                         handleBlur(event);
                                         setTouched({ ...touched, st_timezone: true });
                                     }}
                                     className={errors.st_timezone && touched.st_timezone ? "input-error" : ""}
                                      />
                                       {errors.st_timezone && touched.st_timezone && <p className="error">{errors.st_timezone}</p>}
                                </Col> */}

                {/* --------------Student phone--------------- */}
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Contact Number</h6>
                  {/* <Form.Control type="number" 
                                     value={values.st_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="st_phone"
                                     className={errors.st_phone && touched.st_phone ? "input-error" : ""}
                                      />
                                       {errors.st_phone && touched.st_phone && <p className="error">{errors.st_phone}</p>} */}
                  <PhoneInput
                    name="st_phone"
                    countryCodeEditable={false}
                    onlyCountries={["sg", "nz", "gb", "au"]}
                    country={
                      sessionStorage.getItem("country_alph_code") != null &&
                      sessionStorage.getItem("country_alph_code") != ""
                        ? sessionStorage
                            .getItem("country_alph_code")
                            .toLowerCase()
                        : "sg"
                    } // Set the default country (e.g., singapore)
                    value={values.st_phone}
                    onChange={(phone, country) => {
                      const event = {
                        target: {
                          name: "st_phone",
                          value: phone,
                        },
                      };
                      const event1 = {
                        target: {
                          name: "st_countryCode",
                          value: country.dialCode,
                        },
                      };
                      handleChange(event1);
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, st_phone: true });
                    }}
                    inputProps={{ id: "st_phone" }}
                    id="st_phone"
                    inputClass={
                      errors.st_phone && touched.st_phone
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  {errors.st_phone && touched.st_phone && (
                    <span className="error">{errors.st_phone}</span>
                  )}
                </Col>

                {/* --------------Student phone--------------- */}
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Photo</h6>
                  {!imageUrl && (
                    <Form.Control
                      type="file"
                      name="st_avatar"
                      id="st_avatar"
                      accept=".png, .jpeg, .jpg, .jfif"
                      onChange={(event) => {
                        setTouched({ ...touched, st_avatar: true });
                        if (event.target.files.length > 0) {
                          const fileName = event.target.files[0];
                          async function handleFileUpload(fileName) {
                            try {
                              // Create a FormData object to send the form data, including the image file
                              const formData = new FormData();
                              formData.append("mode", "file_s3_upload_image");
                              formData.append("user", "student");
                              formData.append("image", fileName); // Append the image file

                              // Make an API request here with the FormData object
                              const response_file = await axios.post(
                                BASE_URL + "API/upload/index.php",
                                formData
                              );

                              // Handle the API response here
                              if (response_file.data.success === true) {
                                //console.log(response_file.data.data);
                                handleChange({
                                  target: {
                                    name: "st_avatar",
                                    value: response_file.data.data,
                                  },
                                });
                              } else {
                                fileInputRef.current.value = null;
                                handleChange({
                                  target: { name: "st_avatar", value: null },
                                }); // Clear the input value
                              }

                              // You can also perform further actions based on the API response
                              // For example, show a success message or redirect to another page.
                            } catch (error) {
                              // console.log('null');
                              fileInputRef.current.value = null;
                              handleChange({
                                target: { name: "st_avatar", value: null },
                              }); // Clear the input value
                            }
                          }

                          // Call the async function when needed, passing the fileName as an argument
                          handleFileUpload(fileName);
                        } else {
                          // handleChange(event);
                          //console.log('null')
                          fileInputRef.current.value = null;
                          handleChange({
                            target: { name: "st_avatar", value: null },
                          }); // Clear the input value
                        }
                      }}
                      ref={fileInputRef}
                      className={
                        errors.st_avatar && touched.st_avatar
                          ? "input-error"
                          : ""
                      }
                    />
                  )}
                  {imageUrl && (
                    <Col
                      lg="4"
                      md="6"
                      sm="12"
                      className="d-flex justify-content-between"
                    >
                      <img src={imageUrl} alt="image" width="50" height="50" />
                      <div className="text-end">
                        <Button
                          variant="contained"
                          className="primary-filled  btn btn-contained"
                          onClick={handelRemove}
                        >
                          X
                        </Button>
                      </div>
                    </Col>
                  )}
                  {errors.st_avatar && touched.st_avatar && (
                    <p className="error">{errors.st_avatar}</p>
                  )}
                </Col>

                {/* --------------Contact Address1--------------- */}
                {/* <Col lg="4" md="6" sm="12" className='position-relative'>
                                    <h6 className="form-lable ">Address</h6>
                                    <Form.Control as="textarea" 
                                    rows={3} 
                                    value={values.st_address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="st_address"
                                    className={errors.st_address && touched.st_address ? "input-error control_textarea" : "control_textarea"}
                                     />
                                      {errors.st_address && touched.st_address && <p className="error">{errors.st_address}</p>}
                                </Col> */}

                {/* --------------Contact Checkbox--------------- */}
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <Form.Check
                    type="checkbox"
                    label="Adult Learner"
                    id="st_is_adultlearner"
                    name="st_is_adultlearner"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.st_is_adultlearner}
                    className="mt-4"
                  />

                  {errors.st_is_adultlearner && touched.st_is_adultlearner && (
                    <p className="error">{errors.st_is_adultlearner}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Student Particulars End--------------- */}

          {/* --------------Contact Details--------------- */}

          <Stack
            className="mt-4"
            style={{
              display:
                formik.values.st_is_adultlearner != null &&
                formik.values.st_is_adultlearner === true
                  ? "none"
                  : "block",
            }}
          >
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Parents / Guardians Details </h6>
              </div>

              <Row>
                {/* --------------StudentParents / Guardians/guardian--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Relationship</h6>
                  <Form.Control
                    type="text"
                    value={values.ps_relationship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="ps_relationship"
                    className={
                      errors.ps_relationship && touched.ps_relationship
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.ps_relationship && touched.ps_relationship && (
                    <p className="error">{errors.ps_relationship}</p>
                  )}
                </Col>

                {/* --------------Contact First Name--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">First Name</h6>
                  <Form.Control
                    type="text"
                    value={values.par_firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_firstname"
                    className={
                      errors.par_firstname && touched.par_firstname
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.par_firstname && touched.par_firstname && (
                    <p className="error">{errors.par_firstname}</p>
                  )}
                </Col>

                {/* --------------Contact SurName--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Last Name</h6>
                  <Form.Control
                    type="text"
                    value={values.par_surname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_surname"
                    className={
                      errors.par_surname && touched.par_surname
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.par_surname && touched.par_surname && (
                    <p className="error">{errors.par_surname}</p>
                  )}
                </Col>

                {/* --------------Contact Email--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Email</h6>
                  <Form.Control
                    type="email"
                    value={values.par_emailid}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_emailid"
                    className={
                      errors.par_emailid && touched.par_emailid
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.par_emailid && touched.par_emailid && (
                    <p className="error">{errors.par_emailid}</p>
                  )}
                </Col>

                {/* --------------Student phone--------------- */}
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Contact Number</h6>
                  {/* <Form.Control type="number" 
                                     value={values.par_phone}
                                     onChange={handleChange}
                                     onBlur={handleBlur}
                                     id="par_phone"
                                     className={errors.par_phone && touched.par_phone ? "input-error" : ""} 
                                      />
                                    {errors.par_phone && touched.par_phone && <p className="error">{errors.par_phone}</p>} */}
                  <PhoneInput
                    name="par_phone"
                    countryCodeEditable={false}
                    onlyCountries={["sg", "nz", "gb", "au"]}
                    country={
                      sessionStorage.getItem("country_alph_code") != null &&
                      sessionStorage.getItem("country_alph_code") != ""
                        ? sessionStorage
                            .getItem("country_alph_code")
                            .toLowerCase()
                        : "sg"
                    } // Set the default country (e.g., singapore)
                    value={values.par_phone}
                    onChange={(phone, country) => {
                      const event = {
                        target: {
                          name: "par_phone",
                          value: phone,
                        },
                      };
                      const event1 = {
                        target: {
                          name: "par_countryCode",
                          value: country.dialCode,
                        },
                      };
                      handleChange(event1);
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, par_phone: true });
                    }}
                    inputProps={{ id: "par_phone" }}
                    id="par_phone"
                    inputClass={
                      errors.par_phone && touched.par_phone
                        ? "input-error form-control"
                        : "form-control"
                    }
                  />
                  {errors.par_phone && touched.par_phone && (
                    <p className="error">{errors.par_phone}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Contact Details End--------------- */}
          {/* --------------Contact Details End--------------- */}
          <Stack className="mt-4">
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Contact Details</h6>
              </div>

              <Row>
                {/* --------------Contact Address1--------------- */}
                <Col lg="12" md="12" sm="12" className="position-relative">
                  <h6 className="form-lable ">Address</h6>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.par_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_address"
                    className={
                      errors.par_address && touched.par_address
                        ? "input-error control_textarea"
                        : "control_textarea"
                    }
                  />
                  {errors.par_address && touched.par_address && (
                    <p className="error">{errors.par_address}</p>
                  )}
                </Col>
                {/* --------------Student city--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mt-3">
                  <h6 className="form-lable ">City/Town</h6>
                  <Form.Control
                    type="text"
                    value={values.par_city}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_city"
                    className={
                      errors.par_city && touched.par_city ? "input-error" : ""
                    }
                  />
                  {errors.par_city && touched.par_city && (
                    <p className="error">{errors.par_city}</p>
                  )}
                </Col>

                {/* --------------Student state--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mt-3">
                  <h6 className="form-lable ">County/Region/State</h6>
                  <Form.Control
                    type="text"
                    value={values.par_state}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_state"
                    className={
                      errors.par_state && touched.par_state ? "input-error" : ""
                    }
                  />
                  {errors.par_state && touched.par_state && (
                    <p className="error">{errors.par_state}</p>
                  )}
                </Col>

                {/* --------------Student Country--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mt-3">
                  <h6 className="form-lable ">Country</h6>
                  <Select
                    options={options_countries}
                    name="par_country"
                    value={options_countries.find(
                      (option) =>
                        option.value &&
                        values.par_country &&
                        option.value.toString() ===
                          values.par_country.toString()
                    )}
                    id="par_country"
                    isDisabled={true}
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "par_country",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, par_country: true });
                    }}
                    className={
                      errors.par_country && touched.par_country
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.par_country && touched.par_country && (
                    <p className="error">{errors.par_country}</p>
                  )}
                </Col>

                {/* --------------Student Postal Code--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable ">Postal Code</h6>
                  <Form.Control
                    type="text"
                    value={values.par_zip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="par_zip"
                    className={
                      errors.par_zip && touched.par_zip ? "input-error" : ""
                    }
                  />
                  {errors.par_zip && touched.par_zip && (
                    <p className="error">{errors.par_zip}</p>
                  )}
                </Col>
                {/* -------------- Details Status --------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">How did you hear about us?</h6>
                  <Select
                    options={options_campaign}
                    name="lead_ref_campaign"
                    value={options_campaign.find(
                      (option) =>
                        option.value &&
                        values.lead_ref_campaign &&
                        option.value.toString() ===
                          values.lead_ref_campaign.toString()
                    )}
                    id="lead_ref_campaign"
                    onChange={(selectedOption) => {
                      const value = selectedOption["value"];

                      if (value === "Others") {
                        setShowOtherField(true);

                        const event = {
                          target: {
                            name: "lead_ref_campaign",
                            value: selectedOption["value"],
                          },
                        };

                        handleChange(event);
                      } else {
                        setShowOtherField(false);
                        const event = {
                          target: {
                            name: "lead_ref_campaign",
                            value: selectedOption["value"],
                          },
                        };
                        handleChange(event);
                        const resetEvent = {
                          target: {
                            name: "lead_ref_campaign_others",
                            value: null,
                          },
                        };

                        handleChange(resetEvent);
                      }
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, lead_ref_campaign: true });
                    }}
                    className={
                      errors.lead_ref_campaign && touched.lead_ref_campaign
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.lead_ref_campaign && touched.lead_ref_campaign && (
                    <p className="error">{errors.lead_ref_campaign}</p>
                  )}
                </Col>
                {showOtherField && (
                  <Col lg="4" md="6" sm="12" className="position-relative">
                    <h6 className="form-lable">Please specify</h6>
                    <Form.Control
                      type="text"
                      id="lead_ref_campaign_others"
                      name="lead_ref_campaign_others"
                      value={values.lead_ref_campaign_others}
                      onChange={handleChange}
                      onBlur={() =>
                        setTouched({
                          ...touched,
                          lead_ref_campaign_others: true,
                        })
                      }
                      className={
                        errors.lead_ref_campaign_others &&
                        touched.lead_ref_campaign_others
                          ? "input-error"
                          : ""
                      }
                    />
                    {errors.lead_ref_campaign_others &&
                      touched.lead_ref_campaign_others && (
                        <p className="error">
                          {errors.lead_ref_campaign_others}
                        </p>
                      )}
                  </Col>
                )}

                <Col
                  lg="4"
                  md="6"
                  sm="12"
                  className="position-relative"
                  style={{ display: "none" }}
                >
                  <h6 className="form-lable">Timezone</h6>
                  <Select
                    options={options_time_zone}
                    name="st_timezone"
                    value={options_time_zone.find(
                      (option) =>
                        option.value &&
                        values.st_timezone &&
                        option.value.toString() ===
                          values.st_timezone.toString()
                    )}
                    isDisabled={true}
                    id="st_timezone"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "st_timezone",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, st_timezone: true });
                    }}
                    className={
                      errors.st_timezone && touched.st_timezone
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.st_timezone && touched.st_timezone && (
                    <p className="error">{errors.st_timezone}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          {/* --------------Contact Details--------------- */}

          {/* --------------Lead Details--------------- */}

          <Stack className="mt-4">
            <div className="my-card p-4 bg-white">
              <div className="section-heading border-bottom pb-3 mb-3">
                <h6 className="mb-0">Lead Details </h6>
              </div>

              <Row>
                {/* --------------Details Preferred  Method Of Contact--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable"> Preferred Method Of Contact</h6>
                  <Select
                    options={options_preferedmethodofcontact}
                    name="lead_pref_meth_contact"
                    value={options_preferedmethodofcontact.find(
                      (option) =>
                        option.value &&
                        values.lead_pref_meth_contact &&
                        option.value.toString() ===
                          values.lead_pref_meth_contact.toString()
                    )}
                    id="lead_pref_meth_contact"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "lead_pref_meth_contact",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, lead_pref_meth_contact: true });
                    }}
                    className={
                      errors.lead_pref_meth_contact &&
                      touched.lead_pref_meth_contact
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.lead_pref_meth_contact &&
                    touched.lead_pref_meth_contact && (
                      <p className="error">{errors.lead_pref_meth_contact}</p>
                    )}
                </Col>

                {/* --------------Details Preferred  Time Of Contact--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable"> Preferred Time To Contact</h6>
                  {/* <Form.Control type="time"

                                        value={values.lead_pref_tim_contact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="lead_pref_tim_contact"
                                        className={errors.lead_pref_tim_contact && touched.lead_pref_tim_contact ? "input-error" : ""}
                                    />
                                    {errors.lead_pref_tim_contact && touched.lead_pref_tim_contact && <p className="error">{errors.lead_pref_tim_contact}</p>} */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker
                      value={dayjs(values.lead_pref_tim_contact, "HH:mm:ss")}
                      onChange={(time) => {
                        const event = {
                          target: {
                            name: "lead_pref_tim_contact",
                            value:
                              time != null
                                ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                                : time,
                          },
                        };

                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, lead_pref_tim_contact: true });
                      }}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "lead_pref_tim_contact",
                        },
                      }}
                      className={
                        errors.lead_pref_tim_contact &&
                        touched.lead_pref_tim_contact
                          ? "input-error w-100 h-auto"
                          : "w-100 h-auto"
                      }
                    />
                  </LocalizationProvider>

                  {errors.lead_pref_tim_contact &&
                    touched.lead_pref_tim_contact && (
                      <span className="error">
                        {errors.lead_pref_tim_contact}
                      </span>
                    )}
                </Col>

                {/* --------------Details Referral Source--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable">Referral Source</h6>
                  <Select
                    options={options_refferalsource}
                    name="lead_ref_source"
                    value={options_refferalsource.find(
                      (option) =>
                        option.value &&
                        values.lead_ref_source &&
                        option.value.toString() ===
                          values.lead_ref_source.toString()
                    )}
                    id="lead_ref_source"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "lead_ref_source",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, lead_ref_source: true });
                    }}
                    className={
                      errors.lead_ref_source && touched.lead_ref_source
                        ? "input-error"
                        : ""
                    }
                  />
                  {errors.lead_ref_source && touched.lead_ref_source && (
                    <p className="error">{errors.lead_ref_source}</p>
                  )}
                </Col>

                {/* -------------- Details Status --------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Status Date</h6>
                  {/* <Form.Control
                                        type="date"
                                        max={new Date().toISOString().split("T")[0]}
                                        value={values.ls_date}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="ls_date"
                                        className={errors.ls_date && touched.ls_date ? "input-error" : ""}
                                    />
                                    {errors.ls_date && touched.ls_date && <p className="error">{errors.ls_date}</p>} */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(values.ls_date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "ls_date",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };
                        handleChange(event);
                      }}
                      onBlur={(event) => {
                        handleBlur(event);
                        setTouched({ ...touched, ls_date: true });
                      }}
                      name="ls_date"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "ls_date",
                        },
                      }}
                      className={
                        errors.ls_date && touched.ls_date
                          ? "input-error w-100"
                          : "w-100"
                      }
                      minDate={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {errors.ls_date && touched.ls_date && (
                    <span className="error">{errors.ls_date}</span>
                  )}
                </Col>

                {/* --------------Details Preferred  Method Of Contact--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Status</h6>
                  <Select
                    options={options_status}
                    name="ls_status"
                    value={options_status.find(
                      (option) =>
                        option.value &&
                        values.ls_status &&
                        option.value.toString() === values.ls_status.toString()
                    )}
                    id="ls_status"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "ls_status",
                          value: selectedOption["value"],
                        },
                      };
                      handleChange(event);
                    }}
                    onBlur={(event) => {
                      handleBlur(event);
                      setTouched({ ...touched, ls_status: true });
                    }}
                    className={
                      errors.ls_status && touched.ls_status ? "input-error" : ""
                    }
                  />
                  {errors.ls_status && touched.ls_status && (
                    <p className="error">{errors.ls_status}</p>
                  )}
                </Col>
                {/* --------------description--------------- */}

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable">Status Description</h6>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={values.ls_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="ls_description"
                    className={
                      errors.ls_description && touched.ls_description
                        ? "input-error control_textarea"
                        : "control_textarea"
                    }
                  />
                  {errors.ls_description && touched.ls_description && (
                    <p className="error">{errors.ls_description}</p>
                  )}
                </Col>
              </Row>
            </div>
          </Stack>

          <Stack gap={2} className="mt-4">
            <div className="text-end">
              <Button
                variant="contained"
                className="primary-gray me-2"
                onClick={onGroupContainerClick}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                className="primary-filled "
                disabled={isSubmitting}
              >
                Update
              </Button>
            </div>
          </Stack>
        </div>
      </Form>
    </>
  );
}

export default ViewLead;
