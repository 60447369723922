/*
Purpose: list details of student with use datatable
Designed by R.Sanjai
Date:24/08/2023 
Project: TCMS


UpdateBy:KTH
updateDate:30/08/2023 
Purpose: include API
*/

import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Card, Modal } from "react-bootstrap";

import axios from "axios";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";
import Moment from "moment";

import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { options_attendance_status, security_key, BASE_URL } from "../global";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

function ListStudentAttendance() {
  const [loading, setLoading] = useState(false);
  //const [Id, setId] = useState(null);
  // const [data1, setItems] = useState([]);
  //const [tutorData, setTutorData] = useState([]);
  const [records, setRecords] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [editStatus, setEditStatusData] = useState([]);
  const [isSubmittingEdit, setIsSubmittingEdit] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const handleEditClick = (row) => {
    const updatedEditData = { ...row };
    setEditStatusData(updatedEditData);
    setModalShowEdit(true);
  };

  useEffect(() => {
    fetchData(startDate, endDate);
  }, []);

  const fetchData = async (startDate, endDate) => {
    setLoading(true);

    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getStudentAttendance",
        fk_cent_key: sessionStorage.getItem("key"),
        startDate: startDate,
        endDate: endDate,
      });

      if (response.data.success === true) {
        if (response.data.data.length > 0) {
          setRecords(response.data.data);
          setLoading(false);
        }else{
          setLoading(false);
        }
      } else {
        setLoading(false);
        // Handle unsuccessful response, e.g., show an alert to the user.
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  //Api call End

  // Sample columns configuration
  const columns = [
    {
      headerName: "S.No",
      field: "id",
      headerClassName: "super-app-theme--header",
      flex: 1,
      // renderCell: (params) => params.node && params.node.rowNode
      // ? parseInt(params.node.rowNode.id.replace('row-', ''), 10) + 1
      // : ''

      // valueGetter: (params) => {
      //   return `${params.row.tut_fname == null || params.row.tut_fname == '' ? '-' : params.row.tut_fname+' '+params.row.tut_surname}`;
      // },
    },
    {
      headerName: "Student",
      field: "Student",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_first_name == null || params.row.st_first_name == ""
            ? "-"
            : params.row.st_first_name + " " + params.row.st_surname
        }`;
      },
    },
    {
      headerName: "Date",
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.ses_date == null || params.row.ses_date == ""
            ? "-"
            : Moment(params.row.ses_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Session Name",
      field: "sest_name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.sest_name == null || params.row.sest_name == ""
            ? "-"
            : params.row.sest_name
        }`;
      },
    },
    {
      headerName: "Start Time",
      field: "Start Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.ses_start_time == null || params.row.ses_start_time == ""
            ? "-"
            : Moment(params.row.ses_start_time, "HH:mm:ss").format("HH:mm")
        }`;
      },
    },
    {
      headerName: "End Time",
      field: "End Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.ses_end_time == null || params.row.ses_end_time == ""
            ? "-"
            : Moment(params.row.ses_end_time, "HH:mm:ss").format("HH:mm")
        }`;
      },
    },
    {
      headerName: "Tutor Name",
      field: "Tutor Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.tut_fname == null || params.row.tut_fname == ""
            ? "-"
            : params.row.tut_fname + " " + params.row.tut_surname
        }`;
      },
    },
    {
      headerName: "Status",
      field: "Status",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.attendance_status != null &&
          params.row.attendance_status == ""
            ? "-"
            : parseInt(params.row.attendance_status) === 0
            ? "Pending"
            : parseInt(params.row.attendance_status) === 1
            ? "Ongoing"
            : parseInt(params.row.attendance_status) === 2
            ? "Attended"
            : parseInt(params.row.attendance_status) === 3
            ? "Missed"
            : parseInt(params.row.attendance_status) === 4
            ? "Offline"
            : parseInt(params.row.attendance_status) === 5
            ? "Defer requested"
            : parseInt(params.row.attendance_status) === 6
            ? "Cancelled"
            : "-"
        }`;
      },

      renderCell: (params) => {
        const row = params.row;
        return params.row.attendance_status == null &&
          params.row.attendance_status == "" ? (
          <p>-</p>
        ) : parseInt(params.row.attendance_status) === 0 ? (
          <p className="text-warning">Pending</p>
        ) : parseInt(params.row.attendance_status) === 1 ? (
          <p className="text-info">Ongoing</p>
        ) : parseInt(params.row.attendance_status) === 2 ? (
          <p className="text-success">Attended</p>
        ) : parseInt(params.row.attendance_status) === 3 ? (
          <p className="text-danger">Missed</p>
        ) : parseInt(params.row.attendance_status) === 4 ? (
          <p className="text-danger">Offline</p>
        ) : parseInt(params.row.attendance_status) === 5 ? (
          <p className="text-warning">Defer requested</p>
        ) : parseInt(params.row.attendance_status) === 6 ? (
          <p className="text-danger">Cancelled</p>
        ) : (
          <p>-</p>
        );
      },
    },
    {
      field: "Action",
      type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 200,
      //flex: 1,
      headerAlign: "left",
      renderCell: (params) => {
        const row = params.row;
        if (parseInt(row.attendance_status) == 6) {
          return null;
        } else {
          return (
            <Button
              variant="outlined-red"
              className="primary-outlined"
              onClick={() => handleEditClick(row)}
            >
              Edit Status
            </Button>
          );
        }
      },
    },
  ];

  // Sample data source

  const handleStartDateChange = (event) => {
    // const newStartDate = event.target.value;
    const newStartDate = dayjs(event, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (
      endDate &&
      new Date(endDate).getTime() < new Date(newStartDate).getTime()
    ) {
      // You can handle this validation error, for example, by not updating the state
      // console.error('End date cannot be earlier than start date');
      setStartDate("");
      return;
    }
    setStartDate(newStartDate);

    fetchData(newStartDate, endDate);
  };

  const handleEndDateChange = (event) => {
    //const newEndDate = event.target.value;
    const newEndDate = dayjs(event, "YYYY-MM-DD").format("YYYY-MM-DD");
    // Check if the new end date is not earlier than the start date
    if (
      startDate &&
      new Date(newEndDate).getTime() < new Date(startDate).getTime()
    ) {
      // You can handle this validation error, for example, by not updating the state
      // console.error('End date cannot be earlier than start date');
      setEndDate("");
      return;
    }
    setEndDate(newEndDate);

    fetchData(startDate, newEndDate);
  };

  const handleDateReset = () => {
    setStartDate("");
    setEndDate("");
    //setRecords([]);
    fetchData("", "");
  };

  const validationSchema1 = yup.object().shape({
    status: yup.mixed().required("Status is required."),
  });

  const initialValues1 = {
    fk_cent_key: sessionStorage.getItem("key"),
    attendance_key: editStatus
      ? editStatus.attendance_key != null && editStatus.attendance_key != ""
        ? editStatus.attendance_key
        : ""
      : "",
    status: editStatus
      ? editStatus.attendance_status != undefined
        ? editStatus.attendance_status
        : ""
      : "",

    updated_by: sessionStorage.getItem("key"),
  };

  const formRef = useRef(null);
  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingEdit(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "EditAttendanceStatus",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Attendance status has been changed.", {
              autoClose: 3000, // 3 seconds in this example
            });

            fetchData(startDate, endDate);
            //resetForm();
            setModalShowEdit(false);

            setIsSubmittingEdit(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update status. Try again later.", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingEdit(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to update status. Try again later.", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingEdit(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingEdit(false); // Re-enable the submit button
      } finally {
        setIsSubmittingEdit(false); // Re-enable the submit button
      }
    },
  });

  return (
    <>
      <ToastContainer position="top-center" />
      <div className="page-wrapper">
        <Modal
          show={modalShowEdit}
          onHide={() => setModalShowEdit(false)}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form
            autoComplete="off"
            onSubmit={formik1.handleSubmit}
            ref={formRef}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className="page-title">Edit Status </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {/* --------------Date--------------- */}
              <Row>
                <div className="cus-pyt-dtls">
                  <h6 className="mb-0">Student Name :</h6>
                  <p className="mb-0">
                    {editStatus
                      ? editStatus.st_first_name != ""
                        ? editStatus.st_first_name + " " + editStatus.st_surname
                        : ""
                      : ""}
                  </p>
                </div>
                <div className="cus-pyt-dtls">
                  <h6 className="mb-0">Session :</h6>
                  <p className="mb-0">
                    {editStatus
                      ? editStatus.sest_name != ""
                        ? editStatus.sest_name
                        : ""
                      : ""}
                  </p>
                </div>
                <Col lg="12" md="12" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">Status</h6>
                  <Select
                    menuPlacement="auto"
                    options={options_attendance_status}
                    name="status"
                    //value={options_yearlevel.find(option => option.value && formik1.values.la_maths_year_level && option.value.toString() === formik1.values.la_maths_year_level.toString())}
                    value={options_attendance_status.find(
                      (option) =>
                        option.value &&
                        formik1.values.status != undefined &&
                        option.value.toString() ==
                          formik1.values.status.toString()
                    )}
                    // value={formik1.values.la_maths_year_level}
                    id="status"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "status",
                          value: selectedOption["value"],
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({ ...formik1.touched, status: true });
                    }}
                    className={
                      formik1.errors.status && formik1.touched.status
                        ? "input-error"
                        : ""
                    }
                  />
                  {formik1.errors.status && formik1.touched.status && (
                    <span className="error">{formik1.errors.status}</span>
                  )}
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <div className="mx-auto d-flex gap-2">
                <Button
                  variant="contained"
                  className="primary-gray"
                  onClick={() => setModalShowEdit(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="primary-filled "
                  disabled={isSubmittingEdit}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
        {/* --------------Page Start--------------- */}

        {/* --------------Section Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="page-title"> Student Attendance Sheet</h5>

            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                <NavLink underline="hover" color="inherit" to="/calendar">
                  {" "}
                  Home
                </NavLink>

                <Typography color="text.primary">
                  Student Attendance Sheet
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center gap-2">
              {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

              {/* <Button variant="contained"  className="primary-filled" onClick={handleViewPaymentClick} >View Payments</Button> */}
              {/* <NavLink className="primary-filled rounded-10 p-2"  onClick={handleViewPaymentClick}>
              <AddIcon className="btn-icon" />View Payments</NavLink> */}
            </div>
          </div>
        </div>
        <Card className="my-card p-1 bg-white">
          <Card.Body>
            <Stack gap={3}>
              <div className="mb-3">
                <div className="cus-les-sec">
                  <div className="cus-les-left">
                    <div className="cus-les-sd">
                      <Form>
                        {/* --------------Start Date--------------- */}
                        <Form.Group
                          className="form-group d-flex align-items-center"
                          controlId="formsdate"
                        >
                          <Form.Label className="pe-2 mb-0">
                            <p className="mb-0">Start Date</p>
                          </Form.Label>
                          <Col className="">
                            {/* <input type="date" max={endDate} className="form-control mb-0" onChange={handleStartDateChange} value={startDate} /> */}
                            <LocalizationProvider
                              className={"form-control mb-0"}
                              dateAdapter={AdapterDayjs}
                            >
                              <DatePicker
                                views={["year", "month", "day"]}
                                value={dayjs(startDate, "YYYY-MM-DD")}
                                onChange={handleStartDateChange}
                                maxDate={dayjs(endDate, "YYYY-MM-DD")}
                                format="DD-MM-YYYY"
                              />
                            </LocalizationProvider>
                          </Col>
                        </Form.Group>
                      </Form>
                    </div>

                    <div className="cus-les-ed">
                      <Form>
                        {/* --------------End Date--------------- */}
                        <Form.Group
                          className="form-group d-flex align-items-center"
                          controlId="formedate"
                        >
                          <Form.Label className="pe-2 mb-0">
                            <p className="mb-0">End Date</p>
                          </Form.Label>
                          <Col className="">
                            {/* <input type="date" min={startDate} className="form-control mb-0" onChange={handleEndDateChange} value={endDate} /> */}
                            <LocalizationProvider
                              className={"form-control mb-0"}
                              dateAdapter={AdapterDayjs}
                            >
                              <DatePicker
                                views={["year", "month", "day"]}
                                value={dayjs(endDate, "YYYY-MM-DD")}
                                onChange={handleEndDateChange}
                                minDate={dayjs(startDate, "YYYY-MM-DD")}
                                format="DD-MM-YYYY"
                              />
                            </LocalizationProvider>
                          </Col>
                        </Form.Group>
                      </Form>
                    </div>
                    <div className="cus-les-rst">
                      <Button
                        variant="secondary"
                        className="btn-sec-outline"
                        onClick={handleDateReset}
                      >
                        Reset
                      </Button>
                    </div>
                  </div>
                  <div className="cus-les-right">
                    <div className="cus-les-rst">
                      {/* <span className='ml-5 mt-4'><h5>{tutorData ? tutorData.tut_fname != null && tutorData.tut_fname != '' ? tutorData.tut_fname + ' ' + tutorData.tut_surname  : '' : ''}</h5></span> */}
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
            {/* -------------Content Start--------------- */}

            <Row>
              <Col xs="12">
                <div className="">
                  <Row className="justify-content-center">
                    {/* --------------Table Content Start--------------- */}
                    <Col sm={12}>
                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            ".MuiDataGrid-cell:focus": {
                              outline: "none",
                            },
                            // pointer cursor on ALL rows
                            "& .MuiDataGrid-row:hover": {
                              cursor: "pointer",
                            },
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "AttendanceList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                disableToolbarButton: true,
                                hideFooter: true,
                                hideToolbar: true,
                                fileName: "AttendanceList",
                              },
                              records,
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          showQuickFilter={true}
                          pageSizeOptions={[10, 25, 50, 100]}
                          loading={loading}
                          rows={records.map((row, index) => ({
                            ...row,
                            id: `${index + 1}`,
                          }))}
                          columns={columns}
                          rowHeight={60}
                          density="compact"
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}
                          //onRowClick={(params) => handleRowClick(params.row)}
                        />
                      </Box>
                    </Col>
                    {/* --------------Table Content Start--------------- */}
                  </Row>
                </div>
              </Col>
            </Row>

            {/* --------------Content End--------------- */}
          </Card.Body>
        </Card>

        {/* --------------Section End--------------- */}

        {/* --------------Page End--------------- */}
      </div>
    </>
  );
}

export default ListStudentAttendance;
