/*
Purpose: list details of Holidays with use datatable
Developer: BY R.SD
Date:25/08/2023 
Project: TCMS
*/


import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";

import { useNavigate, useLocation, NavLink } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Stack, Form } from 'react-bootstrap';


import React from 'react';
import Moment from 'moment';
import axios from 'axios';
import * as yup from "yup";

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';


import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';

import { security_key, options_weeklydays, BASE_URL } from "../global";




const basicSchema = yup.object().shape({

    op_dayofweek: yup.mixed().required("Weekly days is required."),
    formMoncheckbox: yup.bool(),

    formMonStart: yup
        .string()
        .when('formMoncheckbox', {
        is: false,
        then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
            return dayjs(value, 'HH:mm:ss').isValid();
        }),
        otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                       
                        return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                    }), // No validation when formMoncheckbox is false
        }),
    formMonEnd: yup
        .string()
        .when('formMoncheckbox', {
        is: false,
        then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formMonStart = Moment(this.parent.formMonStart, 'HH:mm');
            const formMonEnd = Moment(value, 'HH:mm');
            return formMonEnd.isAfter(formMonStart);
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
                // Check if the value is a valid time
            return dayjs(value, 'HH:mm:ss').isValid();
            }),
        otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formMonStart = Moment(this.parent.formMonStart, 'HH:mm');
            const formMonEnd = Moment(value, 'HH:mm');
            return  value != null ? formMonEnd.isAfter(formMonStart): true;
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is a valid time
          
            return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
            }), // No validation when formMoncheckbox is false or formMonStart is not provided
        }),
   
    // formMonStart: yup
    // .string() // Input value should be a string
    // .required("Start time is required.") // Time of contact is required, and an error message is provided if not present
    // .test('is-valid-time', 'Please provide a valid time.', function (value) {
    //   // Check if the value is a valid time
    //   return dayjs(value, 'HH:mm:ss').isValid();
    // }),
    // formMonEnd: yup.string()
    // .required('End Time  is required.')
    // .test('is-valid-time', 'End Time must be after Start Time.', function (value) {
    //   const formMonStart = Moment(this.parent.formMonStart, 'HH:mm');
    //   const formMonEnd = Moment(value, 'HH:mm');
    //   return formMonEnd.isAfter(formMonStart);
    // }).test('is-valid-time', 'Please provide a valid time.', function (value) {
    //     // Check if the value is a valid time
    //     return dayjs(value, 'HH:mm:ss').isValid();
    //   }),
    // formMoncheckbox: yup.boolean().oneOf([true], "Please check the Close checkbox").required("Please check the Close checkbox"),


    formTuecheckbox: yup.bool(),

    formTueStart: yup
    .string()
    .when('formTuecheckbox', {
    is: false,
    then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
        return dayjs(value, 'HH:mm:ss').isValid();
    }),
    otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                    return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                }), // No validation when formMoncheckbox is false
    }),

    formTueEnd: yup
        .string()
        .when('formTuecheckbox', {
        is: false,
        then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formTueStart = Moment(this.parent.formTueStart, 'HH:mm');
            const formTueEnd = Moment(value, 'HH:mm');
            return formTueEnd.isAfter(formTueStart);
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
                // Check if the value is a valid time
            return dayjs(value, 'HH:mm:ss').isValid();
            }),
        otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formTueStart = Moment(this.parent.formTueStart, 'HH:mm');
            const formTueEnd = Moment(value, 'HH:mm');
            return  value != null ? formTueEnd.isAfter(formTueStart): true;
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
            }), // No validation when formMoncheckbox is false or formMonStart is not provided
        }),

    
    formWedcheckbox: yup.bool(),

    formWedStart: yup
    .string()
    .when('formWedcheckbox', {
    is: false,
    then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
        return dayjs(value, 'HH:mm:ss').isValid();
    }),
    otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                    return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                }), // No validation when formMoncheckbox is false
    }),

    formWedEnd: yup
        .string()
        .when('formWedcheckbox', {
        is: false,
        then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formWedStart = Moment(this.parent.formWedStart, 'HH:mm');
            const formWedEnd = Moment(value, 'HH:mm');
            return formWedEnd.isAfter(formWedStart);
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
                // Check if the value is a valid time
            return dayjs(value, 'HH:mm:ss').isValid();
            }),
        otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formWedStart = Moment(this.parent.formWedStart, 'HH:mm');
            const formWedEnd = Moment(value, 'HH:mm');
            return  value != null ? formWedEnd.isAfter(formWedStart) : true;
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
            }), // No validation when formMoncheckbox is false or formMonStart is not provided
        }),

  
    // formWedcheckbox: yup.boolean().oneOf([true], "Please check the Close checkbox").required("Please check the Close checkbox"),
    formThucheckbox: yup.bool(),

    formThuStart: yup
    .string()
    .when('formThucheckbox', {
    is: false,
    then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
        return dayjs(value, 'HH:mm:ss').isValid();
    }),
    otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                    return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                }), // No validation when formMoncheckbox is false
    }),

    formThuEnd: yup
        .string()
        .when('formThucheckbox', {
        is: false,
        then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
            const formThuStart = Moment(this.parent.formThuStart, 'HH:mm');
            const formThuEnd = Moment(value, 'HH:mm');
            return formThuEnd.isAfter(formThuStart);
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
                // Check if the value is a valid time
            return dayjs(value, 'HH:mm:ss').isValid();
            }),
        otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {

            const formThuStart = Moment(this.parent.formThuStart, 'HH:mm');
            const formThuEnd = Moment(value, 'HH:mm');
             return  value != null ? formThuEnd.isAfter(formThuStart) : true;
        }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
            }), // No validation when formMoncheckbox is false or formMonStart is not provided
        }),

   
    // formThucheckbox: yup.boolean().oneOf([true], "Please check the Close checkbox").required("Please check the Close checkbox"),
    formFricheckbox: yup.bool(),

    formFriStart: yup
    .string()
    .when('formFricheckbox', {
    is: false,
    then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
        return dayjs(value, 'HH:mm:ss').isValid();
    }),
    otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                    return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                }), // No validation when formMoncheckbox is false
    }),

    formFriEnd: yup
    .string()
    .when('formFricheckbox', {
    is: false,
    then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const formFriStart = Moment(this.parent.formFriStart, 'HH:mm');
        const formFriEnd = Moment(value, 'HH:mm');
        return formFriEnd.isAfter(formFriStart);
    }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is a valid time
        return dayjs(value, 'HH:mm:ss').isValid();
        }),
    otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const formFriStart = Moment(this.parent.formFriStart, 'HH:mm');
        const formFriEnd = Moment(value, 'HH:mm');
        return  value != null ? formFriEnd.isAfter(formFriStart) : true;
    }).test('is-valid-time', 'Please provide a valid time.', function (value) {
        return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
        }), // No validation when formMoncheckbox is false or formMonStart is not provided
    }),


    // formFricheckbox: yup.boolean().oneOf([true], "Please check the Close checkbox").required("Please check the Close checkbox"),
    formSatcheckbox: yup.bool(),

    formSatStart: yup
    .string()
    .when('formSatcheckbox', {
    is: false,
    then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
        return dayjs(value, 'HH:mm:ss').isValid();
    }),
    otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                    return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                }), // No validation when formMoncheckbox is false
    }),

    formSatEnd: yup
    .string()
    .when('formSatcheckbox', {
    is: false,
    then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const formSatStart = Moment(this.parent.formSatStart, 'HH:mm');
        const formSatEnd = Moment(value, 'HH:mm');
        return formSatEnd.isAfter(formSatStart);
    }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is a valid time
        return dayjs(value, 'HH:mm:ss').isValid();
        }),
    otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const formSatStart = Moment(this.parent.formSatStart, 'HH:mm');
        const formSatEnd = Moment(value, 'HH:mm');
        return  value != null ? formSatEnd.isAfter(formSatStart): true;
    }).test('is-valid-time', 'Please provide a valid time.', function (value) {
        return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
        }), // No validation when formMoncheckbox is false or formMonStart is not provided
    }),
    // formSatcheckbox: yup.boolean().oneOf([true], "Please check the Close checkbox").required("Please check the Close checkbox"),

    formSuncheckbox: yup.bool(),

    formSunStart: yup
    .string()
    .when('formSuncheckbox', {
    is: false,
    then: () =>  yup.string().required('Start time is required.').test('is-valid-time', 'Please provide a valid time.', function (value) {
        return dayjs(value, 'HH:mm:ss').isValid();
    }),
    otherwise: () => yup.string().nullable().notRequired().test('is-valid-time', 'Please provide a valid time.', function (value) {
                    return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
                }), // No validation when formMoncheckbox is false
    }),


    formSunEnd: yup
    .string()
    .when('formSuncheckbox', {
    is: false,
    then:  () =>  yup.string().required('End Time is required.').test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const formSunStart = Moment(this.parent.formSunStart, 'HH:mm');
        const formSunEnd = Moment(value, 'HH:mm');
        return formSunEnd.isAfter(formSunStart);
    }).test('is-valid-time', 'Please provide a valid time.', function (value) {
            // Check if the value is a valid time
        return dayjs(value, 'HH:mm:ss').isValid();
        }),
    otherwise:  () =>  yup.string().nullable().notRequired().test('is-valid-time', 'End Time must be after Start Time.', function (value) {
        const formSunStart = Moment(this.parent.formSunStart, 'HH:mm');
        const formSunEnd = Moment(value, 'HH:mm');
        return  value != null ? formSunEnd.isAfter(formSunStart): true;
    }).test('is-valid-time', 'Please provide a valid time.', function (value) {
        return  value != null ? dayjs(value, 'HH:mm:ss').isValid() : true;
        }), // No validation when formMoncheckbox is false or formMonStart is not provided
    }),

    // formSunStart: yup
    // .string() // Input value should be a string
    // .required("Start time is required.") // Time of contact is required, and an error message is provided if not present
    // .test('is-valid-time', 'Please provide a valid time.', function (value) {
    //   // Check if the value is a valid time
    //   return dayjs(value, 'HH:mm:ss').isValid();
    // }),
    // formSunEnd: yup.string()
    // .required('End Time  is required.')
    // .test('is-valid-time', 'End Time must be after Start Time.', function (value) {
    //   const formSunStart = Moment(this.parent.formSunStart, 'HH:mm');
    //   const formSunEnd = Moment(value, 'HH:mm');
    //   return formSunEnd.isAfter(formSunStart);
    // }).test('is-valid-time', 'Please provide a valid time.', function (value) {
    //     // Check if the value is a valid time
    //     return dayjs(value, 'HH:mm:ss').isValid();
    //   }),
    // formSuncheckbox: yup.boolean().oneOf([true], "Please check the Close checkbox").required("Please check the Close checkbox"),



});

const ListHolidays = () => {
    const location = useLocation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const formRef = useRef(null);

    //const [isProfileOverlayOpen, setProfileOverlayOpen] = useState(false);
    const navigate = useNavigate();
    
    const [isActive, setIsActive] = useState('first');

    useEffect(() => {
        const checkPreviousPagePathname = async () => {
            const previousPagePathname = await sessionStorage.getItem('previousPagePathname');

            if (previousPagePathname) {
                if (['/add-holidays', '/edit-holidays'].includes(previousPagePathname)) {
                    setIsActive('second');
                } else {
                    setIsActive('first');
                }
            } else {
                console.log('No referrer information available.');
                setIsActive('first'); // Default to 'first' if no previousPagePathname exists
            }

            // Always update the sessionStorage with the current pathname after check
            sessionStorage.setItem('previousPagePathname', location.pathname);
        };

        // Call the async function to check the previous page
        checkPreviousPagePathname();
    }, [location.pathname]);


    const handleClick = (eventKey) => {

        setIsActive(eventKey); // Update the active tab when a tab is clicked
    }; 

    const [loading, setLoading] = useState(true);


    const handleRowHolidayEditClick = async (e) => {
        navigate(`/edit-holidays`, { state: { pk_hol_id: `${e.pk_hol_id}` } });
    }
    const [OPData, setOPData] = useState([]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const extractedId = urlParams.get("id");
        if (extractedId !== '') {
            //setId(extractedId);
            fetchData(extractedId);
            fetchOPData(extractedId);
        }
    }, [location]);

    



    //Api call start

    const fetchOPData = async (id) => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "readOperatingHours",
                center_key: sessionStorage.getItem('key'),
            });

            if (response.data.success === true) {
                if (response.data.data.length > 0) {
                    setOPData(response.data.data[0]);
                }
                setLoading(false);

            } else {
                setLoading(false);

                // Handle unsuccessful response, e.g., show an alert to the user.
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const onSubmit = async (formValues) => {
        // Disable the submit button and reset alerts
        setIsSubmitting(true);
        try {
            const response = await axios.post(BASE_URL + "API/api_index.php", {
                security_key: security_key,
                mode: "createOperatingHours",

                ...formValues,
            });
            if (response.data.success === true) {
                if (response.data.data === 1) {
                    //resetFormFields(resetForm, setValues, initialValues);
                    toast.success("Success! Operating hours has been set successfully.", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    // After 3 seconds, redirect to another page
                    setTimeout(function () {
                        navigate("/operating-hoursandholidays");
                    }, 3000); // 3000 milliseconds, which is equivalent to 3 seconds

                    setIsSubmitting(false); // Re-enable the submit button
                } else {
                    toast.error("Failure! Unable to process operating hours. Try again later", {
                        autoClose: 3000, // 3 seconds in this example
                    });

                    setIsSubmitting(false); // Re-enable the submit button
                }

            } else {

                toast.error("Failure! Unable to process operating hours. Try again later", {
                    autoClose: 3000, // 3 seconds in this example
                });

                setIsSubmitting(false); // Re-enable the submit button

            }
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failure! Unable to process operating hours. Try again later", {
                autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button

        } finally {
            setIsSubmitting(false); // Re-enable the submit button

        }
    };

    // });
    const { values, handleBlur, touched, handleChange, errors, handleSubmit, setTouched } = useFormik({

        initialValues: {

            /*tbl_op_hours*/
            op_dayofweek: OPData ? OPData.op_weekday != null && OPData.op_weekday != '' ? OPData.op_weekday : '7' : '7',
            //op_dayofweek: OPData ? OPData.op_weekday : '',

            formMonStart: OPData ? OPData.op_monst_time : '',
            formMonEnd: OPData ? OPData.op_monen_time : '',
            formMoncheckbox: OPData ? parseInt(OPData.op_monis_closed) === 1 ? true : false : false,

            formTueStart: OPData ? OPData.op_tuest_time : '',
            formTueEnd: OPData ? OPData.op_tueen_time : '',
            formTuecheckbox: OPData ? parseInt(OPData.op_tueis_closed) === 1 ? true : false : false,


            formWedStart: OPData ? OPData.op_wedst_time : '',
            formWedEnd: OPData ? OPData.op_weden_time : '',
            formWedcheckbox: OPData ? parseInt(OPData.op_wedis_closed) === 1 ? true : false : false,


            formThuStart: OPData ? OPData.op_thust_time : '',
            formThuEnd: OPData ? OPData.op_thuen_time : '',
            formThucheckbox: OPData ? parseInt(OPData.op_thuis_closed) === 1 ? true : false : false,


            formFriStart: OPData ? OPData.op_frist_time : '',
            formFriEnd: OPData ? OPData.op_frien_time : '',
            formFricheckbox: OPData ? parseInt(OPData.op_friis_closed) === 1 ? true : false : false,


            formSatStart: OPData ? OPData.op_satst_time : '',
            formSatEnd: OPData ? OPData.op_saten_time : '',
            formSatcheckbox: OPData ? parseInt(OPData.op_satis_closed) === 1 ? true : false : false,


            formSunStart: OPData ? OPData.op_sunst_time : '',
            formSunEnd: OPData ? OPData.op_sunen_time : '',
            formSuncheckbox: OPData ? parseInt(OPData.op_sunis_closed) === 1 ? true : false : false,




            /*Common*/

            updated_by: sessionStorage.getItem('key'),
            fk_cent_key: sessionStorage.getItem('key')

        },
        enableReinitialize: true,
        validationSchema: basicSchema,
        onSubmit
    });



    /*List of Holidays */






    // Sample columns configuration
    const columns = [
        { 
            headerName: 'Holiday Title',
            field: "Holiday Title",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
              return `${params.row.hol_title == null || params.row.hol_title == '' ? '-' : params.row.hol_title}`;
            },
        },
        { 
            headerName: 'Holiday Type',
            field: "Holiday Type",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
              return `${params.row.hol_type == null || params.row.hol_type == '' ? '-' : params.row.hol_type}`;
            },
        },
        { 
            headerName: 'Holiday Start Date',
            field: "Holiday  Start Date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
              return `${params.row.hol_startdate == null || params.row.hol_startdate == '' ? '-' : Moment(params.row.hol_startdate).format('DD-MM-YYYY')}`;
            },
            sortComparator: (valueA, valueB, rowA, rowB, direction) => {
                if (valueA === '-' || valueB === '-') {
                  // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
                  return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
                }
          
                const dateA = Moment(valueA, 'DD-MM-YYYY');
                const dateB = Moment(valueB, 'DD-MM-YYYY');
          
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
              },
        },
        { 
            headerName: 'Holiday  End Date',
            field: "Holiday End Date",
            headerClassName: "super-app-theme--header",
            flex: 1,
            valueGetter: (params) => {
                return `${params.row.hol_enddate == null || params.row.hol_enddate == '' ? '-' : Moment(params.row.hol_enddate).format('DD-MM-YYYY')}`;
            },
            sortComparator: (valueA, valueB, rowA, rowB, direction) => {
                if (valueA === '-' || valueB === '-') {
                  // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
                  return valueA === valueB ? 0 : valueA === '-' ? 1 : -1;
                }
          
                const dateA = Moment(valueA, 'DD-MM-YYYY');
                const dateB = Moment(valueB, 'DD-MM-YYYY');
          
                return direction === 'asc' ? dateA - dateB : dateB - dateA;
              },
        },
       
    ];

    // Sample data source

    //Api call start
    const [data1, setItems] = useState([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.post(BASE_URL + 'API/api_index.php', {
                security_key: security_key,
                mode: "getAllHoliday",
                center_key: sessionStorage.getItem('key')
            });
            
            if (response.data.success === true) {
                setRecords(response.data.data);
                setItems(response.data.data);
                setLoading(false);

            } else {
                //alert message provide
                setLoading(false);

            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    //Api call End


    const [records, setRecords] = useState([]);

    return (





        <div className="page-wrapper">

            {/* --------------Page Start--------------- */}


            {/* --------------Section Start--------------- */}

            <div className="mb-3 d-flex justify-content-between align-items-center">
                <div>
                    <h5 className='page-title'> Operating Hours &  Holidays</h5>

                    <div role="presentation" >
                        <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                            <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                            <Typography color="text.primary">Operating Hours &  Holidays</Typography>
                        </Breadcrumbs>
                    </div>
                </div>
                <div>
                    <div className='d-flex align-items-center gap-2'>
                        {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}


                        {isActive == "second" && (
                            <NavLink className="primary-filled rounded-10 p-2" to='/add-holidays'>
                                <AddIcon className="btn-icon" /> Add New Holiday</NavLink>
                        )}
                    </div>
                </div>

            </div>
            <Card className='my-card p-1 bg-white'>

                <Card.Body>

                    {/* -------------Content Start--------------- */}

                    <Tab.Container defaultActiveKey="first"   activeKey={isActive}>
                        <Row>
                            <Col sm={12} className='border-bottom mb-2'>
                          
                                <Nav variant="pills" className="mb-3 text-dark ">
                                    <div className='d-flex justify-content-between'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" className="px-4 py-2 text-dark me-2" onClick={() => handleClick("first")} >Operating Hours</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" className="px-4 py-2 text-dark" onClick={() => handleClick("second")} >Holidays</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                            </Col>

                            <Col sm={12}>

                                <Tab.Content>
                                    <Tab.Pane eventKey="first">

                                        <ToastContainer position="top-center" />
                                        {/* center form */}
                                        <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
                                            <div className="mb-3">
                                                <Row>

                                                    <Col xs="12">

                                                        {/* -------------- Operating Timing --------------- */}
                                                        <Row className="justify-content-center">
                                                            <Col lg="4" md="6" sm="12" className='position-relative'>
                                                                <h6 className="form-lable required-field"> Work Week </h6>

                                                                <Select menuPlacement="auto" options={options_weeklydays}
                                                                    name="op_dayofweek"
                                                                    value={options_weeklydays.find(option => option.value && values.op_dayofweek && option.value.toString() === values.op_dayofweek.toString())}
                                                                    id="op_dayofweek"
                                                                    onChange={(selectedOption) => {
                                                                        const event = {
                                                                            target: {
                                                                                name: "op_dayofweek",
                                                                                value: selectedOption["value"],
                                                                            },
                                                                        };
                                                                        handleChange(event);
                                                                    }}
                                                                    onBlur={(event) => {
                                                                        handleBlur(event);
                                                                        setTouched({ ...touched, op_dayofweek: true });
                                                                    }}
                                                                    className={errors.op_dayofweek && touched.op_dayofweek ? "input-error" : ""}
                                                                />
                                                                {errors.op_dayofweek && touched.op_dayofweek && <p className="error">{errors.op_dayofweek}</p>}


                                                            </Col>

                                                            <Col xs={12}>
                                                                <div className="p-2 bg-white">

                                                                    {/* -------------- Monday Operating Time --------------- */}
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} className=''>
                                                                            <h6 className='mb-0'>Monday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formMoncheckbox ? '' : 'required-field'}>Start time  </h6>
                                                                            <Form.Group className="ws-nowrap" controlId="formMonStart">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formMonStart"
                                                                                    value={values.formMonStart}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formMonStart && touched.formMonStart ? " input-error" : ""}

                                                                                /> */}
                                                                                 <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formMonStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formMonStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formMonStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formMonStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formMonStart && touched.formMonStart ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                               


                                                                            </Form.Group>
                                                                            {errors.formMonStart && touched.formMonStart && <span className="error">{errors.formMonStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formMoncheckbox ? '' : 'required-field'}>End time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formMonEnd">


                                                                                {/* <Form.Control type="time"

                                                                                    value={values.formMonEnd}
                                                                                    name="formMonEnd"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formMonEnd && touched.formMonEnd ? "input-error" : ""}

                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formMonEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formMonEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formMonEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formMonEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formMonEnd && touched.formMonEnd ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                               

                                                                            </Form.Group>
                                                                            {errors.formMonEnd && touched.formMonEnd && <span className="error">{errors.formMonEnd}</span>}

                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formMoncheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    name="formMoncheckbox"
                                                                                    checked={values.formMoncheckbox}

                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    label="Closed"
                                                                                    className="close mb-0 "
                                                                                />


                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} className=''>
                                                                            <h6 className='mb-0'>Tuesday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formTuecheckbox ? '' : 'required-field'}>Start time  </h6>
                                                                            <Form.Group className="ws-nowrap" controlId="formTueStart">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formTueStart"
                                                                                    value={values.formTueStart}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formTueStart && touched.formTueStart ? " input-error" : ""}

                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formTueStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formTueStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formTueStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formTueStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formTueStart && touched.formTueStart ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                              
                                                                            </Form.Group>
                                                                            {errors.formTueStart && touched.formTueStart && <span className="error">{errors.formTueStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formTuecheckbox ? '' : 'required-field'}>End time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formTueEnd">


                                                                                {/* <Form.Control type="time"
                                                                                    name="formTueEnd"
                                                                                    value={values.formTueEnd}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formTueEnd && touched.formTueEnd ? " input-error" : ""}

                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formTueEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formTueEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formTueEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formTueEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formTueEnd && touched.formTueEnd ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formTueEnd && touched.formTueEnd && <span className="error">{errors.formTueEnd}</span>}
                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formTuecheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    name="formTuecheckbox"
                                                                                    checked={values.formTuecheckbox}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    label="Closed"
                                                                                    className="close mb-0 "
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} className=''>
                                                                            <h6 className='mb-0'>Wednesday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formWedcheckbox ? '' : 'required-field'}>Start time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formWedStart">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formWedStart"
                                                                                    value={values.formWedStart}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}

                                                                                    className={errors.formWedStart && touched.formWedStart ? " input-error" : ""}

                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formWedStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formWedStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formWedStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formWedStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formWedStart && touched.formWedStart ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formWedStart && touched.formWedStart && <span className="error">{errors.formWedStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formWedcheckbox ? '' : 'required-field'}>End time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formWedEnd">


                                                                                {/* <Form.Control type="time"
                                                                                    name="formWedEnd"
                                                                                    value={values.formWedEnd}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formWedEnd && touched.formWedEnd ? " input-error" : ""}

                                                                                /> */}

                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formWedEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formWedEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formWedEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formWedEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formWedEnd && touched.formWedEnd ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formWedEnd && touched.formWedEnd && <span className="error">{errors.formWedEnd}</span>}
                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formWedcheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    name="formWedcheckbox"
                                                                                    checked={values.formWedcheckbox}

                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    label="Closed"
                                                                                    className="close mb-0 "
                                                                                />
                                                                            </Form.Group>
                                                                            {values.formWedcheckbox}
                                                                        </Col>

                                                                    </Row>
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} >
                                                                            <h6 className='mb-0'>Thursday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formThucheckbox ? '' : 'required-field'}>Start time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formThuStart">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formThuStart"
                                                                                    value={values.formThuStart}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formThuStart && touched.formThuStart ? "input-error" : ""}

                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formThuStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formThuStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formThuStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formThuStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formThuStart && touched.formThuStart ? "input-error w-100 h-auto op_hours" : "w-100 h-auto op_hours"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formThuStart && touched.formThuStart && <span className="error">{errors.formThuStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formThucheckbox ? '' : 'required-field'}>End time  </h6>
                                                                            <Form.Group className="ws-nowrap" controlId="formThuEnd">


                                                                                {/* <Form.Control type="time"
                                                                                    name="formThuEnd"
                                                                                    value={values.formThuEnd}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formThuEnd && touched.formThuEnd ? "input-error" : ""}

                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formThuEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formThuEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formThuEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formThuEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formThuEnd && touched.formThuEnd ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                               

                                                                            </Form.Group>
                                                                            {errors.formThuEnd && touched.formThuEnd && <span className="error">{errors.formThuEnd}</span>}
                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formThucheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    label="Closed"
                                                                                    name="formThucheckbox"
                                                                                    checked={values.formThucheckbox}

                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className="close mb-0 "
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} className=''>
                                                                            <h6 className='mb-0'>Friday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formFricheckbox ? '' : 'required-field'}>Start time  </h6>
                                                                            <Form.Group className="ws-nowrap" controlId="formFriStart">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formFriStart"
                                                                                    value={values.formFriStart}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formFriStart && touched.formFriStart ? " input-error" : ""}

                                                                                /> */}

                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formFriStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formFriStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formFriStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formFriStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formFriStart && touched.formFriStart ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formFriStart && touched.formFriStart && <span className="error">{errors.formFriStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formFricheckbox ? '' : 'required-field'}>End time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formFriEnd">


                                                                                {/* <Form.Control type="time"
                                                                                    name="formFriEnd"
                                                                                    value={values.formFriEnd}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formFriEnd && touched.formFriEnd ? " input-error" : ""}

                                                                                /> */}

                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formFriEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formFriEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formFriEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formFriEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formFriEnd && touched.formFriEnd ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formFriEnd && touched.formFriEnd && <span className="error">{errors.formFriEnd}</span>}
                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formFricheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    label="Closed"
                                                                                    name="formFricheckbox"
                                                                                    checked={values.formFricheckbox}

                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className="close mb-0 "
                                                                                />
                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} className=''>
                                                                            <h6 className='mb-0'>Saturday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6  class={values.formSatcheckbox ? '' : 'required-field'}>Start time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formSatStart">

                                                                                {/* <Form.Control type="time"
                                                                                    value={values.formSatStart}
                                                                                    name="formSatStart"
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}

                                                                                    className={errors.formSatStart && touched.formSatStart ? " input-error" : ""}

                                                                                /> */}

                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formSatStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formSatStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formSatStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formSatStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formSatStart && touched.formSatStart ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                               
                                                                            </Form.Group>
                                                                            {errors.formSatStart && touched.formSatStart && <span className="error">{errors.formSatStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6  class={values.formSatcheckbox ? '' : 'required-field'}>End time  </h6>
                                                                            <Form.Group className="ws-nowrap" controlId="formSatEnd">


                                                                                {/* <Form.Control type="time"
                                                                                    name="formSatEnd"
                                                                                    value={values.formSatEnd}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formSatEnd && touched.formSatEnd ? "input-error" : ""}
                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formSatEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formSatEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formSatEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formSatEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formSatEnd && touched.formSatEnd ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formSatEnd && touched.formSatEnd && <span className="error">{errors.formSatEnd}</span>}
                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formSatcheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    label="Closed"
                                                                                    name="formSatcheckbox"
                                                                                    checked={values.formSatcheckbox}

                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className="close mb-0 "

                                                                                />

                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>
                                                                    <Row className='mb-3 align-items-center'>

                                                                        <Col lg={2} className=''>
                                                                            <h6 className='mb-0'>Sunday</h6>
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formSuncheckbox ? '' : 'required-field'}>Start time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formSunStart">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formSunStart"
                                                                                    value={values.formSunStart}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formSunStart && touched.formSunStart ? " input-error" : ""}
                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formSunStart, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formSunStart",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formSunStart: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formSunStart',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formSunStart && touched.formSunStart ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                                
                                                                            </Form.Group>
                                                                            {errors.formSunStart && touched.formSunStart && <span className="error">{errors.formSunStart}</span>}
                                                                        </Col>

                                                                        <Col lg={4} className='position-relative'>
                                                                            <h6 class={values.formSuncheckbox ? '' : 'required-field'}>End time  </h6>

                                                                            <Form.Group className="ws-nowrap" controlId="formSunEnd">

                                                                                {/* <Form.Control type="time"
                                                                                    name="formSunEnd"
                                                                                    value={values.formSunEnd}
                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className={errors.formSunEnd && touched.formSunEnd ? " input-error" : ""}


                                                                                /> */}
                                                                                <LocalizationProvider className={'form-control'}  dateAdapter={AdapterDayjs}>
                                                                                    <TimePicker 
                                                                                    value={dayjs(values.formSunEnd, 'HH:mm:ss')}
                                                                                    onChange={(time) =>  {
                                                                                    const event = {
                                                                                    target: {
                                                                                        name: "formSunEnd",
                                                                                        value: time != null? dayjs(time, 'HH:mm:ss').format('HH:mm:ss') : time,
                                                                                    },
                                                                                    };
                                                                                    
                                                                                    handleChange(event);
                                                                                    
                                                                                    }}
                                                                                    onBlur={(event) => {
                                                                                        handleBlur(event);
                                                                                        setTouched({ ...touched, formSunEnd: true });
                                                                                    }}
                                                                                    slotProps={{
                                                                                        textField: { readOnly: true, size: 'small',
                                                                                        id: 'formSunEnd',
                                                                                        },
                                                                                    }}
                                                                                    className={errors.formSunEnd && touched.formSunEnd ? "input-error w-100 h-auto" : "w-100 h-auto"}
                                                                                    />
                                                                                
                                                                                </LocalizationProvider>
                                                                               
                                                                            </Form.Group>
                                                                            {errors.formSunEnd && touched.formSunEnd && <span className="error">{errors.formSunEnd}</span>}
                                                                        </Col>

                                                                        <Col lg={2} className='position-relative'>
                                                                            {/* --------------Contact Checkbox--------------- */}
                                                                            <Form.Group className="d-flex justify-content-start" controlId="formSuncheckbox">
                                                                                <Form.Check // prettier-ignore
                                                                                    type="checkbox"
                                                                                    label="Closed"
                                                                                    name="formSuncheckbox"
                                                                                    checked={values.formSuncheckbox}

                                                                                    onChange={handleChange}
                                                                                    onBlur={handleBlur}
                                                                                    className="close mb-0 "
                                                                                />

                                                                            </Form.Group>
                                                                        </Col>

                                                                    </Row>
                                                                    {/* -------------- End --------------- */}



                                                                    {/* -------------- Buttons --------------- */}

                                                                    <Stack gap={2} className="mt-4">
                                                                        <div className="text-end">

                                                                            <Button type="submit" variant="contained" className="primary-filled " disabled={isSubmitting}>Set</Button>
                                                                        </div>
                                                                    </Stack>

                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        {/* --------------Student Particulars End--------------- */}

                                                    </Col>

                                                </Row>
                                            </div>
                                        </Form>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="second">
                            

                                        <Box
                                            sx={{
                                                height: "fit-content",
                                                width: "100%",
                                                "& .actions": {
                                                    color: "text.secondary",
                                                },
                                                "& .textPrimary": {
                                                    color: "text.primary",
                                                },
                                                "& .super-app-theme--header": {
                                                    backgroundColor: "#e3e6ee",
                                                },
                                            }}
                                            style={{ height: '100%', width: "100%" }}
                                        >
                                           <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                                //sx={{ border: "none", m: 2 }}
                                                autoHeight
                                                slots={{
                                                    loadingOverlay: LinearProgress,
                                                    //noRowsOverlay: CustomNoRowsOverlay,
                                                    toolbar: GridToolbar,
                                                    // toolbar: EditToolbar,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        csvOptions: { fileName: "HolidayList" },
                                                        printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "HolidayList" },
                                                        records,
                                                        showQuickFilter: true
                                                    },


                                                }}
                                                initialState={{
                                                    ...records.initialState,
                                                    pagination: { paginationModel: { pageSize: 10 } },
                                                }}
                                                showQuickFilter={true}

                                                pageSizeOptions={[10, 25, 50, 100]}

                                                loading={loading}
                                                rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                                columns={columns}
                                                rowHeight={60} density="compact"
                                                onRowClick={(params) => handleRowHolidayEditClick(params.row)}

                                                disableRowSelectionOnClick
                                                showCellVerticalBorder={true}
                                                showColumnVerticalBorder={true}

                                            />


                                        </Box>


                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>


                    </Tab.Container>

                </Card.Body>

            </Card>

            {/* --------------Section End--------------- */}




            {/* --------------Page End--------------- */}

        </div >



    );


};

export default ListHolidays;
