/*
Purpose: list details of diagnostics with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/

import { useState, useEffect } from "react";

import { useNavigate, NavLink } from "react-router-dom";

import React from "react";
import { Row, Col, Tab, Nav, Card, Form, Modal } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

import Moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";
import "./Student.css";
import { security_key, BASE_URL } from "../global";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

function Diagnostics() {
  const [isSubmittingSlot, setIsSubmittingSlot] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [showModal1, setShowModal1] = useState(false);
  const toggleModal1 = () => setShowModal1(!showModal1);
  const [loadedFromEdit, setLoadedFromEdit] = useState(false);
  const location = useLocation();

  /* Fees */

  // const [isActive, setIsActive] = useState(true);

  // const handleClick = (event) => {
  //   setIsActive((isActive) => !isActive);
  // };

  
  const [activeTab, setActiveTab] = useState("first");

  useEffect(() => {
      const checkPreviousPagePathname = async () => {
          const previousPagePathname = await sessionStorage.getItem('previousPagePathname');

          if (previousPagePathname) {
              if (['/Edit-diagnostics'].includes(previousPagePathname)) {
                setActiveTab('second');
              }else if (['/assessment-closed'].includes(previousPagePathname)) {
                setActiveTab('thired');
              } else {
                setActiveTab('first');
              }
          } else {
              console.log('No referrer information available.');
              setActiveTab('first'); // Default to 'first' if no previousPagePathname exists
          }

          // Always update the sessionStorage with the current pathname after check
          sessionStorage.setItem('previousPagePathname', location.pathname);
      };

      // Call the async function to check the previous page
      checkPreviousPagePathname();
  }, [location.pathname]);

 

  const handleClick = (eventKey) => {
    setActiveTab(eventKey);
  };
  // useEffect(() => {
  //   if (location.state?.fromEditForm) {
  //     setActiveTab("second");
  //     const newState = { ...location.state, fromEditForm: false };
  //     window.history.replaceState(newState, "");
  //   } else {
  //     setActiveTab("first");
  //   }
  // }, [location.state]);

  const [records, setRecords] = useState([]);

  const [records_d, setRecords_d] = useState([]);

  const [records_a, setRecords_a] = useState([]);

  const [data, setItems] = useState([]);

  const [data_d, setItems_d] = useState([]);

  const [data_a, setItems_a] = useState([]);

  const [options_yearlevel, setYearLevelOptions] = useState([]);

  //Api call start

  useEffect(() => {
    fetchData();
    //fetchData_d();
    axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        country_id: sessionStorage.getItem("country_code"),
        mode: "getCountryYearLevel",
      })
      .then((response_year) => {
        // Assuming the API response is an array of objects with 'id' and 'value' properties
        const formattedyearOptions = response_year.data.data.map((option) => ({
          value: option.activity_level_id,
          label: option.level_name,
        }));
        setYearLevelOptions(formattedyearOptions);
      })
      .catch((error) => {
        console.error("Error fetching options from API: ", error);
      });
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllAssessmentSchedule",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllLeadDiagnosticClose_new",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setRecords_d(response.data.data);
        setItems_d(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    try {
      const response = await axios.post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        mode: "getAllLeadDiagnostic_new",
        fk_cent_key: sessionStorage.getItem("key"),
      });

      if (response.data.success === true) {
        setItems_a(response.data.data);
        setRecords_a(response.data.data);
        setLoading(false);
      } else {
        //alert message provide
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const fetchData_d = async () => {
  //   try {
  //     const response = await axios.post(BASE_URL+'API/api_index.php', {
  //       security_key: security_key,
  //       mode: "getAllLeadDiagnostic",
  //       fk_cent_key: sessionStorage.getItem('key')
  //     });
  //
  //     if (response.data.success === true) {
  //       setRecords_d(response.data.data);
  //       setItems_d(response.data.data);
  //     } else {
  //       //alert message provide
  //     }

  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };

  //Api call End

  const columns = [
    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_first_name == null || params.row.st_first_name == ""
            ? "-"
            : params.row.st_first_name + " " + params.row.st_surname
        }`;
      },
    },
    {
      headerName: "Date",
      field: "Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.as_date == null || params.row.as_date == ""
            ? "-"
            : Moment(params.row.as_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Time",
      field: "Time",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.as_start_time == null || params.row.as_start_time == ""
            ? "-"
            : Moment(params.row.as_start_time, "HH:mm:ss").format("HH:mm a")
        }`;
      },
    },
    {
      headerName: "English Year Level",
      field: "English Year Level",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.lable_english_year == null ||
          params.row.lable_english_year == ""
            ? "-"
            : params.row.lable_english_year
        }`;
      },
    },
    {
      headerName: "Maths Year Level",
      field: "Maths Year Level",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.lable_maths_year == null ||
          params.row.lable_maths_year == ""
            ? "-"
            : params.row.lable_maths_year
        }`;
      },
    },
    {
      headerName: "Phone",
      field: "Phone",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_phone == null || params.row.st_phone == ""
            ? "-"
            : params.row.st_phone
        }`;
      },
    },
    //  { name: "id", selector: (row) => row.fk_lead_key, omit: true },
  ];

  const columns_a = [
    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_first_name == null || params.row.st_first_name == ""
            ? "-"
            : params.row.st_first_name + " " + params.row.st_surname
        }`;
      },
    },
    {
      headerName: "Assessment Date",
      field: "Assesment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.as_date == null || params.row.as_date == ""
            ? "-"
            : Moment(params.row.as_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Gender",
      field: "Gender",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_gender == null || params.row.st_gender == ""
            ? "-"
            : params.row.st_gender
        }`;
      },
    },
    {
      headerName: "Outcome",
      field: "Out Come",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.la_outcome == null || params.row.la_outcome == ""
            ? "-"
            : params.row.la_outcome
        }`;
      },
    },
    {
      headerName: "Initial Diagnostics Close",
      field: "Initial Diagnostics Close",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.is_diagnostics_close == 0 ? "Not Closed" : "Closed"
        }`;
      },
    },
    // { name: "id", selector: (row) => row.pk_lead_key, omit: true },
  ];

  const columns_l = [
    {
      headerName: "Student Name",
      field: "Student Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_first_name == null || params.row.st_first_name == ""
            ? "-"
            : params.row.st_first_name + " " + params.row.st_surname
        }`;
      },
      // selector: row => row.st_first_name + ' ' + row.st_surname,
      // sortable: true,
    },
    {
      headerName: "Assessment Date",
      field: "Assesment Date",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.as_date == null || params.row.as_date == ""
            ? "-"
            : Moment(params.row.as_date, "YYYY-MM-DD").format("DD-MM-YYYY")
        }`;
      },
      sortComparator: (valueA, valueB, rowA, rowB, direction) => {
        if (valueA === "-" || valueB === "-") {
          // Handle sorting for dashes ("-"), place them at the end or beginning based on direction
          return valueA === valueB ? 0 : valueA === "-" ? 1 : -1;
        }

        const dateA = Moment(valueA, "DD-MM-YYYY");
        const dateB = Moment(valueB, "DD-MM-YYYY");

        return direction === "asc" ? dateA - dateB : dateB - dateA;
      },
    },
    {
      headerName: "Gender",
      field: "Gender",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.st_gender == null || params.row.st_gender == ""
            ? "-"
            : params.row.st_gender
        }`;
      },
    },
    {
      headerName: "Outcome",
      field: "Out Come",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.la_outcome == null || params.row.la_outcome == ""
            ? "-"
            : params.row.la_outcome
        }`;
      },
    },
    {
      headerName: "Initial Diagnostics Close",
      field: "Initial Diagnostics Close",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${
          params.row.is_diagnostics_close == 0 ? "Not Closed" : "Closed"
        }`;
      },
    },
  ];
  const handleRowDiagnosticsUpdateClick = async (e) => {
    if (e.is_diagnostics_close == 1) {
      toast.error("Sorry! This record is closed", {
        autoClose: 3000, // 3 seconds in this example
      });
    } else {
      navigate(`/Edit-diagnostics`, {
        state: { pk_lead_key: `${e.pk_lead_key}` },
      });
    }
  };

  //search Functionality End

  const tableData = {
    columns,
    data,
  };
  const [StudentData, setStudentData] = useState({});

  const validationSchema1 = yup.object().shape({
    // la_maths_year_level: yup.mixed().required("Maths year level  is required.."),
    // la_english_year_level: yup.mixed().required("English year level  is required.."),

    la_maths_year_level: yup
      .mixed()
      .test("at-least-one", "Year level is required.", function (value) {
        const { la_english_year_level } = this.parent;
        if (!value && !la_english_year_level) {
          return this.createError({
            path: "la_maths_year_level",
            message: "Year level is required.",
          });
        }
        return true;
      }),
    la_english_year_level: yup
      .mixed()
      .test("at-least-one", "Year level is required.", function (value) {
        const { la_maths_year_level } = this.parent;
        if (!value && !la_maths_year_level) {
          return this.createError({
            path: "la_english_year_level",
            message: "Year level is required.",
          });
        }
        return true;
      }),

    as_date: yup
      .string()
      .test("is-valid-date", "Please provide a valid date.", function (value) {
        const parsedDate = dayjs(value, "YYYY-MM-DD");
        return parsedDate.isValid();
      })
      .required("Date is required."),

    as_start_time: yup
      .string() // Input value should be a string
      .notRequired() // Time of contact is required, and an error message is provided if not present
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return !value || dayjs(value, "HH:mm:ss").isValid();
      }),
    as_end_time: yup
      .string()
      .notRequired()
      .test("is-after", "End time must be after start time", function (value) {
        const { as_start_time } = this.parent;
        if (!as_start_time || !value) return true; // Skip validation if one of the fields is empty

        const startTimeParts = as_start_time
          .split(":")
          .map((part) => parseInt(part, 10));
        const endTimeParts = value.split(":").map((part) => parseInt(part, 10));

        const startTimeMinutes = startTimeParts[0] * 60 + startTimeParts[1];
        const endTimeMinutes = endTimeParts[0] * 60 + endTimeParts[1];

        return endTimeMinutes > startTimeMinutes;
      })
      .test("is-valid-time", "Please provide a valid time.", function (value) {
        // Check if the value is a valid time
        return !value || dayjs(value, "HH:mm:ss").isValid();
      }),
  });

  const initialValues1 = {
    pk_student_key: StudentData ? StudentData.fk_student_key : "",
    pk_lead_key: StudentData ? StudentData.fk_lead_key : "",

    as_date: StudentData ? StudentData.as_date : "",
    as_start_time: StudentData
      ? StudentData.as_start_time != null && StudentData.as_start_time != ""
        ? StudentData.as_start_time
        : ""
      : "",
    as_end_time: StudentData
      ? StudentData.as_end_time != null && StudentData.as_end_time != ""
        ? StudentData.as_end_time
        : ""
      : "",
    la_maths_year_level: StudentData
      ? StudentData.la_maths_year_level != null &&
        StudentData.la_maths_year_level != ""
        ? StudentData.la_maths_year_level
        : ""
      : "",
    la_english_year_level: StudentData
      ? StudentData.la_english_year_level != null &&
        StudentData.la_english_year_level != ""
        ? StudentData.la_english_year_level
        : ""
      : "",

    updated_by: sessionStorage.getItem("key"),
  };

  const formik1 = useFormik({
    initialValues: initialValues1,
    validationSchema: validationSchema1,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setIsSubmittingSlot(true);

      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "UpdateAssessmentSchedule",
          ...values,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });

            fetchData();
            //resetForm();
            toggleModal1();

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else if (response.data.data === 2) {
            toast.error("Student username is not available", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else if (response.data.data == 4) {
            toast.error(
              "Maths year level is not available, Try another option",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else if (response.data.data == 5) {
            toast.error(
              "English year level is not available, Try another option",
              {
                autoClose: 3000, // 3 seconds in this example
              }
            );

            setIsSubmittingSlot(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to update schedule. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmittingSlot(false); // Re-enable the submit button
          }
        } else {
          toast.error("Failure! Unable to update schedule. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmittingSlot(false); // Re-enable the submit button
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to update schedule. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmittingSlot(false); // Re-enable the submit button
      } finally {
        setIsSubmittingSlot(false); // Re-enable the submit button
      }
    },
  });

  // const handleConverClick = () => {
  //
  //   //router.push(`/student?id=${e.personId}`) // your redirection method.
  //   navigate(`/Add-diagnostics?id=${StudentData ? StudentData.fk_lead_key : ''}`);
  // }

  // const handleConverClick = () => {
  //   // You can use the 'id' value here for further processing
  //   navigate(`/Add-diagnostics`, { state: { fk_lead_key: `${StudentData.fk_lead_key}` } });

  // };

  const handleConverClick = async () => {
    const responsetime = axios
      .post(BASE_URL + "API/api_index.php", {
        security_key: security_key,
        fk_cent_key: sessionStorage.getItem("key"),
        mode: "getCheckTimeZone",
      })
      .then((responsetime) => {
        if (responsetime.data.data.length > 0) {
          if (
            responsetime.data.data[1] != null &&
            responsetime.data.data[1] != ""
          ) {
            const currentDate = new Date(
              responsetime.data.data[0] + "T" + responsetime.data.data[1]
            );
            let as_end_time_data =
              StudentData &&
              StudentData.as_end_time != null &&
              StudentData.as_end_time != ""
                ? StudentData.as_end_time
                : "01:00:00";
            // Set the session_date (assuming it's a string, convert it to a Date object)
            const dignostic_date = new Date(
              StudentData.as_date + "T" + as_end_time_data
            );

            // Compare dates using getTime() method
            if (currentDate.getTime() >= dignostic_date.getTime()) {
              navigate(`/Add-diagnostics`, {
                state: { fk_lead_key: `${StudentData.fk_lead_key}` },
              });
            } else {
              toast.error("Sorry, the assessment has not been completed", {
                autoClose: 3000, // 3 seconds in this example
              });
            }
          } else {
            toast.error("Sorry, the assessment has not been completed", {
              autoClose: 3000, // 3 seconds in this example
            });
          }
        } else {
          toast.error("Sorry, the assessment has not been completed", {
            autoClose: 3000, // 3 seconds in this example
          });
        }
      })
      .catch((error) => {
        toast.error("Sorry, the assessment has not been completed", {
          autoClose: 3000, // 3 seconds in this example
        });
      });
  };

  const handleRowDiagnosticsEditClick = async (e) => {
    // Clear and reset formik1 fields and touched values for specific fields
    formik1.setFieldValue(
      "la_english_year_level",
      e.la_english_year_level != null && e.la_english_year_level != ""
        ? e.la_english_year_level
        : ""
    );
    formik1.setFieldTouched("la_english_year_level", false);

    formik1.setFieldValue(
      "la_maths_year_level",
      e.la_maths_year_level != null && e.la_maths_year_level != ""
        ? e.la_maths_year_level
        : ""
    );
    formik1.setFieldTouched("la_maths_year_level", false);

    // Submit the form to ensure pending changes are processed
    //formik1.resetForm();

    // Set student data using the event parameter
    setStudentData(e);

    //navigate(`/Edit-diagnostics?id=${e.fk_lead_key}`);
    toggleModal1();
  };

  return (
    <>
      {/* React alert */}
      <ToastContainer position="top-center" />

      <div className="page-wrapper">
        <Modal
          show={showModal1}
          onHide={toggleModal1}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            className="d-flex justify-content-between gap-2"
            closeButton
          >
            <Modal.Title>Schedule Initial Assessment</Modal.Title>
            <div className="">
              {/* <NavLink className="primary-filled rounded-10 p-2" onClick={handleConverClick}>
                Move Diagnostic</NavLink>   */}
            </div>
          </Modal.Header>
          <Form onSubmit={formik1.handleSubmit}>
            <Modal.Body>
              <div className="section-heading border-bottom pb-3 mt-3 mb-3 d-flex justify-content-between gap-2">
                <h6 className="mb-0">Schedule Assessment</h6>
                <Button
                  variant="contained"
                  className="primary-filled"
                  onClick={handleConverClick}
                >
                  Finish Assessment
                </Button>
              </div>
              <Row>
                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable required-field">Date</h6>
                  {/* <Form.Control
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    
                    value={formik1.values.as_date}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    id="as_date"
                    className={formik1.errors.as_date && formik1.touched.as_date ? "input-error" : ""}
                  />
                  {formik1.errors.as_date && formik1.touched.as_date && <p className="error">{formik1.errors.as_date}</p>} */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={dayjs(formik1.values.as_date, "YYYY-MM-DD")}
                      onChange={(date) => {
                        const event = {
                          target: {
                            name: "as_date",
                            value:
                              date != null
                                ? dayjs(date, "YYYY-MM-DD").format("YYYY-MM-DD")
                                : date,
                          },
                        };
                        formik1.handleChange(event);
                      }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                        formik1.setTouched({
                          ...formik1.touched,
                          as_date: true,
                        });
                      }}
                      name="as_date"
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "as_date",
                        },
                      }}
                      className={
                        formik1.errors.as_date && formik1.touched.as_date
                          ? "input-error w-100"
                          : "w-100"
                      }
                      //  minDate={dayjs(formik1.values.as_date, 'YYYY-MM-DD')}
                      minDate={dayjs()}
                      format="DD-MM-YYYY"
                    />
                  </LocalizationProvider>
                  {formik1.errors.as_date && formik1.touched.as_date && (
                    <span className="error">{formik1.errors.as_date}</span>
                  )}
                </Col>
                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable">Start Time</h6>
                  {/* <Form.Control
                    type="time"
                    
                    value={formik1.values.as_start_time}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    id="as_start_time"
                    className={formik1.errors.as_start_time && formik1.touched.as_start_time ? "input-error" : ""}
                  />
                  {formik1.errors.as_start_time && formik1.touched.as_start_time && <p className="error mt-5">{formik1.errors.as_start_time}</p>} */}
                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker
                      value={dayjs(formik1.values.as_start_time, "HH:mm:ss")}
                      onChange={(time) => {
                        const event = {
                          target: {
                            name: "as_start_time",
                            value:
                              time != null
                                ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                                : time,
                          },
                        };

                        formik1.handleChange(event);
                      }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                        formik1.setTouched({
                          ...formik1.touched,
                          as_start_time: true,
                        });
                      }}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "as_start_time",
                        },
                      }}
                      className={
                        formik1.errors.as_start_time &&
                        formik1.touched.as_start_time
                          ? "input-error w-100 h-auto"
                          : "w-100 h-auto"
                      }
                    />
                  </LocalizationProvider>
                  {formik1.errors.as_start_time &&
                    formik1.touched.as_start_time && (
                      <span className="error">
                        {formik1.errors.as_start_time}
                      </span>
                    )}
                </Col>
                <Col lg="4" md="6" sm="12" className="position-relative mb-3">
                  <h6 className="form-lable">End Time</h6>
                  {/* <Form.Control
                    type="time"
                    
                    value={formik1.values.as_end_time}
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    id="as_end_time"
                    className={formik1.errors.as_end_time && formik1.touched.as_end_time ? "input-error" : ""}
                  />
                  {formik1.errors.as_end_time && formik1.touched.as_end_time && <p className="error">{formik1.errors.as_end_time}</p>} */}

                  <LocalizationProvider
                    className={"form-control"}
                    dateAdapter={AdapterDayjs}
                  >
                    <TimePicker
                      value={dayjs(formik1.values.as_end_time, "HH:mm:ss")}
                      onChange={(time) => {
                        const event = {
                          target: {
                            name: "as_end_time",
                            value:
                              time != null
                                ? dayjs(time, "HH:mm:ss").format("HH:mm:ss")
                                : time,
                          },
                        };

                        formik1.handleChange(event);
                      }}
                      onBlur={(event) => {
                        formik1.handleBlur(event);
                        formik1.setTouched({
                          ...formik1.touched,
                          as_end_time: true,
                        });
                      }}
                      slotProps={{
                        textField: {
                          readOnly: true,
                          size: "small",
                          id: "as_end_time",
                        },
                      }}
                      className={
                        formik1.errors.as_end_time &&
                        formik1.touched.as_end_time
                          ? "input-error w-100 h-auto"
                          : "w-100 h-auto"
                      }
                    />
                  </LocalizationProvider>
                  {formik1.errors.as_end_time &&
                    formik1.touched.as_end_time && (
                      <span className="error">
                        {formik1.errors.as_end_time}
                      </span>
                    )}
                </Col>
                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    English Year Level
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_yearlevel}
                    name="la_english_year_level"
                    //value={options_yearlevel.find(option => option.value && formik1.values.la_english_year_level && option.value.toString() === formik1.values.la_english_year_level.toString())}
                    //value={formik1.values.la_english_year_level !== null ? options_yearlevel.find(option => option.value && option.value.toString() === formik1.values.la_english_year_level.toString()) : null}
                    value={options_yearlevel.find(
                      (option) =>
                        option.value &&
                        formik1.values.la_english_year_level &&
                        option.value.toString() ===
                          formik1.values.la_english_year_level.toString()
                    )}
                    //value={formik1.values.la_english_year_level}
                    id="la_english_year_level"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "la_english_year_level",
                          value: selectedOption["value"],
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        la_english_year_level: true,
                      });
                    }}
                    className={
                      formik1.errors.la_english_year_level &&
                      formik1.touched.la_english_year_level
                        ? "input-error"
                        : ""
                    }
                  />
                  {formik1.errors.la_english_year_level &&
                    formik1.touched.la_english_year_level && (
                      <span className="error">
                        {formik1.errors.la_english_year_level}
                      </span>
                    )}
                </Col>

                <Col lg="4" md="6" sm="12" className="position-relative">
                  <h6 className="form-lable required-field">
                    Maths Year Level
                  </h6>
                  <Select
                    menuPlacement="auto"
                    options={options_yearlevel}
                    name="la_maths_year_level"
                    //value={options_yearlevel.find(option => option.value && formik1.values.la_maths_year_level && option.value.toString() === formik1.values.la_maths_year_level.toString())}
                    value={options_yearlevel.find(
                      (option) =>
                        option.value &&
                        formik1.values.la_maths_year_level &&
                        option.value.toString() ===
                          formik1.values.la_maths_year_level.toString()
                    )}
                    // value={formik1.values.la_maths_year_level}
                    id="la_maths_year_level"
                    onChange={(selectedOption) => {
                      const event = {
                        target: {
                          name: "la_maths_year_level",
                          value: selectedOption["value"],
                        },
                      };
                      formik1.handleChange(event);
                    }}
                    onBlur={(event) => {
                      formik1.handleBlur(event);
                      formik1.setTouched({
                        ...formik1.touched,
                        la_maths_year_level: true,
                      });
                    }}
                    className={
                      formik1.errors.la_maths_year_level &&
                      formik1.touched.la_maths_year_level
                        ? "input-error"
                        : ""
                    }
                  />
                  {formik1.errors.la_maths_year_level &&
                    formik1.touched.la_maths_year_level && (
                      <span className="error">
                        {formik1.errors.la_maths_year_level}
                      </span>
                    )}
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <div className="mx-auto d-flex gap-2">
                <Button
                  variant="contained"
                  className="primary-gray"
                  onClick={toggleModal1}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  className="primary-filled "
                  disabled={isSubmittingSlot}
                >
                  Update
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* --------------Page Start--------------- */}

        {/* --------------Section Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className="page-title"> Initial Diagnostics </h5>

            <div role="presentation">
              <Breadcrumbs aria-label="breadcrumb" className="my-breadcrumbs">
                <NavLink underline="hover" color="inherit" to="/calendar">
                  {" "}
                  Home
                </NavLink>

                <Typography color="text.primary">
                  Initial Diagnostics
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center gap-2">
              {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

              <NavLink
                className="primary-filled rounded-10 p-2"
                to="/AddInitialDiagnostics"
              >
                <AddIcon className="btn-icon" /> New Initial Diagnostics
              </NavLink>
            </div>
          </div>
        </div>
        <Card className="my-card p-1 bg-white">
          <Card.Body>
            {/* -------------Content Start--------------- */}

            <Tab.Container activeKey={activeTab}>
              <Row>
                <Col sm={12} className="border-bottom mb-2">
                  <div className=" justify-content-right  float-end">
                    <div className="d-flex gap-2 ">
                      {/* {isActive && ( */}
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          {/* <div class="form-group has-search my-auto mb-2">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control mb-0" onChange={handleFilter}  />
                      </div> */}
                        </Tab.Pane>

                        {/* )} */}

                        {/* {!isActive && ( */}
                        <Tab.Pane eventKey="second">
                          {/* <div class="form-group has-search my-auto mb-2">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control mb-0" onChange={handleFilter_a}  />
                      </div> */}
                        </Tab.Pane>
                        {/* )} */}

                        {/* {!isActive && ( */}
                        <Tab.Pane eventKey="thired">
                          {/* <div class="form-group has-search my-auto mb-2">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" class="form-control mb-0" onChange={handleFilter_l}  />
                      </div> */}
                        </Tab.Pane>
                        {/* )} */}
                      </Tab.Content>
                    </div>
                  </div>
                  <Nav variant="pills" className="mb-3 text-dark ">
                    <div className="d-flex justify-content-between">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          // className="px-4 py-2 text-dark me-2"
                          // onClick={handleClick}
                          className={`px-4 py-2 text-dark me-2 ${
                            activeTab === "first" ? "active" : ""
                          }`}
                          onClick={() => handleClick("first")}
                        >
                          Scheduled
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          // className="px-4 py-2 text-dark"
                          // onClick={handleClick}
                          className={`px-4 py-2 text-dark ${
                            activeTab === "second" ? "active" : ""
                          }`}
                          onClick={() => handleClick("second")}
                        >
                          Accepted
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="thired"
                          className="px-4 py-2 text-dark"
                          // onClick={handleClick}
                          onClick={() => handleClick("thired")}
                        >
                          Lapsed
                        </Nav.Link>
                      </Nav.Item>
                    </div>
                  </Nav>
                </Col>

                <Col sm={12}>
                  <Tab.Content activeKey={activeTab}>
                    <Tab.Pane eventKey="first">
                      {/* <DataTable
                     progressPending={loading} 
                        columns={columns}
                        data={records}
                        Header
                        pagination
                        highlightOnHover
                        onRowClicked={handleRowDiagnosticsEditClick}
                        className='overflow-auto list-cus-h'
                        
                      /> */}
                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            ".MuiDataGrid-cell:focus": {
                              outline: "none",
                            },
                            // pointer cursor on ALL rows
                            "& .MuiDataGrid-row:hover": {
                              cursor: "pointer",
                            },
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          loading={loading}
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "ScheduledList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                disableToolbarButton: true,
                                hideFooter: true,
                                hideToolbar: true,
                                fileName: "LapsedList",
                                orientation: "landscape",
                              },
                              records,
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...records.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          rows={records.map((row, index) => ({
                            ...row,
                            id: `row-${index}`,
                          }))}
                          columns={columns}
                          rowHeight={60}
                          density="compact"
                          onRowClick={(params) =>
                            handleRowDiagnosticsEditClick(params.row)
                          }
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}
                        />
                      </Box>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      {/* <DataTable
                     progressPending={loading} 
                        columns={columns_a}
                        data={records_a}
                        Header
                        pagination
                        highlightOnHover
                        onRowClicked={handleRowDiagnosticsUpdateClick}
                        className='overflow-auto list-cus-h'
                      /> */}
                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            ".MuiDataGrid-cell:focus": {
                              outline: "none",
                            },
                            // pointer cursor on ALL rows
                            "& .MuiDataGrid-row:hover": {
                              cursor: "pointer",
                            },
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          loading={loading}
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "AcceptedList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                disableToolbarButton: true,
                                hideFooter: true,
                                hideToolbar: true,
                                fileName: "LapsedList",
                                orientation: "landscape",
                              },
                              records_a,
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...records_a.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          rows={records_a.map((row, index) => ({
                            ...row,
                            id: `row-${index}`,
                          }))}
                          columns={columns_a}
                          rowHeight={60}
                          density="compact"
                          onRowClick={(params) =>
                            handleRowDiagnosticsUpdateClick(params.row)
                          }
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}
                        />
                      </Box>
                    </Tab.Pane>

                    <Tab.Pane eventKey="thired">
                      {/* <DataTable
                     progressPending={loading} 
                        columns={columns_l}
                        data={records_d}
                        Header
                        pagination
                        highlightOnHover
                        onRowClicked={handleRowDiagnosticsUpdateClick}
                        className='overflow-auto list-cus-h'
                      /> */}
                      <Box
                        sx={{
                          height: "fit-content",
                          width: "100%",
                          "& .actions": {
                            color: "text.secondary",
                          },
                          "& .textPrimary": {
                            color: "text.primary",
                          },
                          "& .super-app-theme--header": {
                            backgroundColor: "#f4f4f4",
                          },
                        }}
                        style={{ height: "100%", width: "100%" }}
                      >
                        <DataGrid
                          sx={{
                            // disable cell selection style
                            ".MuiDataGrid-cell:focus": {
                              outline: "none",
                            },
                            // pointer cursor on ALL rows
                            "& .MuiDataGrid-row:hover": {
                              cursor: "pointer",
                            },
                          }}
                          //sx={{ border: "none", m: 2 }}
                          autoHeight
                          loading={loading}
                          slots={{
                            loadingOverlay: LinearProgress,
                            //noRowsOverlay: CustomNoRowsOverlay,
                            toolbar: GridToolbar,
                            // toolbar: EditToolbar,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { fileName: "LapsedList" },
                              printOptions: {
                                pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`,
                                disableToolbarButton: true,
                                hideFooter: true,
                                hideToolbar: true,
                                fileName: "LapsedList",
                                orientation: "landscape",
                              },
                              records_d,
                              showQuickFilter: true,
                            },
                          }}
                          initialState={{
                            ...records_d.initialState,
                            pagination: { paginationModel: { pageSize: 10 } },
                          }}
                          pageSizeOptions={[10, 25, 50, 100]}
                          rows={records_d.map((row, index) => ({
                            ...row,
                            id: `row-${index}`,
                          }))}
                          columns={columns_l}
                          rowHeight={60}
                          density="compact"
                          onRowClick={(params) =>
                            handleRowDiagnosticsUpdateClick(params.row)
                          }
                          disableRowSelectionOnClick
                          showCellVerticalBorder={true}
                          showColumnVerticalBorder={true}
                        />
                      </Box>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card.Body>
        </Card>

        {/* --------------Section End--------------- */}

        {/* --------------Page End--------------- */}
      </div>
    </>
  );
}

export default Diagnostics;
