/*
Purpose: list of SUBJECT with use datatable
Developer: BY KTH
Date:24/08/2023 
Project: TCMS
*/


import React from 'react';
import { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from 'react-router-dom';

import { Row, Col, Card, Form, Modal, Button } from 'react-bootstrap';


import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';


import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";

import { useFormik } from "formik";
import * as yup from "yup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  security_key, BASE_URL
} from "../global";

const ListForm = () => {



  const [records, setRecords] = useState([]);


  const [data, setItems] = useState([]);


  const [loading, setLoading] = useState(true);


  const [IsDownload, setIsDownload] = useState(false);



  //Api call start


  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);
  const [showDelete, setDeleteShow] = useState(false);
  const [data_delete, setDeleteLabelData] = useState([]);
  const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


  const handleDeleteClick = (row) => {

    const updatedData = { ...row };
    setDeleteLabelData(updatedData);

    setDeleteShow(true);
  };


  const [showVisible, setVisibleShow] = useState(false);
  const [data_visible, setVisibleLabelData] = useState([]);
  const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);


  const handleVisibleClick = (row) => {
    //(row);

    const updatedData = { ...row };
    setVisibleLabelData(updatedData);

    setVisibleShow(true);
  };
  const handleNotPdfClick = () => {
    toast.error("Sorry! Not download deactive forms", {
      autoClose: 3000, // 3 seconds in this example
    });
  };


  const handlePdfClick = async (fileName) => {
    

    setIsDownload(true);
    // try {
    //   // Create a FormData object to send the form data, including the image file
    //   const formData = new FormData();
    //   formData.append("mode", "download_s3_file");
    //   formData.append("file", fileName); // Append the image file

    //   // Make an API request with the FormData object
    //   const response = await axios.post(BASE_URL + 'API/upload/index.php', formData, {
    //     responseType: 'blob', // Specify that the response is binary data (blob)
    //   });

    //     // Extract the file extension from the file name
    //     const fileExtension = fileName.split('.').pop().toLowerCase();

    //     // Determine the content type based on the file extension
    //     let contentType = 'application/octet-stream'; // Default content type
    //     if (fileExtension === 'pdf') {
    //       contentType = 'application/pdf';
    //     } else if (fileExtension === 'docx') {
    //       contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    //     } else if (fileExtension === 'txt') {
    //       contentType = 'text/plain';
    //     } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
    //       contentType = 'image/jpeg';
    //     } else if (fileExtension === 'png') {
    //       contentType = 'image/png';
    //     } // Add more cases for other file types

    //     // Create a Blob from the binary response
    //     const blob = new Blob([response.data], { type: contentType });

    //   // Create a data URL from the Blob
    //   const dataUrl = URL.createObjectURL(blob);

    //   // Open a new tab with an iframe to display the PDF content
    //   const newTab = window.open();
    //   newTab.document.write('<iframe src="' + dataUrl + '" style="width:100%; height:100vh;"></iframe>');

    //  
    // } catch (error) {
    //   console.error('Error opening file:', error);
    // }


    try {
      // Create a FormData object to send the form data, including the image file
      const formData = new FormData();
      formData.append("mode", "download_s3_file");
      formData.append("file", fileName); // Append the image file

      // Make an API request with the FormData object
      const response = await axios.post(BASE_URL + 'API/upload/index.php', formData, {
        responseType: 'blob', // Specify that the response is binary data (blob)
      });

      // Create a Blob from the binary response
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // Create a link element
      const link = document.createElement('a');

      // Create a Blob URL for the downloaded content
      const blobUrl = window.URL.createObjectURL(blob);

      // Set the link's href attribute to the Blob URL
      link.href = blobUrl;

      // Set the download attribute with the desired file name
      link.download = fileName;

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);

      // Release the Blob URL resources
      window.URL.revokeObjectURL(blobUrl);

     
      setIsDownload(false);
    } catch (error) {
      console.error('Error downloading file:', error);
      setIsDownload(false);
    }



  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.post(BASE_URL + 'API/api_index.php', {
        security_key: security_key,
        mode: "getAllFormByCentre",
        fk_cent_key: sessionStorage.getItem('key'),
        fk_country_id: sessionStorage.getItem('country_code')
      });
      
      if (response.data.success === true) {
        setRecords(response.data.data);
        setItems(response.data.data);
        setLoading(false);


      } else {
        //alert message provide
        setLoading(false);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }


    // try {
    //     const response_centre = await axios.post(BASE_URL+'API/api_index.php', {
    //       security_key: security_key,
    //       mode: 'getAllCenterGroupByCentre'
    //     });
    //     
    //     if(response_centre.data.success===true){
    //         const formattedOptions = response_centre.data.data.map(option => ({
    //             value: option.pk_cent_key,
    //             label: option.cent_name
    //           }));
    //           // formattedOptions.unshift({
    //           //   value: '',
    //           //   label: '--Select--'
    //           // });
    //           setOptions(formattedOptions);
    //     }else{
    //       //alert message provide
    //       setLoading(false);
    //     }


    // } catch (error) {
    //     console.error('Error fetching data:', error);
    // }

  };


  const handleActiveDisableClick = async () => {
    
    toast.error("Sorry! This form can only be deactivated by the super admin.", {
      autoClose: 3000, // 3 seconds in this example
    });
};
const handleDeActiveDisableClick = async () => {
    
  toast.error("Sorry! This form can only be activated by the super admin.", {
    autoClose: 3000, // 3 seconds in this example
  });
};

  // Sample columns configuration
  const columns = [

    {

      headerName: 'Form Name',
      field: "Form Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.f_name == null || params.row.f_name == '' ? '-' : params.row.f_name}`;
      },


    },
    // {
    //   name: 'Form Type',
    //   selector: row => row.f_type=='1' ? 'All Country' : row.f_type=='2' ? 'Country' : row.f_type=='3' ? 'Centre' : '-',

    //   sortable: true
    // },
    {
      headerName: 'Centre Name',
      field: "Centre Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.cent_name == null || params.row.cent_name == '' ? '-' : params.row.cent_name}`;
      },


    }, {

      headerName: 'Created By',
      field: "Created By",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.f_user_type != null ? parseInt(params.row.f_user_type) == 1 ? 'Centre Admin' : 'Super Admin' : '-'}`;
      },

    },
    // {
    //     name: 'Country',
    //     selector: row =>row.country_name == null ||  row.country_name == '' ? '-' : row.country_name,
    //     sortable: true
    // },
    // },{
    //   name: 'Form Link',
    //   selector: row =>row.f_link == null ||  row.f_link == '' ? '-' : row.f_link,
    //   sortable: true
    // },
    {
      field: "Form",
      type: "actions",
      headerName: "Form",
      headerClassName: "super-app-theme--header",
      width: 250,
      renderCell: (params) => {
        const row = params.row;
        if (row.f_image != null && row.f_image != '') { // Replace this condition with your actual condition
          return (
            // <img src={BASE_URL + 'API/upload/image/'+row.f_image} alt='image' width="50" height="50" />
            parseInt(row.f_visibility) == 1 ? <NavLink underline="hover" color="inherit" class="my-2" disabled={IsDownload} onClick={() => handlePdfClick(row.f_image)}>{row.f_image}</NavLink> : <NavLink underline="hover" color="inherit" class="my-2" onClick={() => handleNotPdfClick()}>{row.f_image}</NavLink>
            //  <p class="my-2" onClick={() => handlePdfClick(row.f_image)}>{row.f_image}</p>
          );
        }
        else {
          return (
            <p>-</p>
          );
        }
      }
    }, {
      field: "Action",
      type: "actions",
      headerName: "Action",
      headerClassName: "super-app-theme--header",
      width: 250,
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.f_visibility)==1? 'Active' : 'Deactive'}`;
      },
      renderCell: (params) => {
        const row = params.row;

        if (row.f_user_type != null && parseInt(row.f_user_type) == 1) {


          if (parseInt(row.f_visibility) == 1) {
            return (
              <div className='d-flex justify-content-center'>
                <span className='active-btn my-auto cursor-pointer' onClick={() => handleDeleteClick(row)} >
                  <DoneIcon className="icon-blue cursor-pointer btn-icon me-1" />
                  Active
                </span>
                {/* <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="primary-blue  btnmr-2 my-2" >Visible</Buttonmui> */}
              </div>
            );
          } else {
            return (
              <div className='d-flex justify-content-center'>
                <span className='deactive-btn my-auto cursor-pointer' onClick={() => handleVisibleClick(row)} >
                  <CloseIcon className="cursor-pointer btn-icon me-1" />
                  Deactive
                </span>
                {/* <Buttonmui variant="outlined-red" onClick={() => handleVisibleClick(row)}  className="filled-danger" >Not Visible</Buttonmui> */}
              </div>
            );
          }
        } else {


          if (parseInt(row.f_visibility) == 1) {
            return (
              <div className='d-flex justify-content-center'>
                <span className='active-btn my-auto' onClick={()=> handleActiveDisableClick()}>
                  <DoneIcon className="icon-blue cursor-pointer btn-icon me-1" />
                  <del>Active</del>
                </span>
                {/* <Buttonmui variant="outlined-red" onClick={() => handleDeleteClick(row)} className="primary-blue  btnmr-2 my-2" >Visible</Buttonmui> */}
              </div>
            );
          } else {
            return (
              <div className='d-flex justify-content-center'>
                <span className='deactive-btn my-auto' onClick={()=> handleDeActiveDisableClick()}>
                  <CloseIcon className="cursor-pointer btn-icon me-1" />
                  <del>Deactive</del>
                </span>
                {/* <Buttonmui variant="outlined-red" onClick={() => handleVisibleClick(row)}  className="filled-danger" >Not Visible</Buttonmui> */}
              </div>
            );
          }

        }

        

      }
    },

  ];



  const [modalShowAdd, setModalShowAdd] = useState(false);



  const basicSchema = yup.object().shape({

    f_name: yup.string().required("Form name is required.").min(2, "Minimum 2 characters is required.").max(250, "Maximum 250 characters is required.").matches(/^[^,\s][A-Za-z0-9.!@#$%-/^&*()_+,\s]*[^,\s]$/, "Avoid start & end with a space or comma").matches(/^[^'"\\]*(?:[^'"\\][^'"\\]*)*$/,"Avoid apostrophe & quotes."),
    //f_type: yup.mixed().required("Form type is required."),  
    f_image: yup.mixed().required("Form file is required."),
    //f_link: yup.mixed().required("Form link is required."),  


    //   ban_image: yup.mixed().test('at-least-one', 'Form image or Banner link is required..', function (value) {
    //     const { ban_link } = this.parent;
    //     if (!value && !ban_link) {
    //         return this.createError({
    //             path: 'ban_image',
    //             message: 'Banner image or Banner link is required..',
    //         });
    //     }
    //     return true;
    // }),

    // ban_link: yup.mixed().test('at-least-one', 'Banner image or Banner link is required..', function (value) {
    //     const { ban_image } = this.parent;
    //     if (!value && !ban_image) {
    //         return this.createError({
    //             path: 'ban_link',
    //             message: 'Banner image or Banner link  is required..',
    //         });
    //     }
    //     return true;
    // }),

    // country_id: yup.array().when('f_type', {
    //   is: (FType) => parseInt(FType) === 2,
    //   then: (schema) => schema.test({
    //     test: (value) => value.length > 0, // Check if the array is not empty
    //     message: 'Country is required.', // Display this message if the array is not empty
    //   }),
    // }),

    // fk_cent_key: yup.array().when('f_type', {
    //   is: (FType) => parseInt(FType) === 3,
    //   then: (schema) => schema.test({
    //     test: (value) => value.length > 0, // Check if the array is not empty
    //     message: 'Centre is required.', // Display this message if the array is not empty
    //   }),
    // }),

  });

  const initialValues = {
    /*tbl_year_level*/

    f_name: '',
    f_image: '',
    //f_link: '',
    f_type: 3,
    country_id: 0,
    fk_cent_key: sessionStorage.getItem('key'),

    created_by: sessionStorage.getItem('key'),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: basicSchema,
    enableReinitialize: true,
    // onSubmit,
    onSubmit: async (formValues) => {
      setIsSubmitting(true);


      try {
        const response = await axios.post(BASE_URL + "API/api_index.php", {
          security_key: security_key,
          mode: "CreateFormCentre",
          ...formValues,
        });

        if (response.data.success === true) {
          // Reset the form
          //formRef.current.reset();
          if (response.data.data === 1) {
            toast.success("Success! Your form was submitted", {
              autoClose: 3000, // 3 seconds in this example
            });
            fetchData();
            // setShow(false);
            setModalShowAdd(false);
            //setModalShowNotyfy(true);

            resetForm();
            

            setIsSubmitting(false); // Re-enable the submit button
          } else {
            toast.error("Failure! Unable to create forms. Try again later", {
              autoClose: 3000, // 3 seconds in this example
            });

            setIsSubmitting(false); // Re-enable the submit button
          }

        } else {

          toast.error("Failure! Unable to create forms. Try again later", {
            autoClose: 3000, // 3 seconds in this example
          });

          setIsSubmitting(false); // Re-enable the submit button

        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failure! Unable to create forms. Try again later", {
          autoClose: 3000, // 3 seconds in this example
        });

        setIsSubmitting(false); // Re-enable the submit button

      } finally {
        setIsSubmitting(false); // Re-enable the submit button

      }

    },
  });

  // Now, you can set variables as needed:
  const values = formik.values;
  const handleBlur = formik.handleBlur;
  const touched = formik.touched;
  const handleChange = formik.handleChange;
  const errors = formik.errors;
  const handleSubmit = formik.handleSubmit;
  const setTouched = formik.setTouched;
  const resetForm = formik.resetForm;
  const setValues = formik.setValues;
  const handleReset = formik.handleReset;
  const fileInputRef = useRef(null);
  
  const resetFormFields = (resetForm, setValues, initialValues) => {
    resetForm(); // Reset Formik-controlled fields
    
  };


  function onSubmitUserDelete() {
    setIsUserDeleteSubmitting(true);

    const responseuserdelete = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "DeleteForm",
      pk_f_key: data_delete.pk_f_key
    }).then((responseuserdelete) => {

      if (responseuserdelete.data.data == 1) {

        setDeleteShow(false)
        // Handle the successful response here
        toast.success("Success! Form has been deactivated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsUserDeleteSubmitting(false);

      } else {
        toast.error("Failure! Unable to deactive form. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to deactive form. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }



  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL + "API/api_index.php", {

      security_key: security_key,
      fk_cent_key: sessionStorage.getItem('key'),
      mode: "VisibleForm",
      pk_f_key: data_visible.pk_f_key
    }).then((responseVisible) => {

      if (responseVisible.data.data == 1) {

        setVisibleShow(false)
        // Handle the successful response here
        toast.success("Success! Form has been activated.", {
          autoClose: 3000, // 3 seconds in this example
        });

        fetchData();

        setIsVisibleSubmitting(false);

      } else {
        toast.error("Failure! Unable to active form. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      }


    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to active form. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }


 


  return (
    <>
      <ToastContainer position="top-center" />


      <Modal show={showDelete} variant="danger">

        <Modal.Header>Form</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want change to deactivate this Form? </p>

        </Modal.Body>
        <Modal.Footer className='pt-0'>
          <div className="mx-auto d-flex gap-2" >

            <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
            <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitUserDelete()} >Yes</Button>
          </div>

        </Modal.Footer>

      </Modal>


      <Modal show={showVisible} variant="danger">

        <Modal.Header>Form</Modal.Header>

        <Modal.Body>
          <p>Are you sure? You want change to activate this Form? </p>

        </Modal.Body>
        <Modal.Footer className='pt-0'>
          <div className="mx-auto d-flex gap-2" >

            <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
            <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
          </div>

        </Modal.Footer>

      </Modal>

      <div className="page-wrapper">


        <Modal
          show={modalShowAdd} onHide={() => setModalShowAdd(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Form autoComplete="off" onSubmit={handleSubmit} ref={formRef}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <h5 className='page-title'> Add Form </h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>

              {/* --------------Date--------------- */}
              <Row>
                <Col lg="6" md="6" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Form Name</h6>
                  <Form.Control
                    type="text"
                    value={values.f_name}
                    onChange={handleChange}

                    onBlur={handleBlur}
                    id="f_name"
                    className={errors.f_name && touched.f_name ? "input-error" : ""}
                  />
                  {errors.f_name && touched.f_name && <p className="error">{errors.f_name}</p>}
                </Col>
                {/* --------------Student phone--------------- */}
                <Col lg="6" md="6" sm="12" className='position-relative'>
                  <h6 className="form-lable required-field">Form File</h6>
                  {/* {!imageUrl && ( */}
                  <Form.Control type="file"
                    name="f_image"
                    id="f_image"
                    accept=".pdf, .docx, .txt"
                    onChange={(event) => {
                      setTouched({ ...touched, f_image: true });
                      if (event.target.files.length > 0) {
                        const fileName = event.target.files[0];
                        async function handleFileUpload(fileName) {
                          try {
                            // Create a FormData object to send the form data, including the image file
                            const formData = new FormData();
                            formData.append("mode", "file_s3_upload_document");
                            formData.append("module", "form");
                            formData.append("document", fileName); // Append the image file

                            // Make an API request here with the FormData object
                            const response_file = await axios.post(BASE_URL + 'API/upload/index.php', formData);

                            // Handle the API response here
                            if (response_file.data.success === true) {
                             
                              handleChange({ target: { name: "f_image", value: response_file.data.data } });
                            } else {
                              handleChange({ target: { name: "f_image", value: null } }); // Clear the input value
                              fileInputRef.current.value = null;
                            }

                            // You can also perform further actions based on the API response
                            // For example, show a success message or redirect to another page.
                          } catch (error) {
                          
                            handleChange({ target: { name: "f_image", value: null } }); // Clear the input value
                            fileInputRef.current.value = null;
                          }
                        }

                        // Call the async function when needed, passing the fileName as an argument
                        handleFileUpload(fileName);


                      } else {
                        // handleChange(event);
                       
                        handleChange({ target: { name: "f_image", value: null } }); // Clear the input value
                        fileInputRef.current.value = null;
                      }
                    }}
                    ref={fileInputRef}
                    className={errors.f_image && touched.f_image ? "input-error" : ""}
                  />

                  {/* // )}
                        // {imageUrl && (
                        //     <Col lg="4" md="6" sm="12" className='d-flex justify-content-between'>
                        //         <img src={imageUrl} alt='image' width="50" height="50" />
                        //         <div className="text-end">
                        //             <Button variant="contained" className="primary-filled  btn btn-contained" onClick={handelRemove}>X</Button>
                        //         </div>
                        //     </Col>
                        // )} */}
                  {errors.f_image && touched.f_image && <p className="error">{errors.f_image}</p>}
                </Col>

                {/* <Col lg="6" md="6" sm="12" className='position-relative'>
                        <h6 className="form-lable required-field">Form Link</h6>
                        <Form.Control type="text"
                            name="f_link"
                            id="f_link"
                            value={values.f_link}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.f_link && touched.f_link ? "input-error" : ""}

                        />
                        {errors.f_link && touched.f_link && <p className="error">{errors.f_link}</p>}

                    </Col> */}
                {/* 
                    <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Form Type</h6>
                 <Select menuPlacement="auto" 
                    options={options_banner_type}
                    name="f_type"
                    value={values.f_type['value']}
                    id="f_type"
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "f_type",
                                value: selectedOption["value"],
                            },
                        };

                        if (selectedOption["value"] === 1) {
                            const allCountries = options_countries.map(country => country.value);
                            const countryEvent = {
                                target: {
                                    name: "country_id",
                                    value: allCountries,
                                },
                            };
                            handleChange(countryEvent);
                        }
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, f_type: true });
                    }}
                    className={errors.f_type && touched.f_type ? "input-error" : ""}
                />
                {errors.f_type && touched.f_type && <p className="error">{errors.f_type}</p>}
                </Col>
                {parseInt(values.f_type) === 2 && (
                  <> <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Country</h6>
                 <Select menuPlacement="auto" 
                    options={options_countries}
                    name="country_id"
                    value={values.country_id}
                    id="country_id"
                    isMulti
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "country_id",
                                value: selectedOption,
                            },
                        };
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, country_id: true });
                    }}
                    className={errors.country_id && touched.country_id ? "input-error" : ""}
                />
                {errors.country_id && touched.country_id && <span className="error">{errors.country_id}</span>}
                </Col>
                </> )}
                {parseInt(values.f_type) === 3 && (
                    <>
                <Col lg="6" md="6" sm="12" className='position-relative'>
                <h6 className="form-label required-field">Center</h6>
                 <Select menuPlacement="auto" 
                    options={option_centre}
                    name="fk_cent_key"
                    value={values.fk_cent_key}
                    id="fk_cent_key"
                    isMulti
                    onChange={(selectedOption) => {
                        const event = {
                            target: {
                                name: "fk_cent_key",
                                value: selectedOption,
                            },
                        };
                        handleChange(event);
                    }}
                    onBlur={(event) => {
                    handleBlur(event);
                    setTouched({ ...touched, fk_cent_key: true });
                    }}
                    className={errors.fk_cent_key && touched.fk_cent_key ? "input-error" : ""}
                />
                {errors.fk_cent_key && touched.fk_cent_key && <span className="error">{errors.fk_cent_key}</span>}
                </Col>
                </> )} */}





              </Row>
            </Modal.Body>

            <Modal.Footer>

              <div className="mx-auto d-flex gap-2" >

                <Button variant="contained" className="primary-gray" onClick={() => setModalShowAdd(false)}>Cancel</Button>
                <Button variant="contained" type='submit' className="primary-filled " disabled={isSubmitting}>Save</Button>
              </div>

            </Modal.Footer>
          </Form>

        </Modal>




        {/* --------------Page Start--------------- */}

        <div className="mb-3 d-flex justify-content-between align-items-center">
          <div>
            <h5 className='page-title'>Forms</h5>

            <div role="presentation" >
              <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                <NavLink underline="hover" color="inherit" to='/calendar'> Home</NavLink>


                <Typography color="text.primary">Forms</Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className='d-flex gap-2'>
            {/*<div>
                            <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                                Back
                             </Button>
                         </div>*/}

            <div className=''>

              <Button variant="contained" className="primary-filled rounded-10 p-2" onClick={() => setModalShowAdd(true)} >
                Add Form
              </Button>
              {/* <Button variant='primary' className='me-2' onClick={() => setModalShow2(true)}>
                    Edit Enrolment
                    </Button> */}

            </div>

          </div>
        </div>


        <Card className='my-card p-1 bg-white'>

          <Card.Body>

            {/* -------------Content Start--------------- */}


            <Row>
              
              <Col sm={12}>





                <Box
                  sx={{
                    height: "fit-content",
                    width: "100%",
                    "& .actions": {
                      color: "text.secondary",
                    },
                    "& .textPrimary": {
                      color: "text.primary",
                    },
                    "& .super-app-theme--header": {
                      backgroundColor: "#e3e6ee",
                    },
                  }}
                  style={{ height: '100%', width: "100%" }}
                >
                 <DataGrid
  
                    //sx={{ border: "none", m: 2 }}
                    autoHeight
                    slots={{
                      loadingOverlay: LinearProgress,
                      //noRowsOverlay: CustomNoRowsOverlay,
                      toolbar: GridToolbar,
                      // toolbar: EditToolbar,
                    }}
                    slotProps={{
                      toolbar: {
                        csvOptions: { fileName: "FormList" },
                        printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "FormList" },
                        records,
                        showQuickFilter: true
                      },


                    }}
                    initialState={{
                      ...records.initialState,
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    showQuickFilter={true}

                    pageSizeOptions={[10, 25, 50, 100]}

                    loading={loading}
                    rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                    columns={columns}
                    rowHeight={60} density="compact"

                    disableRowSelectionOnClick
                    showCellVerticalBorder={true}
                    showColumnVerticalBorder={true}

                  />


                </Box>


              </Col>
            </Row>


          </Card.Body>

        </Card>

        {/* --------------Section End--------------- */}




        {/* --------------Page End--------------- */}


        {/* <AddEntrolmentModal
          show={modalShow1}
          onHide={() => setModalShow1(false)}
        /> */}
        {/* <EditEntrolmentModal
          show={modalShow2}
          onHide={() => setModalShow2(false)}
        /> */}


      </div>


    </>
  );


};

export default ListForm;
