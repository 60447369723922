import React, { useState, useEffect } from 'react';
import axios from "axios";
//import { createBrowserHistory } from "history";
import {
  security_key, BASE_URL
} from "./global";
import { Route, HashRouter as Router, Routes, useNavigate } from "react-router-dom";

import Header from './pages/Header';
import AdminHeader from './pages/AdminHeader';
import ExpiredCheck from './pages/ExpiredCheck';
import Login from './pages/Login';
import AdminLogin from './pages/AdminLogin';

/* Group of Student Components */
import AddStudent from './pages/AddStudent';
import ListStudent from './pages/Student';
import EditStudent from './pages/EditStudent';
import ViewStudent from './pages/ViewStudent';
import StudentSearch from './pages/StudentSearch';


/* Group of Lead Components */
import AddLead from './pages/AddLead';
import ListLead from './pages/ListLead';
import ViewLead from './pages/ViewLead';

/* Group of Session Components */
import AddSession from './pages/AddSession';
import GenerateSession from './pages/GenerateSession';
import ListSession from './pages/ListSession';
import EditSession from './pages/EditSession';



/* Group of Entrolment Components */
import ListEntrolments from './pages/ListEntrolments';
import ListXeroEntry from './pages/ListXeroEntry';
import ListQBEntry from './pages/ListQBEntry';

import ViewEntrolments from './pages/ViewEntrolments';

import ViewUnEntrolments from './pages/ViewUnEntrolments';

import ListReceipt from './pages/ListReceipt';
import ListViewFees from './pages/ListViewFees';

/* Group of Holidays Components */
import AddHolidays from './pages/AddHolidays';
import ListHolidays from './pages/ListHolidays';
import EditHolidays from './pages/EditHolidays';


/* Group of Staff Components */
import ListStaff from './pages/ListStaff';
import AddStaff from './pages/AddStaff';
import EditStaff from './pages/EditStaff';


import Calendar from './pages/Calendar';


//Student Report 

// import ListAttendanceReport from "./pages/ListAttendanceReport";
// import ListAttendenceSummaryReport from "./pages/ListAttendenceSummaryReport";
// import ListBirthdayReport from "./pages/ListBirthdayReport";
// import ListDiagnosticsReport from "./pages/ListDiagnosticsReport";
// import ListInvoiceReport from "./pages/ListInvoiceReport";
// import ListMyPointsReport from "./pages/ListMyPointsReport";
// import ListStudentParticularsReport from "./pages/ListStudentParticularsReport";
// import ListStudentTestResultReport from "./pages/ListStudentTestResultReport";




//BY  M.KS

// //admin
import SuperAdminList from "./pages/admin-list";
import AddAdmin from "./pages/Add-admin";
import EditAdmin from "./pages/Edit-admin";
import ReportRevenue from "./pages/Report-revenue";
import ReportStuEnroll from "./pages/Report-stu-enroll";
import Subject from "./pages/Subject";
import AddSubject from "./pages/AddSubject";
import EditSubject from "./pages/EditSubject";

import ListBanner from "./pages/ListBanner";
import ListForm from "./pages/ListForm";

// //Diagnostics
import Diagnostics from "./pages/Diagnostics";
import AddDiagnostics from "./pages/Add-diagnostics";
import EditDiagnostics from "./pages/Edit-diagnostics";
import AddInitialDiagnostics from "./pages/AddInitialDiagnostics";

// //Fees
import Fees from "./pages/Fees";
import AddFees from "./pages/Add-pricing";
import AddDiscount from "./pages/Add-discount";
import EditFees from "./pages/Edit-pricing";
import EditDiscount from "./pages/Edit-discount";


//Report
import Reports from './pages/Report';

//Financial Reports
import DailyPGPayments from "./pages/Report-finance-daily_pg_payments";
import DailyMerchantStatement from "./pages/Report-finance-daily_merchant_statement";
import Debtor from "./pages/Report-finance-debtor";
import Deposits from "./pages/Report-finance-deposits";
import Timesheet from "./pages/Report-finance-timesheet";
import MonthlyRevenue from "./pages/Report-finance-monthly_revenue";

//Student Reports
import Assessment from "./pages/Report-student-assessment";
import Coins from "./pages/Report-student-coins";
import LoginRp from "./pages/Report-student-login";
import ContactList from "./pages/Report-student-contact_list";

//Centre Management Reports
import Birthday from "./pages/Report-centre-birthday";
import MissedSessions from "./pages/Report-centre-missed_sessions";
import EntrolmentStatistics from "./pages/Report-centre-entrolment_statistics";
import TeacherRatio from "./pages/Report-centre-student_teacher_ratio";
import CentreUnenrollment from "./pages/Report-centre-unenrollment";

import ListSlot from "./pages/ListSlot";



// //Report
// import Report from "./pages/Report";
// import ReportBank from "./pages/Report-bank";
// import ReportDailyPayment from "./pages/Report-daily-payment";
// import ReportDebtor from "./pages/Report-debtor";
// import ReportDeposit from "./pages/Report-deposit";
// import ReportRevenue from "./pages/Report-revenue";
// import ReportExpence from "./pages/Report-expence";
// import ReportStatement from "./pages/Report-statement";
// import ReportMonthlyPayment from "./pages/Report-monthly-payment";
// import ReportOutCollection from "./pages/Report-out-collection";
// import ReportPgScheduled from "./pages/Report-pg-scheduled";
// import ReportPaymentDue from "./pages/Report-payment-due";
// import ReportTimeSheet from "./pages/Report-time-sheet";
// import ReportWeekFees from "./pages/Report-week-fees";


//BY  P.KS
//Centre Management Report 
// import AttendanceReports from "./pages/reports/AttendanceReports";
// import Defferedlessons from "./pages/reports/Defferedlessons";
// import LoginCorrection from "./pages/reports/LoginCorrection";
// import StudentPertutor from "./pages/reports/StudentPertutor";
// import StudentPoints from "./pages/reports/StudentPoints";
// import StudyDurations from "./pages/reports/StudyDurations";
// import TutorPerformance from "./pages/reports/TutorPerformance";
// import UnenrolledReasons from "./pages/reports/UnenrolledReasons";
// import Unenrolled from "./pages/reports/Unenrolled";

import AddCenterInfo from './pages/AddCenterInfo';


/* Group of Tutor Components */
import ListTutor from './pages/ListTutor';
import AddTutor from './pages/AddTutor';
import EditTutor from './pages/EditTutor';

/* Group of Session Time Components */
import ListSessionTime from './pages/ListSessionTime';
import AddSessionTime from './pages/AddSessionTime';
import AddSessionTimeDemo from './pages/AddSessionTimeDemo';
import AddHolidaySessionTime from './pages/AddHolidaySessionTime';
import EditSessionTime from './pages/EditSessionTime';

import QuickBookApi from './pages/QuickBookApi';
import XeroApi from './pages/XeroApi';

import StripeApi from './pages/StripeApi';


import ListYearLevel from './pages/ListYearLevel';


import Sidebar from './pages/sidebar';
import AdminSidebar from './pages/adminsidebar';


import WhatsApp from './pages/WhatsApp';


import ListUser from './pages/ListUser';
import PageNotFound from "./pages/404Page";
import Codecheck from './pages/Codecheck';


import ListCentreForm from './pages/ListCentreForm';

import ListLessonActivity from './pages/ListLessonActivity';
import ListParent from './pages/ListParent';
import ListParentStudent from './pages/ListParentStudent';
import ListContent from './pages/ListContent';
import ListDiagnosticType from './pages/ListDiagnosticType';
import ListDiagnosticMaster from './pages/ListDiagnosticMaster';
import ListTutorTimeSheet from './pages/ListTutorTimeSheet';
import ListTutorPayment from './pages/ListTutorPayment';

import ListStudentAttendance  from './pages/ListStudentAttendance';
import ListScheduledMaintenance  from './pages/ListScheduledMaintenance';

//Admin Report
import AdminReport from './pages/AdminReport';
//Network
import AdminReportEnrolmentStatistics from './pages/AdminReportEnrolmentStatistics';
import AdminReportInitialAssessments from './pages/AdminReportInitialAssessments';
import AdminReportUnenrollment from './pages/AdminReportUnenrollment';
import AdminReportStudentAppUsage from './pages/AdminReportStudentAppUsage';
import AdminReportStudentSession from './pages/AdminReportStudentSession';
import AdminReportParentAppUsage from './pages/AdminReportParentAppUsage';

import AdminFinanceNetworkRevenue from './pages/AdminFinanceNetworkRevenue';
import AdminFinanceNettRevenue from './pages/AdminFinanceNettRevenue';
import AdminFinanceRefunds from './pages/AdminFinanceRefunds';
import AdminFinanceDeposits from './pages/AdminFinanceDeposits';
import AdminFinanceRegistrationFees from './pages/AdminFinanceRegistrationFees';

import AdminPGCommission from './pages/AdminPGCommission';
import AdminPaymentFlows from './pages/AdminPaymentFlows';
import AdminFailedPayments from './pages/AdminFailedPayments';
import AdminDisputes from './pages/AdminDisputes';


import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import moment from 'moment-timezone';

moment.tz.setDefault("Asia/Calcutta");



function AdminRoute() {
  
  const navigate = useNavigate();
  
  const [isOpen, setIsOpen] = useState(false);


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [message, setMessage] = useState('');

    const handleSearchChange = event => {
      setMessage(event.target.value);
  
     // console.log('value is:', event.target.value);
    };

  const UserType = sessionStorage.getItem('is_admin');
  useEffect(() => {
      if (UserType == "1" ) {
        document.title = 'TCMS SuperAdmin';
        
        axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          pk_sa_key: sessionStorage.getItem('key'),
          user_type: sessionStorage.getItem('user_type'),
          pk_sa_id: sessionStorage.getItem('user_id'),
          mode: "CheckSuperAdminDetails"
        }).then(responseAdmin => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              if (responseAdmin.data.success === true) {
                if (responseAdmin.data.data.length > 0) {
                      if (responseAdmin.data.data[0]['pk_sa_key'] !== '') {
                        document.title = 'TCMS SuperAdmin';
                        // sessionStorage.setItem('key', response.data.data[0]['pk_sa_key']);
                        // sessionStorage.setItem('is_admin', '1');
                        // sessionStorage.setItem('center_name', 'Admin');
                        // sessionStorage.setItem('first_name', response.data.data[0]['sa_first_name']);
                        // sessionStorage.setItem('sur_name', response.data.data[0]['sa_last_name']);

                        // //0-super admin
                        // //1-Center super admin and admin.

                        // sessionStorage.setItem('user_type', response.data.data[0]['user_type']);
                       

                    } else {
                      sessionStorage.clear();
                      navigate("/admin/Login");
                      document.title = 'TCMS SuperAdmin';
                    }

                }else{
                  sessionStorage.clear();
                  navigate("/admin/Login");
                  document.title = 'TCMS SuperAdmin';
                }
              }else{
                sessionStorage.clear();
                navigate("/admin/Login");
                document.title = 'TCMS SuperAdmin';
              }
          })
          .catch(error => {
              console.error('Error fetching options from API: ', error);
              sessionStorage.clear();
              navigate("/admin/Login");
              document.title = 'TCMS SuperAdmin';
          });
      }
      else{
        sessionStorage.clear();
        navigate("/admin/Login");
      }
     

  }, [navigate, UserType]);


  
   if(UserType == 1) {
    return (<>

          <AdminSidebar isOpen={!isOpen} toggleSidebar={toggleSidebar} />

          <div className='page-content' style={{
          flex: 1,
          padding: '16px',
          marginLeft: !isOpen ? '240px' : '0', // Adjust the margin when the sidebar is open
          transition: 'margin-left 0.3s ease',
        }}>
           <AdminHeader isOpen={!isOpen} toggleSidebar={toggleSidebar} />

          <Routes >
   
            <Route path="/*" element={<PageNotFound />} />
            
            <Route path="/admin-list" element={<SuperAdminList />} />
            <Route path="/Add-admin" element={<AddAdmin />} />
            <Route path="/Edit-admin" element={<EditAdmin />} />
            <Route path="/AdminReportStudentEnrolment" element={<ReportStuEnroll />} />
            <Route path="/AdminReportRevenue" element={<ReportRevenue />} />
            
            <Route path="/ListUser" element={<ListUser />} />

            <Route path="/ListBanner" element={<ListBanner />} />
            <Route path="/ListForm" element={<ListForm />} />
            <Route path="/ListContent" element={<ListContent />} />
            <Route path="/ListDiagnosticType" element={<ListDiagnosticType />} />
            <Route path="/ListDiagnosticMaster" element={<ListDiagnosticMaster />} />
            <Route path="/AdminReport" element={<AdminReport />} />
            <Route path="/ListScheduledMaintenance" element={<ListScheduledMaintenance />} />
            <Route path="/AdminReportEnrolmentStatistics" element={<AdminReportEnrolmentStatistics />} />
            <Route path="/AdminReportInitialAssessments" element={<AdminReportInitialAssessments />} />
            <Route path="/AdminReportUnenrollment" element={<AdminReportUnenrollment />} />
            <Route path="/AdminReportStudentAppUsage" element={<AdminReportStudentAppUsage />} />
            <Route path="/AdminReportStudentSession" element={<AdminReportStudentSession />} />
            <Route path="/AdminReportParentAppUsage" element={<AdminReportParentAppUsage />} />

            <Route path="/AdminFinanceNetworkRevenue" element={<AdminFinanceNetworkRevenue />} />
            <Route path="/AdminFinanceNettRevenue" element={<AdminFinanceNettRevenue />} />
            <Route path="/AdminFinanceRefunds" element={<AdminFinanceRefunds />} />
            <Route path="/AdminFinanceDeposits" element={<AdminFinanceDeposits />} />
            <Route path="/AdminFinanceRegistrationFees" element={<AdminFinanceRegistrationFees />} />

            <Route path="/AdminPGCommission" element={<AdminPGCommission />} />
            <Route path="/AdminPaymentFlows" element={<AdminPaymentFlows />} />
            <Route path="/AdminFailedPayments" element={<AdminFailedPayments />} />
            <Route path="/AdminDisputes" element={<AdminDisputes />} />

            <Route path="/ListYearLevel" element={<ListYearLevel />} />
          </Routes>
          </div>
</>
    );
}
  else {
      return null;
  }
}

function CentreRoute() {
  
  const navigate = useNavigate();
  
  const [isOpen, setIsOpen] = useState(false);


  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const [message, setMessage] = useState('');

    const handleSearchChange = event => {
      setMessage(event.target.value);
  
     // console.log('value is:', event.target.value);
    };




    

  const UserType = sessionStorage.getItem('is_admin');

  useEffect(() => {
      if (UserType == "0" ) {
        document.title = 'TCMS';
        axios.post(BASE_URL + 'API/api_index.php', {
          security_key: security_key,
          pk_cent_key: sessionStorage.getItem('key'),
          user_type: sessionStorage.getItem('user_type'),
          pk_ci_id: sessionStorage.getItem('user_id'),
          mode: "CheckCenterDetails"
        }).then(responseCenter => {
              // Assuming the API response is an array of objects with 'id' and 'value' properties
              if (responseCenter.data.success === true) {
                if (responseCenter.data.data.length > 0) {
                      if (responseCenter.data.data[0]['pk_cent_key'] !== '') {

                        //sessionStorage.setItem('key', responseCenter.data.data[0]['pk_cent_key']);
                        //sessionStorage.setItem('is_admin', '0');
                        // sessionStorage.setItem('center_name', responseCenter.data.data[0]['cent_name']);
                        // sessionStorage.setItem('first_name', responseCenter.data.data[0]['ci_first_name']);
                        // sessionStorage.setItem('sur_name', responseCenter.data.data[0]['ci_surname']);
                        sessionStorage.setItem('time_zone', responseCenter.data.data[0]['cent_timezone']);
                       // sessionStorage.setItem('user_id', responseCenter.data.data[0]['pk_ci_id']);
                        //user_type
                        //0- Center super admin and admin.
                        //1- staff
                        //sessionStorage.setItem('user_type', responseCenter.data.data[0]['user_type']);
                        sessionStorage.setItem('country_alph_code', responseCenter.data.data[0]['country_code']);
                        sessionStorage.setItem('country_code', responseCenter.data.data[0]['cent_country']);
                        sessionStorage.setItem('currency_code', responseCenter.data.data[0]['country_currency']);
                        sessionStorage.setItem('cent_profile', responseCenter.data.data[0]['cent_profile']);
                    } else {
                      sessionStorage.clear();
                      navigate("/Login");
                      document.title = 'TCMS';
                    }

                }else{
                  sessionStorage.clear();
                  navigate("/Login");
                  document.title = 'TCMS';
                }
              }else{
                sessionStorage.clear();
                navigate("/Login");
                document.title = 'TCMS';
              }
          })
          .catch(error => {
            sessionStorage.clear();
              console.error('Error fetching options from API: ', error);
              navigate("/Login");
              document.title = 'TCMS';
          });


      }
      else{
        sessionStorage.clear();
        navigate("/Login");
      }
     

  }, [navigate, UserType]);

  if (UserType == 0) {
      return (
            <>
            <Sidebar isOpen={!isOpen} toggleSidebar={toggleSidebar} />

            <div className='page-content' style={{
            flex: 1,
            padding: '16px',
            marginLeft: !isOpen ? '240px' : '0', // Adjust the margin when the sidebar is open
            transition: 'margin-left 0.3s ease',
          }}>
            <Header isOpen={!isOpen} toggleSidebar={toggleSidebar} handleSearchChange={handleSearchChange}/>
            <Routes >

           
            <Route path="/*" element={<PageNotFound />} />

              <Route path="/calendar" element={<Calendar isOpen={!isOpen} toggleSidebar={toggleSidebar} />} />

              <Route path="/list-students" element={<ListStudent />} />
              <Route path="/StudentSearch" element={<StudentSearch message={message} />} />
              <Route path="/add-student" element={<AddStudent />} />
              <Route path="/student-profile" element={<EditStudent />} />
              <Route path="/view-student" element={<ViewStudent />} />

              <Route path="/list-lead" element={<ListLead />} />
              <Route path="/add-lead" element={<AddLead />} />
              <Route path="/view-lead" element={<ViewLead />} />

              <Route path="/list-session" element={<ListSession />} />
              <Route path="/add-session" element={<AddSession />} />
              <Route path="/generate-session" element={<GenerateSession />} />
              <Route path="/edit-session" element={<EditSession />} />

              <Route path="/student-entrolments" element={<ListEntrolments />} />
              <Route path="/enrollment-receipts" element={<ListReceipt />} />
              <Route path="/fees-view" element={<ListViewFees />} />
             
              <Route path="/ViewEntrolments" element={<ViewEntrolments />} />

            <Route path="/ViewUnEntrolments" element={<ViewUnEntrolments />} />

            <Route path="/ListXeroEntry" element={<ListXeroEntry />} />
            <Route path="/ListQBEntry" element={<ListQBEntry />} />
            
            
              <Route path="/operating-hoursandholidays" element={<ListHolidays />} />
              <Route path="/add-holidays" element={<AddHolidays />} />
              <Route path="/edit-holidays" element={<EditHolidays />} />



            

              <Route path="/Diagnostics" element={<Diagnostics />} />
              <Route path="/Add-diagnostics" element={<AddDiagnostics />} />
              <Route path="/Edit-diagnostics" element={<EditDiagnostics />} />
              <Route path="/AddInitialDiagnostics" element={<AddInitialDiagnostics />} />


              <Route path="/Fees" element={<Fees />} />
              <Route path="/Add-pricing" element={<AddFees />} />
              <Route path="/Add-discount" element={<AddDiscount />} />
              <Route path="/Edit-pricing" element={<EditFees />} />
              <Route path="/Edit-discount" element={<EditDiscount />} />


              <Route path="/list-tutor" element={<ListTutor />} />
              <Route path="/add-tutor" element={<AddTutor />} />
              <Route path="/edit-tutor" element={<EditTutor />} />

              <Route path="/list-session-time" element={<ListSessionTime />} />
              <Route path="/add-session-time" element={<AddSessionTime />} />
              <Route path="/add-session-time-demo" element={<AddSessionTimeDemo />} />
              <Route path="/add-holiday-session-time" element={<AddHolidaySessionTime />} />
              <Route path="/edit-session-time" element={<EditSessionTime />} />

              <Route path="/centre-info" element={<AddCenterInfo />} />

              <Route path="/Subject" element={<Subject />} />
            <Route path="/AddSubject" element={<AddSubject />} />
            <Route path="/EditSubject" element={<EditSubject />} />
              
              <Route path="/reports" element={<Reports />} />

              {/* Financial Report */}
              <Route path="/report-finance-daily_pg_payments" element={<DailyPGPayments />} />
              <Route path="/report-finance-daily_merchant_statement" element={<DailyMerchantStatement />} />
              <Route path="/report-finance-debtor" element={<Debtor />} />
              <Route path="/report-finance-deposits" element={<Deposits />} />
              <Route path="/report-finance-timesheet" element={<Timesheet />} />
              <Route path="/report-finance-monthly_revenue" element={<MonthlyRevenue />} />

              {/* Student Report */}
              <Route path="/report-student-assessment" element={<Assessment />} />
              <Route path="/report-student-coins" element={<Coins />} />
              <Route path="/report-student-login" element={<LoginRp />} />
              <Route path="/report-student-contact_list" element={<ContactList />} />
              

              {/* Centre Management Report */}
              <Route path="/report-centre-birthday" element={<Birthday />} />
              <Route path="/report-centre-missed_sessions" element={<MissedSessions />} />
              <Route path="/report-centre-entrolment_statistics" element={<EntrolmentStatistics />} />
              <Route path="/report-centre-student_teacher_ratio" element={<TeacherRatio />} />
              <Route path="/report-centre-unenrollment" element={<CentreUnenrollment />} />

              <Route path="/QuickBookApi" element={<QuickBookApi />} />
              <Route path="/XeroApi" element={<XeroApi />} />
              <Route path="/StripeApi" element={<StripeApi />} />
              
              <Route path="/WhatsApp" element={<WhatsApp />} />



            <Route path="/ListStaff" element={<ListStaff />} />
            <Route path="/AddStaff" element={<AddStaff />} />
            <Route path="/EditStaff" element={<EditStaff />} />

            <Route path="/codecheck" element={<Codecheck />} />
            <Route path="/ListSlot" element={<ListSlot />} />
            <Route path="/ListCentreForm" element={<ListCentreForm />} />
            <Route path="/ListLessonActivity" element={<ListLessonActivity />} />

            <Route path="/ListParent" element={<ListParent />} />
            <Route path="/ListParentStudent" element={<ListParentStudent />} />
            <Route path="/ListTutorTimeSheet" element={<ListTutorTimeSheet />} />
            <Route path="/ListTutorPayment" element={<ListTutorPayment />} />
            <Route path="/ListStudentAttendance" element={<ListStudentAttendance />} />
            </Routes>
            </div>
  </>
      );
  }
 
  else {
      return null;
  }
}



//function App() {
const App = () => {
   console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
  return (
   <>
    <Router>
    <div className='main-content'>
      <div style={{ display: 'flex' }}>

        <Routes>
             
            {/* <Route path="*" component={PageNotFound} /> */}
            {/* <Route component={PageNotFound} /> */}
            
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/*" Component={CentreRoute} />
            <Route path="/admin/*" Component={AdminRoute} />
           
    
        </Routes>
        </div>
    </div>
    </Router>
 </>
  );


}

export default App;
