/*
Purpose: list details of admin with use datatable
Developer: BY KTH
Date:22/08/2023 
Project: TCMS
*/


import { useState, useCallback, useEffect } from "react";
import { useLocation,NavLink, useNavigate } from "react-router-dom";

import { Row, Col, Card, Modal} from 'react-bootstrap';

import axios from 'axios';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { Button as Buttonmui } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { security_key, BASE_URL} from "../global";
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';

import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
const Superadmin = () => {
  
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

   const onReportStuEnrollClick = useCallback(() => {
    navigate("/admin/AdminReportStudentEnrolment");
  }, [navigate]);

   const onReportClick = useCallback(() => {
    navigate("/admin/AdminReportRevenue");
  }, [navigate]);

  const location = useLocation();
  useEffect(() => {

      const checkAdminStatus = async () => {
          const isAdminSuccess = sessionStorage.getItem('isAdminSuccess');
          if (isAdminSuccess !== null && isAdminSuccess === '1') {
            toast.success("You have successfully logged into your account.", {
                  autoClose: 1000, // 3 seconds in this example
              });
              sessionStorage.setItem('isAdminSuccess', '0');
          } else {
             
          }
        };
    
        checkAdminStatus();
}, [location]);


  const handleRowClick = async(e) => {
    if(parseInt(e.visibility)==1){
      navigate(`/admin/Edit-admin`, { state: { pk_cent_key: `${e.pk_cent_key}` }});
    }else{
     
      toast.error("Sorry! Allow Only active center.", {
        autoClose: 3000, // 3 seconds in this example
      });
    }
   

 }

 const handleUserClick = async (e) => {
  if(parseInt(e.visibility)==1){
      navigate(`/admin/ListUser`, { state: { pk_cent_key: `${e.pk_cent_key}` }});

    }else{
      toast.error("Sorry! Allow Only active center.", {
        autoClose: 3000, // 3 seconds in this example
      });

    }
  
}


   // Sample data source
    
     //Api call start
     const [data, setItems] = useState([]);

     const [showDelete, setDeleteShow] = useState(false);
      const [data_delete, setDeleteLabelData] = useState([]);
      const [isUserDeleteSubmitting, setIsUserDeleteSubmitting] = useState(false);


      const handleDeleteClick = (row) => {
      
          const updatedData = { ...row};
          setDeleteLabelData(updatedData);
      
          setDeleteShow(true);
      };

      const [showVisible, setVisibleShow] = useState(false);
      const [data_visible, setVisibleLabelData] = useState([]);
      const [isVisibleSubmitting, setIsVisibleSubmitting] = useState(false);
      
 
      const handleVisibleClick = (row) => {
        
        
          const updatedData = { ...row};
          setVisibleLabelData(updatedData);
        
          setVisibleShow(true);
        };
    
     useEffect(() => {
         fetchData();
     }, []);

     const fetchData = async () => {
         try {
             const response = await axios.post(BASE_URL+'API/api_index.php', {
               security_key: security_key,
               mode: sessionStorage.getItem('user_type') == 1  ? 'getSingleCenter_new' : 'getAllCenter',
               pk_cent_key: sessionStorage.getItem('key')
             });
             
             if(response.data.success===true){
               setRecords(response.data.data);
               setItems(response.data.data);
               setLoading(false);
             }else{
               //alert message provide
               setLoading(false);
             }
             
             
         } catch (error) {
             console.error('Error fetching data:', error);
         }
     };

     //Api call End



// // Sample columns configuration
  const columns = [
    { headerName: 'Centre Name',
      field: "cent_name",
      headerClassName: "super-app-theme--header",
      flex: 1
    },
    { headerName: 'Full Name',
      field: "Full Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      valueGetter: (params) => {
        return `${params.row.ci_first_name || ''} ${params.row.ci_surname || ''}`;
      }
   },
  { headerName: 'Email',
    field: "cent_emailid",
    headerClassName: "super-app-theme--header",
    flex: 1
    },
    { headerName: 'Login UserName',
    field: "ca_login_id",
    headerClassName: "super-app-theme--header",
    flex: 1
    },
    { 
    headerName: 'License Fee',
    field: "License Fee",
    headerClassName: "super-app-theme--header",
    flex: 1,
    valueGetter: (params) => {
      return `${params.row.cent_comis_amt+'%' || ''}`;
    }
  },
    {
      headerName: "Action",
      field: "actions",
      type: "actions",
     // disableColumnSelector: true,
      headerClassName: "super-app-theme--header",
      width: 210,
      align:'left',
      disableExport: false,
      disableColumnFilter: false,
      disableColumnMenu: false,
      filterable: true,
      sortable: true,
      headerAlign:"left",
      valueGetter: (params) => {
        return `${parseInt(params.row.visibility)==1 && sessionStorage.getItem('user_type') != 1 ? 'Active' :  sessionStorage.getItem('user_type') != 1 ? 'Deactive' : '' }`;
      },
      renderCell: (params) => {
            const row = params.row;

      
          return (
            <div className='d-flex  pad-12'>
            
              <Buttonmui variant="outlined-red"  onClick={() => handleUserClick(row)}  className="primary-blue  btnmr-2 my-auto" >
               <VisibilityIcon className="btn-icon me-1"/> View User
              </Buttonmui>
              {parseInt(row.visibility)==1 && sessionStorage.getItem('user_type') != 1 ? 
              <span className='active-btn my-auto cursor-pointer' onClick={() => handleDeleteClick(row)} >
               <DoneIcon className="icon-blue  btn-icon me-1" />
               Active
               </span> : sessionStorage.getItem('user_type') != 1 ? 
               <span className='deactive-btn my-auto cursor-pointer'  onClick={() => handleVisibleClick(row)} >
                <CloseIcon className=" btn-icon me-1" />
                Deactive
              </span> : ''}
             
            </div>
          );
       
      
      }
    },
   // { name: "id", selector: (row) => row.pk_cent_key, omit: true }
  ];

  const [records, setRecords] = useState(data);



  function onSubmitDelete() {
    setIsUserDeleteSubmitting(true);

    const responsedelete = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      updated_by: sessionStorage.getItem('key'),
      mode: "deleteCenter",
      pk_cent_key: data_delete.pk_cent_key
    }).then((responsedelete) => {

        if(responsedelete.data.data == 1){

            setDeleteShow(false)
            // Handle the successful response here
            toast.success("Success! Centre has been deactivated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

            setIsUserDeleteSubmitting(false);

        }else{
            toast.error("Failure! Unable to delete centre. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsUserDeleteSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to delete centre. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsUserDeleteSubmitting(false);
      });
  }


  

  function onSubmitVisible() {
    setIsVisibleSubmitting(true);

    const responseVisible = axios.post(BASE_URL+"API/api_index.php", {

      security_key: security_key,
      updated_by: sessionStorage.getItem('key'),
      mode: "VisibleCenter",
      pk_cent_key: data_visible.pk_cent_key
    }).then((responseVisible) => {

        if(responseVisible.data.data == 1){

          setVisibleShow(false)
            // Handle the successful response here
            toast.success("Success! Centre has been activated.", {
              autoClose: 3000, // 3 seconds in this example
            });
          
           fetchData();

           setIsVisibleSubmitting(false);

        }else{
            toast.error("Failure! Unable to change centre status. Try again later.", {
                autoClose: 3000, // 3 seconds in this example
              });
              setIsVisibleSubmitting(false);
        }
       

    })
      .catch((error) => {
        // Handle any errors that occurred during the request
        toast.error("Failure! Unable to change centre status. Try again later.", {
          autoClose: 3000, // 3 seconds in this example
        });
        setIsVisibleSubmitting(false);
      });
  }


  return (
    <>
     <ToastContainer position="top-center" />
    <Modal show={showDelete} variant="danger">

    <Modal.Header>Centre</Modal.Header>
    
    <Modal.Body>
      <p>Are you sure? You want change to deactivate this centre? </p>
    
    </Modal.Body>
    <Modal.Footer className='pt-0'>
      <div className="mx-auto d-flex gap-2" >
    
          <Button variant="contained" className="primary-gray" onClick={() => setDeleteShow(false)}>Cancel</Button>
          <Button variant="contained" type='submit' className="primary-filled " disabled={isUserDeleteSubmitting} onClick={() => onSubmitDelete()} >Yes</Button>
      </div>
    
      </Modal.Footer>
    
      </Modal>

      <Modal show={showVisible} variant="danger">

<Modal.Header>Centre</Modal.Header>

<Modal.Body>
  <p>Are you sure? You want change to activate this centre? </p>

</Modal.Body>
<Modal.Footer className='pt-0'>
  <div className="mx-auto d-flex gap-2" >

      <Button variant="contained" className="primary-gray" onClick={() => setVisibleShow(false)}>Cancel</Button>
      <Button variant="contained" type='submit' className="primary-filled " disabled={isVisibleSubmitting} onClick={() => onSubmitVisible()} >Yes</Button>
  </div>

  </Modal.Footer>

  </Modal>
    
    <div className="page-wrapper">

        {/* --------------Page Start--------------- */}

          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div>
                <h5 className='page-title'>  Centres Management  </h5>
                
                <div role="presentation" >
                    <Breadcrumbs aria-label="breadcrumb" className='my-breadcrumbs'>
                        <NavLink underline="hover" color="inherit" to='/admin/admin-list'> Home</NavLink>
                       

                        <Typography color="text.primary">Centres Management</Typography>
                    </Breadcrumbs>
                </div>
            </div>
            <div>
            <div className='d-flex align-items-center gap-2'>
                    {/* <Button className="secondary-filled " variant="contained" onClick={() => navigate(window.history.back())}>
                        Back
                    </Button> */}
                    <NavLink className="primary-filled rounded-10 p-2" to='/admin/Add-admin' style={{ display: sessionStorage.getItem('user_type') == 1  ? 'none' : 'block' }}>
                                <AddIcon className="btn-icon"/> New Centre</NavLink>
                </div>
              
            </div>
          </div>

         {/* --------------Section Start--------------- */}  

         <Card className='my-card p-1 bg-white'>

            <Card.Body>


              {/* --------------Tabs Section-2 Start--------------- */}  
              <Row>
                  <Col xs="12">
                      <div className="">

                          <Row className="justify-content-center">

                              {/* --------------Table Content Start--------------- */} 
                              <Col sm={12}>
                                
                                <div className="d-flex flex-wrap justify-content-end align-items-center mb-4">

                                

                                  
                                  
                                  <div className="form-group has-search">
                                  <a href="javascript:void(0)" className="text-primary btn-primary-outline btn btn-primary" onClick={onReportClick}>
                                    Revenue Report
                                     
                                  </a>

                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <a href="javascript:void(0)" className="text-primary btn-primary-outline btn btn-primary" onClick={onReportStuEnrollClick}>
                                    Enrolment Report
                                  </a>
                                    {/* <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" className="form-control" onChange={handleFilter}  /> */}

                                  </div>


                                </div>

                              </Col>
                              {/* --------------Table Content Start--------------- */} 
                              


                              {/* --------------Table Content Start--------------- */} 
                              <Col sm={12}>
                                
                                    <Box
                                      sx={{
                                        height: "fit-content",
                                        width: "100%",
                                        "& .actions": {
                                            color: "text.secondary",
                                        },
                                        "& .textPrimary": {
                                            color: "text.primary",
                                        },
                                        "& .super-app-theme--header": {
                                            backgroundColor: "#f4f4f4",
                                        },
                                    }}
                                    style={{ height: '100%', width: "100%" }}
                                >
                                   <DataGrid
  sx={{
                                  // disable cell selection style
                                  '.MuiDataGrid-cell:focus': {
                                    outline: 'none'
                                  },
                                  // pointer cursor on ALL rows
                                  '& .MuiDataGrid-row:hover': {
                                    cursor: 'pointer'
                                  }
                                }}
                                        //sx={{ border: "none", m: 2 }}
                                        autoHeight
                                        loading={loading}
                                        slots={{
                                            loadingOverlay: LinearProgress,
                                            //noRowsOverlay: CustomNoRowsOverlay,
                                            toolbar: GridToolbar,
                                            // toolbar: EditToolbar,
                                        }}
                                        slotProps={{
                                            toolbar: {
                                                csvOptions: { fileName: "CentreList" },
                                                printOptions: { pageStyle: `@media print {
                                      @page {
                                        size: A4 landscape;
                                        margin: 0;
                                      }
                                    }`, disableToolbarButton: true, hideFooter: true, hideToolbar: true, fileName: "CentreList" },
                                                records,
                                                showQuickFilter: true
                                            },
                                        }}
                                        initialState={{
                                          ...records.initialState,
                                          pagination: { paginationModel: { pageSize: 10 } },
                                        }}
                                        pageSizeOptions={[10, 25, 50, 100]}
                                        rows={records.map((row, index) => ({ ...row, id: `row-${index}` }))}
                                        columns={columns}
                                        rowHeight={60} density="compact"
                                        onRowClick={(params) => handleRowClick(params.row)}
                                        disableRowSelectionOnClick
                                        showCellVerticalBorder= {true}
                                        showColumnVerticalBorder= {true}

                                    />
                                </Box>
                                

                              </Col>
                              {/* --------------Table Content Start--------------- */} 

                          </Row>
                          
                      </div>
                  </Col>
              </Row>
               {/* --------------Tabs Section-2 End--------------- */}

               </Card.Body>

            </Card>

            {/* --------------Section End--------------- */}


        {/* --------------Page End--------------- */}

    </div>
    </>
);
};

export default Superadmin;
